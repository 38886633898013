import React, { useContext } from 'react';
import './SizeRowHeader.scss';
import { SizeHolderMatrixContext } from '../../context/SizeHolderMatrixCotext';
import jsonData from '../types.json';
import icon from '../../assets/images/question-circle.svg'
import {isSizeChartChangedContext} from '../../context/isSizeChartChangedContext';
import tooltipImages from '../../utils/measurement-names-to-image'

function SizeRowHeader({ measurementTypes, setMeasurementTypes, isMutallyExcluded}) {
    const [sizeHolderMatrix, setSizeHolderMatrix] = useContext(SizeHolderMatrixContext)
    const [, setIsChartChanged] = useContext(isSizeChartChangedContext);


    const renderSizeRowHeader = () => {
        var arr = measurementTypes.map((measurementType, index) => {
            return (
                <div key={index} className='size-header-item' style={{borderTop: 'none'}}>
                    <input type="checkbox" checked={measurementType.enabled} onChange={(event) => handleClick(event, index)} />
                    <p style={{margin: 0, fontSize:'18px', textAlign: "center", width:'70px'}}>{getMeasurementTypeName(measurementType.name)}</p>
                    { measurementType.name !== 'height' && measurementType.name !== 'height_kids' && measurementType.name !== 'weight' &&
                    <div className='size-header-item-tool-tip' style={{marginLeft: '5px'}}>
                        <img src={icon} style={{width: '10px'}} alt="icn"/>
                        <div className='size-header-item-tool-tip-text'>     
                            <img src= {tooltipImages(measurementType.name)} alt="tooltip images"/>

                            {measurementType.name.endsWith("_kids") ?  
                            getMeasurementToolTipText(measurementType.name.slice(0, measurementType.name.length - 5)) : 
                            getMeasurementToolTipText(measurementType.name)
                            }
                        </div>
                    </div>}
                </div>)
        });
        arr.unshift(<div key={53453599} style={{borderTop: 'none',borderLeft: 'none'}} className='size-header-item'>Size</div>)
        return arr

    }

    const getMeasurementTypeName = (mtName) => {
        let correctType = mtName
        correctType = jsonData.measurement_types.filter(mt => {
            return mtName === mt.name;
        });
        return correctType[0]?.display_name

    }

    const getMeasurementToolTipText = (mtName) => {
        let correctType = mtName
        correctType = jsonData.measurement_types.filter(mt => {
            return mtName === mt.name;
        });
        return correctType[0]?.tooltip_text
    }

    // const getMeasurementToolTipImg = (mtName) => {
    //     let correctType = mtName
    //     correctType = jsonData.measurement_types.filter(mt => {
    //         return mtName === mt.name;
    //     });
    //     return correctType[0]?.tooltip_thumbnail.url
    // }

    const syncSizeMatrixToMeasurementTypes = (newColumnHeaders) => {
        let newSizeMatrix = [];
        let oldSizeMatrix = sizeHolderMatrix;
        for (let i = 0; i < oldSizeMatrix.length; i++) {
            newSizeMatrix[i] = []
            for (let j = 0; j < oldSizeMatrix[i].length; j++) {
                let measurementTypesNames = newColumnHeaders.map(x => x.name)
                if(oldSizeMatrix[i][j] !== undefined){
                const newIndex = measurementTypesNames.indexOf(oldSizeMatrix[i][j].measurementTypeName);
                if (newIndex > -1) {
                    newSizeMatrix[i][newIndex] = { ...oldSizeMatrix[i][j], enabled: newColumnHeaders[newIndex].enabled }
                }
             }
            }
        }
        return newSizeMatrix;
    }
    const markOnly = (index, checked) => {
        const newColumnHeaders = [];
        let newIndex = 1;
        for (let j = 0; j < measurementTypes.length; j++) {
            if (j !== index) {
                newColumnHeaders[newIndex] = { ...measurementTypes[j], enabled: !checked }
                newIndex++;
            }
            else {
                newColumnHeaders[0] = { ...measurementTypes[j], enabled: checked }
            }
        }
        return newColumnHeaders;
    }
    const reorderMeasurementTypes = (index, checked) => {
        let disabled = [], enabled = [];
        for (let j = 0; j < measurementTypes.length; j++) {
            if (j !== index) {
                if (measurementTypes[j].enabled === undefined || !measurementTypes[j].enabled)
                    disabled.push({ ...measurementTypes[j] })
                else
                    enabled.push({ ...measurementTypes[j] })
            }
        }
        if (checked)
            enabled.push({ ...measurementTypes[index], enabled: checked })
        else
            disabled.push({ ...measurementTypes[index], enabled: checked })

        let newColumnHeaders = []
        for (let j = 0; j < enabled.length; j++)
            newColumnHeaders.push(enabled[j]);
        for (let j = 0; j < disabled.length; j++)
            newColumnHeaders.push(disabled[j]);
        return newColumnHeaders;
    }

    const handleClick = (event, index) => {
        setIsChartChanged(true);
        let newSizeMatrix = [];
        let newColumnHeaders = [];
        newColumnHeaders = !isMutallyExcluded ? reorderMeasurementTypes(index, event.target.checked) : markOnly(index, event.target.checked);
        setMeasurementTypes(newColumnHeaders);
        newSizeMatrix = syncSizeMatrixToMeasurementTypes(newColumnHeaders);
        setSizeHolderMatrix(newSizeMatrix);
    }


    return (
        <React.Fragment>
            {renderSizeRowHeader()}
        </React.Fragment>
    );
}

export default SizeRowHeader;
