import React from "react";
import "./SizeChartGender.scss";
import { chartWizardPageNames } from "../../../utils/page-state";

const genderTypes = {
  MALE: "male",
  FEMALE: "female",
  UNISEX: "unisex",
};

export default function SizeChartGender({
  setChartPageState,
  gender,
  setGender,
  fromEdit,
  setChartGender,
  chartGender,
  setEditRenderer,
  chartsizeChartType
}) {


  const setGenderName = (gender) =>{
    if(chartsizeChartType.includes('kids')){
        if(gender.toLowerCase() === 'male')
            return 'Boys';
        else if(gender.toLowerCase() === 'female')
            return 'Girls'
        else return gender
    }
    else return gender

}

  // Rendered from edit
  if(fromEdit){
       
    return (
      <div className="size-chart-gender" style={{width: fromEdit && '500px'}}>
        <h1>Gender</h1>
        <div className="buttons-wrapper">
          <div
            className={`button ${chartGender === genderTypes.MALE && "active"}`}
            onClick={() => setChartGender(genderTypes.MALE)}
          >
            {setGenderName('Male')}
          </div>
          <div
            className={`button ${chartGender === genderTypes.FEMALE && "active"}`}
            onClick={() => setChartGender(genderTypes.FEMALE)}
          >
            {setGenderName('Female')}
          </div>
          <div
            className={`button ${chartGender === genderTypes.UNISEX && "active"}`}
            onClick={() => setChartGender(genderTypes.UNISEX)}
          >
            Unisex
          </div>
        </div>
  
        <div
          className={`next-button ${!chartGender && "unable"}`}
          onClick={() => setEditRenderer(null)}
        >
          Save
        </div>
      </div>
    );
    
  }
  // Rendered from chart wizard
  else{
    const nextClickHandler = () => {
      // check for input
      if (!gender) {
        return;
      }
      setChartPageState(chartWizardPageNames.CHART_WIZARD_UNIT);
    };
  
    
  
    return (
      <div className="size-chart-gender">
        <h1>Gender</h1>
        <div className="buttons-wrapper">
          <div
            className={`button ${gender === genderTypes.MALE && "active"}`}
            onClick={() => setGender(genderTypes.MALE)}
          >
            Male
          </div>
          <div
            className={`button ${gender === genderTypes.FEMALE && "active"}`}
            onClick={() => setGender(genderTypes.FEMALE)}
          >
            Female
          </div>
          <div
            className={`button ${gender === genderTypes.UNISEX && "active"}`}
            onClick={() => setGender(genderTypes.UNISEX)}
          >
            Unisex
          </div>
        </div>
  
        <div
          className={`next-button ${!gender && "unable"}`}
          onClick={nextClickHandler}
        >
          Next
        </div>
      </div>
    );
  }
  
}
