// 1


const checkForEmpty = (meas) => {
  return (
    meas.from !== "" &&
    meas.from !== null &&
    meas.from !== undefined &&
    meas.from.trim() !== "" &&
    meas.to !== "" &&
    meas.to !== null &&
    meas.to !== undefined &&
    meas.to.trim() !== ""
  );
};

exports.validate = (arr,tableType) => {
  const errors = [];
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[0].length; j++) {
      if(arr[i][j].enabled === false ) continue;
      const valid = checkForEmpty(arr[i][j]);
      if (!valid) {
        const message =
          "We’re missing some info, please make sure all cells have been updated to a value other than zero.";
        errors.push({ coordinate: [i, j], message,tableType });
      }
    }
  }
  return errors
};
