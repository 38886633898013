import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import "./TopBar.scss";
import { ReactComponent as HomeImage } from "../../assets/images/home.svg";
import { ReactComponent as AccountImage } from "../../assets/images/account.svg";
import { ReactComponent as HelpImage } from "../../assets/images/help-icon.svg";
import { NavLink, useLocation } from "react-router-dom";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import { ReactComponent as MySizeIcon } from "../../assets/images/mysize-icon.svg";
import { ReactComponent as ChainIcon } from "../../assets/images/chain-icon.svg";
import { ReactComponent as GraySettingsIcon } from "../../assets/images/gray-settings.svg";
import chainIcon from "../../assets/images/chain-icon.svg";
import { TipsContext } from "../../context/TipsContext";
import { LocalStorageService } from "../../services/LocalStorageService";
import { config } from "../../config";


export default function TopBar({ resetAndShowTips, isHelpTips }) {
  const [showHelp, setShowHelp] = useState(false);
  const [retailerProfile] = useContext(RetailerProfileContext);
  const [tipsState, tipsHandler] = useContext(TipsContext);
  const location = useLocation();

  const {
    REACT_APP_HELP_LINK_WIX,
    REACT_APP_HELP_LINK_SHOPIFY,
    REACT_APP_HELP_LINK_PRESTASHOP,
    REACT_APP_HELP_LINK_WOOCOMMERCE,
    REACT_APP_HELP_LINK_LIGHTSPEED,
    REACT_APP_HELP_LINK_EXTERNAL,
    REACT_APP_HELP_LINK_BITRIX,
    REACT_APP_HELP_LINK_MAGENTO,
  } = config;

  useEffect(() => {
    window.retailerSource = retailerProfile.source;
    checkLocalStoragePlan()
  }, []);

  const checkLocalStoragePlan = () => {
    LocalStorageService.checkLocalStoragePlan(retailerProfile.pricing_plan?.id)
  }

  const titleSet = () => {
    switch (location.pathname) {
      case "/main":
        return "Products";
      case "/account":
        return "Account";
      case "/widget-settings":
        return "Customize Widget";
      case "/plans":
        return "Plans";
      default:
        return "Products";
    }
  };

  const redirectToHelpPage = () => {
    const retailerSource = retailerProfile.source;
    switch (retailerSource) {
      case "wix":
        window.open(REACT_APP_HELP_LINK_WIX);
        break;
      case "shopify":
        window.open(REACT_APP_HELP_LINK_SHOPIFY);
        break;
      case "presta":
        window.open(REACT_APP_HELP_LINK_PRESTASHOP);
        break;
      case "woocommerce":
        window.open(REACT_APP_HELP_LINK_WOOCOMMERCE);
        break;
      case "lightspeed":
        window.open(REACT_APP_HELP_LINK_LIGHTSPEED);
        break;
      case "external":
        window.open(REACT_APP_HELP_LINK_EXTERNAL);
        break;
      case "bitrix":
        window.open(REACT_APP_HELP_LINK_BITRIX);
        break;
      case "magento":
        window.open(REACT_APP_HELP_LINK_MAGENTO);
        break;  
      default:
        break;
    }
  };

  const isSourceWithOutProductsPair = () => {
    return (
      retailerProfile.source === "external" ||
      retailerProfile.source === "woocommerce" ||
      retailerProfile.source === "bitrix" ||
      retailerProfile.source === "plugin" ||
      retailerProfile.source === "lightspeed"
    );
  };
  const isWoocommerceBitrixLightspeed = () => {
    return (
      retailerProfile.source === "woocommerce" ||
      retailerProfile.source === "bitrix" ||
      retailerProfile.source === "plugin" ||
      retailerProfile.source === "lightspeed"
    );
  };

  const productsLinkGenerator = (source) => {
    switch (source) {
      case "woocommerce":
        return (
          <a
            className="pair-charts-btn"
            target="_parent"
            href={`https://${retailerProfile.website.replaceAll('https://', '').replaceAll('http://', '')}/wp-admin/edit.php?post_type=product`}
          >
            <img className="chain-icon" src={chainIcon} alt="chain icon" />
            Pair Charts With Products
          </a>
        );
      case "lightspeed":
        return (
          <a
            className="pair-charts-btn"
            href={`${config[process.env.REACT_APP_ENV].REACT_APP_API_BASE_URL}/mysize_bulk_editor?shop_id=${retailerProfile.website}&shop_type=${retailerProfile.source}`}
          >
            <img className="chain-icon" src={chainIcon} alt="chain icon" />
            Pair Charts With Products
          </a>
        );
      case "bitrix":
        return (
          <a
            className="pair-charts-btn"
            target="_parent"
            href={`http://${retailerProfile.website}/bitrix/admin/iblock_admin.php?type=catalog&lang=ru&admin=N`}
          >
            <img className="chain-icon" src={chainIcon} alt="chain icon" />
            Pair Charts With Products
          </a>
        );
      case "plugin":
        return (
          <a
            className="pair-charts-btn"
            target="_blank"
            rel="noreferrer"
            href={`${retailerProfile.website}${retailerProfile?.store_items_url}`}
          >
            <img className="chain-icon" src={chainIcon} alt="chain icon" />
            Pair Charts With Products
          </a>
        );
      default:
        break;
    }
  };

  const showTipsHandler = () => {
    if (!LocalStorageService.get("added_size_chart")) {
      tipsHandler("start_no_size_chart");
    }else {
      tipsHandler("start_have_size_chart");
    }
    setShowHelp(!showHelp)
  };

  return (
    <div
      className={`top-bar ${
        isSourceWithOutProductsPair() && "top-bar-external"
      }`}
    >
      <div
        className={`top-bar-content-wrapper ${
          isWoocommerceBitrixLightspeed() && "reverse"
        }`}
      >
        {/* <div className={"top-bar-content-wrapper"}style={{display: "flex", justifyContent: "space-between", width: "753px"}}> */}

        {!isSourceWithOutProductsPair() ? (
          <div className="left-side">{titleSet()}</div>
        ) : isWoocommerceBitrixLightspeed() ? (
          <div className="left-side">
            {Object.keys(retailerProfile).length !== 0 &&
              productsLinkGenerator(retailerProfile.source)}
          </div>
        ) : (
          <div className="left-side">
            <MySizeIcon />
          </div>
        )}

        <div className="right-side">
          <ul className="right-side-ul">
            {
              <React.Fragment>
                <li>
                  <div className="tooltip">Home</div>
                  <NavLink to={retailerProfile?.source === "external" && !retailerProfile?.pricing_plan ? "/prepare-account": "/main"} activeClassName="active-nav-link">
                    <HomeImage
                      className={`home-image topbar-tab  ${
                        (location.pathname === "/main" ||
                          location.pathname === "/") &&
                        "active"
                      }`}
                    />
                    {/* <img className={"home-image"} src={location.pathname == "/main" ? homeImageHover : homeImage} alt={'home image'} /> */}
                  </NavLink>
                </li>
                {retailerProfile?.pricing_plan && (
                  <li>
                    <div className="tooltip">Account</div>
                    <NavLink activeClassName="active-nav-link" to="/account">
                      <AccountImage
                        className={`account-image topbar-tab ${
                          (location.pathname === "/account" ||
                            location.pathname === "/plans") &&
                          "active"
                        }`}
                      />
                      {/* <img className={"account-image"} src={location.pathname == "/account" ? accountImageHover : accountImage} alt={'account image'} /> */}
                    </NavLink>
                  </li>
                )}
                 {retailerProfile?.source !== "external" && (
              <li>
                <div className="tooltip">Settings</div>
                <NavLink className="topbar-tab settings-nav" activeClassName="active-nav-link active" to="/widget-settings">
                  <GraySettingsIcon />
                </NavLink>
              </li>
            )}
              </React.Fragment>
            }
            <li>
              <div className="tooltip">Help</div>
              {/* <HelpImage className={`topbar-tab`} onClick={redirectToHelpPage} /> */}
              <HelpImage
                className={`topbar-tab`}
                onClick={() => setShowHelp(!showHelp)}
              />

              <div
                className={`help-modal ${showHelp && "display-block"}`}
                onClick={() => setShowHelp(false)}
              ></div>
              <ul
                className={`help-hover ${showHelp && "display-flex"}`}
                onMouseLeave={() => setShowHelp(false)}
              >
                <li
                  onClick={() => {
                    redirectToHelpPage();
                    setShowHelp(!showHelp);
                  }}
                >
                  Help Center
                </li>
                {(location.pathname === "/main" ||
                  location.pathname === "/") && (
                  <React.Fragment>
                    <li className="seperator"></li>
                    <li onClick={ showTipsHandler}>{`Show Tips`}</li> 
                  </React.Fragment>
                )}
              </ul>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
  );
}
