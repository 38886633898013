import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import XcloseButton from '../../assets/images/x-close-button.svg'
import "./AlertDialog.scss";

export default function AlertDialog({ setIsDialogOpen, header, description, okButton, cancelButton,closeImg }) {

    const handleClose = (type) => {
        if (type === true) {
            setIsDialogOpen(true)
        } else {
            setIsDialogOpen(false)
        }
    };

    const closePopUp = () => {
        setIsDialogOpen(false)
    }

    
    return (
        <div className='mysize-dialog'>
            <Dialog
                className="dialog"
                open={true}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="alert-title" >{header}</DialogTitle>
                <DialogActions disableSpacing={true}>
                    {closeImg &&<img style={{cursor: 'pointer',position: 'absolute',top: '20px',right: '20px'}} src={XcloseButton} className={"x-close-button"} onClick={closePopUp} alt={'close-button'}/>}
                </DialogActions>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="alert-buttons-wrapper">
                    {cancelButton && <Button className="alert-button"  style={{textTransform: "none"}} onClick={() => handleClose(false)} color="primary">
                        {cancelButton}
                    </Button>}
                    {okButton &&
                        <Button className="alert-button" style={{textTransform: "none"}} onClick={() => handleClose(true)} color="primary">
                            {okButton}
                        </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}

