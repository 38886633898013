import React,{useState, useContext} from 'react';
import { ReactComponent as MySizeIcon } from "../../assets/images/mysize-icon.svg";
import Footer from '../Footer/Footer';
import { ReactComponent as OpenEyeIcon } from "../../assets/images/open-eye-icon.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/images/close-eye-icon.svg";
import "./SignIn.scss";
import registrationGirl from '../../assets/images/registration-girl.webp'
import ApiService from '../../services/ApiService';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import {useNavigate, Link} from "react-router-dom";
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import {signInValidation} from '../../validations/input-validations';
import { LocalStorageService } from '../../services/LocalStorageService';
import blueArrowRight from "../../assets/images/blue-right-arrow.svg";



export default function SignIn() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [errors, setErrors] = useState({});


    const [retailerProfile, setRetailerProfile] = useContext(RetailerProfileContext);
    const history = useNavigate();




    const submit = async() => {

        const errors = await signInValidation({email, password});
        if(errors){
            setErrors(errors);
            return
        }
        setErrors({})
        setIsLoading(true);

        const api = new ApiService();
        let token = null;
        let response;
        
        
        
        response = await api.externalSignIn(email, password);
        if(response.code){

            setIsLoading(false)
            if(response.message === "INVALID_PASSWORD"){
                setErrors({password: response.message});
            }else if(response.message === "EMAIL_NOT_FOUND") {
                setErrors({email: response.message});
            }
            return;
        }

        token = response.account.token;
        const meta = await api.getRetailerMeta(token);
        response.meta = meta;
        

       LocalStorageService.set("retailer_token", token);
       setRetailerProfile(response);
       setIsLoading(false)
       if (response?.account_status === "pending_selection") {
           history("/prepare-account", {replace: true});
       } else{
           history("/", {replace: true});
       }

        // if(response?.account_status === "uninstalled"){
        //     history("/select-plan", {replace: true});
        // }else{
        //     history("/", {replace: true});
        // }

    }



    console.log(errors);

    return (
        <div className="signin-container">
              {isLoading && <div className="auth-modal">
                <CircularIndeterminate/>
            </div>}
            <div className="left-side ">
                <div className="logo-frame">
                    <Link to="/welcome">
                        <img
                        className="signin__arrow-left"
                        src={blueArrowRight}
                        alt="blue arrow left" />
                    </Link>
                    <MySizeIcon className="mysizeid-logo"/>
                </div>
                <h3>Sign in to your account</h3>
                <form>
                    <div className="input-wrapper-auth">
                        <input id="email" className={`${errors.email && "input-error"}`} onChange={(e) => setEmail(e.target.value.trim())}></input>
                        <label htmlFor="email" className={email?"focused":""}>Email</label>
                        {errors.email && <div className="error-text">{errors.email === "required" ?  "Please fill out this field" : errors.email === "EMAIL_NOT_FOUND" ? "This email not found" : "Email is not valid"}</div>}
                    </div>

                    <div className="input-wrapper-auth">
                        <input id="password" className={`${errors.password && "input-error"}`} type={`${isPassVisible?"text":"password"}`} onChange={(e) => setPassword(e.target.value.trim())}></input>
                        <label htmlFor="password" className={(isPassVisible&&password)||password?"focused":""}>Password</label>
                        {isPassVisible?<OpenEyeIcon className="eye-icon open-eye-icon" onClick={()=>setIsPassVisible(!isPassVisible)} /> : <CloseEyeIcon className="eye-icon" onClick={()=>setIsPassVisible(!isPassVisible)} />}
                        
                        {errors.password && <div className="error-text">{errors.password === "required" ?  "Please fill out this field"  : errors.password === "INVALID_PASSWORD" ? "Wrong password" : "Invalid password" }</div>}
                   </div>
                    <Link to="/forgot-password"> Forgot Password?</Link>
                    <div className="signup-button" onClick={submit}>SIGN IN</div>
                    <div className="signin-inner-container">

                        <p className="signin-text">Don’t have an account yet? <Link to="/signup">Sign up</Link></p>
                    </div>
                    <Footer/>
                </form>

            </div>
            <div className="right-side">
                <img src={registrationGirl} width="457px" alt="registration girl"></img>
            </div>
        </div>
    )
}
