import React from 'react';
import "./Footer.scss";
import { config } from '../../config';

const { REACT_APP_PRIVACY_POLICY_LINK, REACT_APP_TOS_LINK } = config;

export default function Footer() {
    return (
        <p className="footer">By proceeding, you agree to MySizeID’s <a href={REACT_APP_TOS_LINK} target="_blank" rel="noreferrer">TOS</a> and <a href={REACT_APP_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">Privacy Policy.</a></p>
    )
}
