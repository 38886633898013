import React from "react";
import "./SomethingWentWrong.scss";

export default function SomethingWentWrong({ showSomethingWentWrong,setShowSomethingWentWrong }) {

  if (showSomethingWentWrong) {
    return (
      <div className="something-went-wrong-container">
        <div className="something-went-wrong">
          <h1>Oops..!</h1>
          <p>Something went wrong</p>
          <div className="try-again-btn" onClick={() => setShowSomethingWentWrong(false)}>
            Try Again
          </div>
        </div>
      </div>
    );
  }
  return null;
}
