import React, { useState,useContext } from 'react';
import './SizeChartCreatorHeader.scss';
import SizeChartName from '../ChartWizard/SizeChartName/SizeChartName';
import SizeChartGender from '../ChartWizard/SizeChartGender/SizeChartGender';
import SizeChartAge from '../ChartWizard/SizeChartAge/SizeChartAge';
import SizeChartUnit from '../ChartWizard/SizeChartUnit/SizeChartUnit';
import { editPages } from '../../utils/page-state';
import {SelectedOpenSizeChartContext} from '../../context/SelectedOpenSizeChartContext';
import {convertForApi} from '../../utils/size_chart'

export default function SizeChartCreatorHeader({gender,selectedTypeCategory,chartName,setChartName, setChartGender,setChartAgeCategory,chartAgeCategory,measurementSystem ,setChartMeasurementSystem,approvalStatus,chartsizeChartType}) {

    const [editRenderer, setEditRenderer] = useState(null)
    const [openSelectedSizeChart] = useContext(SelectedOpenSizeChartContext);



    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const APPROVAL_STATUS = {not_submitted: "Saved", submitted: "Submitted", approved: "Approved", rejected: "Rejected"}

    const onSaveHandler = async(data) => {

        const fixedSizeChart = convertForApi(data);
        fixedSizeChart["version"] = 2
        if(editRenderer === editPages.NAME){
            setChartName(fixedSizeChart.name)
        }


    }

    let editDisplayPage = null;
    switch (editRenderer) {
        case editPages.NAME:
            editDisplayPage = <SizeChartName name={chartName} fromEdit={true} onSaveHandler={onSaveHandler} sizeChartData={openSelectedSizeChart} setEditRenderer={setEditRenderer}/>
            break;
        case editPages.GENDER:
            editDisplayPage = <SizeChartGender fromEdit={true} onSaveHandler={onSaveHandler} sizeChartData={openSelectedSizeChart} setEditRenderer={setEditRenderer} setChartGender={setChartGender} chartGender={gender} chartsizeChartType={chartsizeChartType} />
            break;
        case editPages.AGE:
            editDisplayPage =  <SizeChartAge fromEdit={true} setEditRenderer={setEditRenderer} setChartAgeCategory={setChartAgeCategory} chartAgeCategory={chartAgeCategory}/>
            break;
        case editPages.TYPE:
            editDisplayPage = null
            break;
        case editPages.UNIT:
            editDisplayPage = <SizeChartUnit fromEdit={true} setEditRenderer={setEditRenderer} measurementSystem={measurementSystem} setChartMeasurementSystem={setChartMeasurementSystem}/>
            break;
        default:
            break;
    }



    const editForm =  editDisplayPage && (
        <div className="edit-container">
            {editDisplayPage}
            <div>save</div>
        </div>

    );

    const setGender = (gender) =>{
        if(chartsizeChartType.includes('kids')){
            if(gender.toLowerCase() === 'male')
                return 'Boys';
            else if(gender.toLowerCase() === 'female')
                return 'Girls'
            else return gender
        }
        else return gender

    }


    return (
        <div className='size-chart-creator-table-header'>
            <div className='size-chart-breadcrumbs'>
                <div className='size-chart-name'>
                    <div className='is-edited' onClick={() => setEditRenderer(editPages.NAME)}>{capitalize(chartName)}
                    </div>
                </div>
                <p>
                    <span className='is-edited' onClick={() => setEditRenderer(editPages.GENDER)}>&nbsp;{capitalize(setGender(gender))}&nbsp;</span>/
                    <span>&nbsp;{capitalize(measurementSystem)}&nbsp;</span>/
                    <span className='is-edited' onClick={() => setEditRenderer(editPages.AGE)}>&nbsp;</span>
                    <span  onClick={() => setEditRenderer(editPages.TYPE)}>&nbsp;{selectedTypeCategory}&nbsp;</span>

                </p>
            </div>
            <p className='size-chart-status'>{APPROVAL_STATUS[approvalStatus]}</p>

            {editDisplayPage && <div className="edit-modal" onClick={() => setEditRenderer(null) }></div>}

            {editForm}
        </div>
    )
}
