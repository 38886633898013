import React from 'react'
import "./WidgetFontSettings.scss"
import infoImage from '../../assets/images/info_image.svg'

export default function WidgetFontSettings({fontName, setFontName, cssLink, setCssLink, showFontInfoBubble, setShowFontInfoBubble}) {

    const getDisplayValue = (fontString) => {
        if ([null, undefined, ""].includes(fontString))
            return ""
        return fontString
    }

    return (
        <div className="widget-font-settings">
            <div className="widget-font-settings-header">
                <p className="widget-font-settings-header-label">
                    Font
                    <img className="widget-font-settings-header-image" 
                        src={infoImage} 
                        onMouseEnter={() => setShowFontInfoBubble(true)}
                        onMouseLeave={() => setShowFontInfoBubble(false)}/>
                </p>
                {showFontInfoBubble && <div className="widget-font-settings-info-bubble">
                    If you’re using a standard font, please specify its name below. If your font is more unique and is hosted on your site - 
                    please paste the CSS link, or simply contant us and we'll do it for you.
                </div>}
            </div>
            <div className="widget-font-settings-input-fields">
                <input type="text" className="widget-font-settings-input-field" value={getDisplayValue(fontName)} placeholder="Font Name" onChange={(e) => setFontName(e.target.value)} />
                <input type="text" className="widget-font-settings-input-field" value={getDisplayValue(cssLink)} placeholder="CSS Link" onChange={(e) => setCssLink(e.target.value)} />
            </div>
        </div>
    )
}
