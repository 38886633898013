import React, { useContext } from 'react';
import './BraColHeader.scss';
import { SizeHolderMatrixContext } from '../../context/SizeHolderMatrixCotext';
import jsonData from '../types.json';
import deleteSizeButton from '../../assets/images/delete-size-icon.svg';
import tooltipImages from '../../utils/measurement-names-to-image';
import icon from '../../assets/images/question-circle.svg'


function BraColHeader({measurementTypes, setMeasurementTypes}) {
    const [sizeHolderMatrix, setSizeHolderMatrix] = useContext(SizeHolderMatrixContext);


    const getMeasurementToolTipText = (mtName) => {
        let correctType = mtName
        correctType = jsonData.measurement_types.filter(mt => {
            return mtName === mt.name;
        });
        return correctType[0]?.tooltip_text
    }
 
    const renderSizeRowHeader = () => {
        var arr = measurementTypes.map((measurementType, index) => {
            return (
                <div key={index} className={`bra-size-header-item ${index > 0 && 'show-hover'}`}>
                    {index === 0 ? <p style={{ margin: 0, fontSize: '18px' }}>Band</p> : <input placeholder="Enter Size:" onChange={(event) => {setSizeRowHeaderName(event,index)}} className='bra-input-name' value={measurementType.subName} />}
                    <img className="close-img" src={deleteSizeButton} alt={"delete"} onClick={() => deleteCol(index)}/>
                    {<p style={{margin: 0, fontSize:'14px', textAlign: "center"}}>({getMeasurementTypeName(measurementType.name)})</p>}
                     <div className='size-header-item-tool-tip bra-tooltip'>
                        <img alt='tool tip' className="bra-tooltip-icon" src={icon} style={{width: '10px'}}/>
                        <div className='size-header-item-tool-tip-text bra-tooltip-popup '>     
                            <img alt='tooltip icon' className="tooltip-image" src={tooltipImages(measurementType.name)}/>
                            {getMeasurementToolTipText(measurementType.name)}
                        </div>
                    </div>
                </div>)
        });
        arr.unshift(<div key={53453599} className='bra-size-header-item' style={{borderLeft: 'none'}}>Size</div>)
        return arr

    }

    const deleteCol = (index) =>{
        let tempArr =[...sizeHolderMatrix];
        tempArr.forEach(row =>{
            row.splice(index,1);
        });

        let tempMeasurementType = [...measurementTypes]
        tempMeasurementType.splice(index,1);

        setMeasurementTypes(tempMeasurementType);
        setSizeHolderMatrix(tempArr);

    }  

    const setSizeRowHeaderName = (event,index) =>{
        let tempMeasurementTypes = [...measurementTypes];
        let item = {...tempMeasurementTypes[index], subName: event.target.value}
        tempMeasurementTypes.splice(index,1,item);
        setMeasurementTypes(tempMeasurementTypes);
    }

    const getMeasurementTypeName = (mtName) => {
        let correctType = mtName
        correctType = jsonData.measurement_types.filter(mt => {
            return mtName === mt.name;
        });

        return correctType[0].display_name

    }

    return (
        <React.Fragment>
            {renderSizeRowHeader()}
        </React.Fragment>
    );
}

export default BraColHeader;
