import React from "react";
import "./WidgetBody.scss";
import { Slider, createTheme } from "@mui/material";

const borderRadiusScaleFactor = 3.3333334

export default function WidgetBody({ size, radius, onSizeChangeHandler, onRadiusChangeHandler }) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiSlider: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            // fontSize: '1rem',
            color: "#f8686e",
            padding: 0,
            '& .MuiSlider-track': {
              height: '3px',
              color: '#f8686e',
            },
            '& .MuiSlider-rail': {
              color: '#e8e8e8',
              opacity: 1,
              height: '3px',
            },
            '& .MuiSlider-mark': {
              height: '12px',
              width: '4px',
              backgroundColor: '#e8e8e8',
              borderRadius: '2px',
              opacity: '1'
            },
            '& .MuiSlider-markLabel': {
              top: '20px'
            }
          }
        },
      },
    },
  });

  const widgetSizes = [
    {
      value: 0,
      label: "Small"
    },
    {
      value: 50,
      label: "Medium"
    },
    {
      value: 100,
      label: "Large"
    }
  ]

  // const WidgetSizeSlider = styled(Slider)(({ theme }) => ({
  //   color: '#3a8589',
  //   height: 10,
  //   // padding: '23px 0',
  //   '& .MuiSlider-thumb': {
  //     height: 27,
  //     width: 27,
  //     backgroundColor: '#fff',
  //     '&:hover': {
  //       boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
  //     }
  //   },
  //   '& .MuiSlider-track': {
  //     height: 2,
  //     color: '#f8686e',
  //   },
  //   '& .MuiSlider-rail': {
  //     color: theme.palette.mode === 'dark' ? '#f8686e' : '#e8e8e8',
  //     opacity: theme.palette.mode === 'dark' ? undefined : 1,
  //     height: 2,
  //   },
  // }))

  const getDiscretValueForSize = (size) => {
    switch(size) {
      case "small": return 0
      case "medium": return 50
      default: return 100
    }
  }

  const upScaleBorderRadiusValue = (borderRadius) => Math.round(borderRadius * borderRadiusScaleFactor)

  // const SizeSliderThumb = (props) => {
  //   return (
  //     <span {...props}>
  //       <img src={SizeSliderThumbImage} />
  //     </span>
  //   );
  // }
 
  return (
    <div className="widget-body">
      <div className="size">
        <div className="widget-body__header">
          <label htmlFor="size">Widget Size</label>
          <div>{size}</div>
        </div>
        <Slider
          id="size"
          // components={{Thumb: SizeSliderThumb}}
          theme={theme}
          aria-label="Restricted values"
          defaultValue={getDiscretValueForSize(size)}
          step={null}
          value={getDiscretValueForSize(size)}
          valueLabelDisplay="auto"
          marks={widgetSizes}
          onChange={(e) => onSizeChangeHandler(e.target.value)}
          valueLabelDisplay="off"
        />
      </div>
      <div className="radius">
        <div className="widget-body__header">
          <label htmlFor="radius">Round Corners</label>
          <div>{upScaleBorderRadiusValue(radius)}%</div>
        </div>
        <Slider
          id="radius"
          theme={theme}
          aria-label="Temperature"
          value={radius}
          onChange={(e) => onRadiusChangeHandler(e.target.value)}
          min={0}
          max={30}
          color="secondary"
        />
      </div>
    </div>
  );
}
