import info from './../components/ChartWizard/types.json';



      /*

      ageCategory: "adults"
gender: "male"
measurementSystem: "cm"
measurementTypes: (3) ["waist_navel", "neckc", "chestc"]
name: "sx"
sizeChartType: "tops"
sizingType: "alphaNumeric"
      */

const getSizeChartType = (meas) => {

    if(meas.sizeChartType === "shoes"){
        return "shoes_0"
    } 
    else if(meas.sizeChartType === "socks"){
        return "socks_0"
    }
    else if(meas.sizeChartType === "hats"){
        return "hat_0"
    }
    else if(meas.sizeChartType === "bras" && meas.sizingType === "alphaNumeric"){
        return "bra_0"
    }
    else if(meas.ageCategory === "kids"){
        switch (meas.sizeChartType) {
            case "tops":
                return "kids_general_tops"
            case "bottoms":
                return "kids_general_bottoms"
            case "overalls":
                return "kids_general_overall"
            case "dresses":
                return "kids_general_overall"
            default:
                break;
        }
    }
    else if(meas.sizingType === "") {
        ////////////////////////////////
        // User chose not advanced mode
        ////////////////////////////////  

        if(meas.ageCategory === "adults"){
            switch (meas.sizeChartType) {
                  case "tops":
                      return "tops_0"
                  case "bottoms":
                    return "bottoms_0"
                  case "dresses":
                    return "dress_0"
                  case "dress_shirt":
                    return "dress_shirt_grouping_0"
                  case "bras":
                    return "bra_cup_1"
                  case "overalls":
                    return "overall_0"
                  case "shoes":
                    return "shoes_0"
                  case "socks":
                    return "socks_0"
                  case "hats":
                    return "hats_0"
                  default:
                      break;
              }
        } 
        else if(meas.ageCategory === "kids"){
            switch (meas.sizeChartType) {
                case "tops":
                    return "kids_general_tops"
                case "bottoms":
                    return "kids_general_bottoms"
                case "overlall":
                    return "kids_general_overall"
                default:
                    break;
            }
        }
       
          
    }

    const type = info.measurementTypes[meas.sizingType][meas.sizeChartType];
    for (var key of Object.keys(type)) {
        const arr = type[key];
        let contains = true;
        for (let index = 0; index < arr.length; index++) {
            
            if(!meas.measurementTypes.includes(arr[index])){
                contains = false;
                break
            } 
        }
        if(contains){
            return key;
        }
    }
    
}

export default getSizeChartType;