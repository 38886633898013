import React, { useEffect, useState, useContext } from "react";
import "./ChartWizard.scss";
import ChartWizardStepper from "../UI/ChartWizardStepper/ChartWizardStepper";
import xClosebutton from "../../assets/images/x-close-button.svg";
import backArrow from "../../assets/images/back-arrow.svg";
import SizeChartName from "./SizeChartName/SizeChartName";
import { chartWizardPageNames } from "../../utils/page-state";
import SizeChartGender from "./SizeChartGender/SizeChartGender";
import SizeChartUnit from "./SizeChartUnit/SizeChartUnit";
import SizeChartAge from "./SizeChartAge/SizeChartAge";
import SizeChartCategory from "./SizeChartCategory/SizeChartCategory";
import SelectSizingType from "./SelectSizingType/SelectSizingType";
import SelectMeasurementTypes from "./SelectMeasurementTypes/SelectMeasurementTypes";
import getSizeChartType from "../../utils/measure-type-calc";
import ApiService from "../../services/ApiService";
import {getGenderNumber,getMeasurementSystemNumber} from '../../utils/sizeChartFunction'
import {RetailerProfileContext} from '../../context/RetailerProfileContext';
// import { LinearProgress } from "@material-ui/core";

const from_location_to_number = {
  chart_wizard_name: 0,
  chart_wizard_gender: 1,
  chart_wizard_unit: 2,
  chart_wizard_age: 3,
  chart_wizard_category: 4,
};

// const fromUserInputToServerName = (input) => {
//   let newGender = null;
//   switch (input.gender) {
//     case "male":
//       newGender = 0;
//       break;
//     case "female":
//       newGender = 1;
//       break;
//
//     case "unisex":
//       newGender = 2;
//       break;
//
//     default:
//       break;
//   }
//
//   // measurement_type_id
//
//   return { name: input.name, gender: newGender, sizes: [10, 100] };
// };

export default function ChartWizard({ setShowChartWizard, showChartWizard,setProductPairRender,productPairRender, setOpenSelectedSizeChart }) {
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [measurementSystem, setMeasurementSystem] = useState(null);
  const [ageCategory, setAgeCategory] = useState(null);
  const [sizeChartType, setSizeChartType] = useState(null);
  const [sizingType, setSizingType] = useState("");
  const [retailerProfile] = useContext(RetailerProfileContext);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [chartPageState, setChartPageState] = useState(
    chartWizardPageNames.CHART_WIZARD_NAME
  );



  useEffect(() => {
    //  case for unmount ChartWizard
    if (chartPageState === chartWizardPageNames.CHART_WIZARD_DONE) {

      const test = {
        sizingType,
        sizeChartType,
        measurementTypes,
        ageCategory

      }
      const size_chart_type = getSizeChartType(test);
    
    
      const body = {
        version: 2,
        name,
        gender: getGenderNumber(gender),
        measurement_system: getMeasurementSystemNumber(measurementSystem),
        size_chart_type,
        sizes: [],
        age_category: ageCategory
      };

      const addSizeChartFromWizard = async() => {
        const res = await new ApiService().addSizeChart(body);
        setProductPairRender(!productPairRender);
        setOpenSelectedSizeChart(res)
      };

      addSizeChartFromWizard();
      setShowChartWizard(false);
    }
  });

  const backArrowHandler = () => {
    switch (chartPageState) {
      case chartWizardPageNames.CHART_WIZARD_GENDER:
        setChartPageState(chartWizardPageNames.CHART_WIZARD_NAME);
        break;
      case chartWizardPageNames.CHART_WIZARD_UNIT:
        setChartPageState(chartWizardPageNames.CHART_WIZARD_GENDER);
        break;
      case chartWizardPageNames.CHART_WIZARD_AGE:
        setChartPageState(chartWizardPageNames.CHART_WIZARD_UNIT);
        break;
      case chartWizardPageNames.CHART_WIZARD_CATEGORY:
        setChartPageState(chartWizardPageNames.CHART_WIZARD_AGE);
        break;
      case chartWizardPageNames.CHART_WIZARD_SELECT_SIZING_TYPE:
        setChartPageState(chartWizardPageNames.CHART_WIZARD_CATEGORY);
        break;
      default:
        break;
    }
  };

  // renders components by chartPageState's string
  let pageRenderer = null;

  switch (chartPageState) {
    case chartWizardPageNames.CHART_WIZARD_NAME:
      pageRenderer = (
        <SizeChartName
          setChartPageState={setChartPageState}
          name={name}
          setName={setName}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_GENDER:
      pageRenderer = (
        <SizeChartGender
          setChartPageState={setChartPageState}
          gender={gender}
          setGender={setGender}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_UNIT:
      pageRenderer = (
        <SizeChartUnit
          setChartPageState={setChartPageState}
          measurementSystem={measurementSystem}
          setChartMeasurementSystem={setMeasurementSystem}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_AGE:
      pageRenderer = (
        <SizeChartAge
          setChartPageState={setChartPageState}
          ageCategory={ageCategory}
          setAgeCategory={setAgeCategory}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_CATEGORY:
      pageRenderer = (
        <SizeChartCategory
          setChartPageState={setChartPageState}
          sizeChartType={sizeChartType}
          setSizeChartType={setSizeChartType}
          gender={gender}
          ageCategory={ageCategory}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_SELECT_SIZING_TYPE:
      pageRenderer = (
        <SelectSizingType
          sizeChartType={sizeChartType}
          setChartPageState={setChartPageState}
          sizingType={sizingType}
          setSizingType={setSizingType}
        />
      );
      break;
    case chartWizardPageNames.CHART_WIZARD_SELECT_MEASUREMENT_TYPES:
      pageRenderer = (
        <SelectMeasurementTypes
          setChartPageState={setChartPageState}
          sizingType={sizingType}
          sizeChartType={sizeChartType}
          measurementTypes={measurementTypes}
          setMeasurementTypes={setMeasurementTypes}
        />
      );
      break;
    // case chartWizardPageNames.CHART_WIZARD_DONE:
    //   const data = {
    //     name,
    //     gender,
    //    measurementSystem,
    //     ageCategory,
    //     sizeChartType,
    //     sizingType,
    //     measurementTypes,
    //     chartPageState
    //   }
  
    //   break;

    default:
      break;
  }

  let showBackAndStepper = (
    <div>
      <img
        src={backArrow}
        alt="back button"
        className="back-button"
        onClick={backArrowHandler}
      />
      <ChartWizardStepper
        stepNumber={from_location_to_number[chartPageState]}
      />
    </div>
  );

  if (
    chartPageState ===
    chartWizardPageNames.CHART_WIZARD_SELECT_MEASUREMENT_TYPES
  ) {
    showBackAndStepper = null;
  } else if (
    chartPageState === chartWizardPageNames.CHART_WIZARD_SELECT_SIZING_TYPE
  ) {
    showBackAndStepper = (
      <img
        src={backArrow}
        alt="back button"
        className="back-button"
        onClick={backArrowHandler}
      />
    );
  }
  else if (chartPageState === chartWizardPageNames.CHART_WIZARD_NAME) {
    showBackAndStepper = <ChartWizardStepper
    stepNumber={from_location_to_number[chartPageState]}
  />
  }




  return (
    <div style={{top: retailerProfile.source === 'presta' && "30%"}} className="chart-wizard-wrapper">
      {/* <div className="modal" onClick={() => setShowChartWizard(false)}></div> */}
      <div className="chart-wizard">
        <img
          src={xClosebutton}
          alt="closing button"
          className="close-button"
          onClick={() => setShowChartWizard(false)}
        />
        {showBackAndStepper}
        <div className="content-container">{pageRenderer}</div>
      </div>
    </div>
  );
}
