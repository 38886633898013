import React, { useState, useEffect, useContext } from 'react';
import './SizeChartTable.scss';
import addSizeIcon from '../../assets/images/add-size-new.svg';
import SizeRowHeader from '../SizeRowHeader/SizeRowHeader';
import { SizeHolderMatrixContext } from '../../context/SizeHolderMatrixCotext';
import { SizesNameContext } from '../../context/SizesNameContext';
import BraColHeader from '../BraColHeader/BraColHeader';
import { isSizeChartChangedContext } from '../../context/isSizeChartChangedContext';
import closeButton from '../../assets/images/delete-size-icon.svg';
import SizeRangeBox from '../SizeRangeBox/SizeRangeBox';



function SizeChartTable({ show, measurementTypes, setMeasurementTypes, sizeNames, sizeMatrix, setSizeMatrix, setParentSizesName, isMutallyExcluded, isBra, errorCoordiante }) {
    
    const [sizesName, setSizesName] = useState(sizeNames);
    const [sizeHolderMatrix, setSizeHolderMatrix] = useState(sizeMatrix);
    const [widthScroller, setWidthScroller] = useState(false);
    const [heightScroll, setHeightScroll] = useState(false);
    const [, setIsChartChanged] = useContext(isSizeChartChangedContext);



    useEffect(() => {
        setSizeMatrix(sizeHolderMatrix);

        // eslint-disable-next-line
    }, [sizeHolderMatrix])

    useEffect(() => {
        if (widthScroller) {
            document.querySelector('.sizes-container').scrollBy(10000, 0);
        }
        setWidthScroller(false)
    }, [widthScroller])

    useEffect(() => {
        if (heightScroll) {
            document.querySelector('.sizes-container').scrollBy(0, 1000000);
        }
        setHeightScroll(false)
    }, [heightScroll])


    useEffect(() => {
        setParentSizesName(sizesName);
        // eslint-disable-next-line
    }, [sizesName])

    const addSizeRow = () => {
        setIsChartChanged(true);
        var newArray = [];
        if (sizeHolderMatrix.length !== 0) {
            for (var i = 0; i < sizeHolderMatrix.length; i++) {
                newArray[i] = []
                for (var j = 0; j < sizeHolderMatrix[i].length; j++) {
                    newArray[i][j] = { ...sizeHolderMatrix[i][j] }
                }
            }
            newArray[i] = []
            for (var k = 0; k < sizeHolderMatrix[0].length; k++) {
                newArray[i][k] = { from: "", to: "", enabled: measurementTypes[k].enabled, measurementTypeName: measurementTypes[k].name, sizeName: "" }
            }
            setSizeHolderMatrix(newArray)
        }
        else {
            newArray[0] = [];
            for (var l = 0; l < measurementTypes.length; l++) {
                newArray[0][l] = { from: "", to: "", enabled: measurementTypes[l].enabled, measurementTypeName: measurementTypes[l].name, sizeName: "" }
            }
            setSizeHolderMatrix(newArray)
        }
        let names = [...sizesName]
        names.push("")
        setSizesName(names);
        setHeightScroll(true);

    }

    const deleteSizeRow = (rowNumber) => {
        var newArray = [];
        for (var i = 0; i < sizeHolderMatrix.length; i++) {
            newArray[i] = []
            for (var j = 0; j < sizeHolderMatrix[i].length; j++) {
                newArray[i][j] = { ...sizeHolderMatrix[i][j] }
            }
        }
        newArray.splice(rowNumber, 1);
        setSizeHolderMatrix(newArray)
        let names = [...sizesName]
        names.splice(rowNumber, 1);
        setSizesName(names);

    }

    const createSizeRows = () => {
        return sizesName.map((cn, i) => {
            return renderSizeRow(measurementTypes.length, i, errorCoordiante);
        });
    }

    const changeInputSizeName = (event, rowNumber) => {
        setIsChartChanged(true)
        let newArray = [];
        let tempSizesName = [...sizesName]
        tempSizesName[rowNumber] = event.target.value
        setSizesName(tempSizesName);
        for (let i = 0; i < sizeHolderMatrix.length; i++) {
            newArray[i] = [];
            for (let j = 0; j < sizeHolderMatrix[i].length; j++) {
                if (i === rowNumber) newArray[i][j] = { ...sizeHolderMatrix[i][j], sizeName: event.target.value }
                else newArray[i][j] = { ...sizeHolderMatrix[i][j] }
            }
        }
        setSizeHolderMatrix(newArray);
    }

    const renderSizeRow = (numberOfMeasurementTypes, rowNumber, errorCoordiante) => {
        let i;
        let arr = [];
        for (i = 0; i < numberOfMeasurementTypes + 1; i++) {
            let tempArr = [];
            if (i === 0) tempArr.push(<div key={i} className='size-item header'><input
                onChange={(event) => changeInputSizeName(event, rowNumber)} className='enter-size' value={sizesName[rowNumber]}
                placeholder='Enter Size:' />
                <img src={closeButton} onClick={() => { setIsChartChanged(true); deleteSizeRow(rowNumber) }} alt="close button" />
            </div>);
            else tempArr.push(<div key={i} className='size-item'><SizeRangeBox rowNumber={rowNumber} columnNumber={i - 1} shouldMarkError={errorCoordiante !== undefined && errorCoordiante.length !== 0 && errorCoordiante[0] === rowNumber && errorCoordiante[1] === i - 1} /></div>);
            arr.push(tempArr);
        }
        return arr
    }

    const addColumn = () => {
        setIsChartChanged(true);
        let tempMeasurementTypes = [...measurementTypes];
        tempMeasurementTypes.push({ name: "chestc", enabled: true, subName: "" });
        setMeasurementTypes(tempMeasurementTypes);

        let tempSizeHolderMatrix = [...sizeHolderMatrix];
        tempSizeHolderMatrix.forEach(sizeRow => {
            sizeRow.push({ from: '', to: '', enabled: true, measurementTypeName: 'chestc', sizeName: '' })
        })
        setSizeHolderMatrix(tempSizeHolderMatrix);
        setWidthScroller(true);
    }
    return (
        show &&
        <div className='standart-size-chart-creator-wrapper-table'>
            <div className='size-chart-creator-table'>
                {isBra && <img onClick={() => addColumn()} className='add-col-size-icon' src={addSizeIcon} alt="img" />}
                <div className='standart-size-chart-creator-container'>
                    <div className='sizes-container'
                        style={{ 'gridTemplateColumns': `repeat(${measurementTypes.length + 1}, 16.6%)`, height: sizeHolderMatrix.length > 3 ? '210px' : 'unset' }}>
                        <SizesNameContext.Provider value={[sizesName, setSizesName]}>
                            <SizeHolderMatrixContext.Provider value={[sizeHolderMatrix, setSizeHolderMatrix]}>
                                {!isBra ? <SizeRowHeader measurementTypes={measurementTypes} setMeasurementTypes={setMeasurementTypes} isMutallyExcluded={isMutallyExcluded} /> : <BraColHeader measurementTypes={measurementTypes} setMeasurementTypes={setMeasurementTypes} />}
                                {createSizeRows()}
                            </SizeHolderMatrixContext.Provider>
                        </SizesNameContext.Provider>
                    </div>
                    <img onClick={() => addSizeRow()} className='add-size-icon' src={addSizeIcon} alt="add size icon" />
                </div>
            </div>
        </div>

    );
}


export default SizeChartTable;
