import { LocalStorageService } from "./LocalStorageService";
import { config } from "../config";


const api_base_url = config[process.env.REACT_APP_ENV].REACT_APP_API_BASE_URL;
const ROOT_URL = `${api_base_url}/api/v1`;
// const ROOT_URL = "http://localhost:3000/api/v1";
// const ROOT_URL = "http://localhost:3001/api/v1";
// const ROOT_URL = "http://localhost:5000/api/v1";
// const ROOT_URL = "https://api-qa.mysz.io/api/v1";

export class HttpService {
  headers = {};
  constructor(url_prefix = "") {
    this.url_prefix = url_prefix;
    this.getHeaders();
  }

  async get(url, queryParams) {
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          headers: this.headers,
        }
      );
      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  async post(url, body, queryParams = null, allowLocalStorage = true) {
    try {
      console.log('body: ', body)
      !allowLocalStorage && this.getHeaders(false);
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "POST",
          headers: this.headers,
          body: JSON.stringify(body),
        }
      );

      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  async put(url, body, queryParams = null) {
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "PUT",
          headers: this.headers,
          body: JSON.stringify(body),
        }
      );
      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  async remove(url, queryParams = null) {
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "DELETE",
          headers: this.headers,
        }
      );
      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders(allowLocalStorage = true) {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": allowLocalStorage ? (LocalStorageService.get('retailer_token') || null) : null
    };
    // if (this.checkSession()) {
    //     let apiToken = this.getSession().api_token
    //     this.headers = {
    //         ...this.headers,
    //         "Authorisation": `Bearer ${apiToken}`
    //     }
    // }
  }

  // getSession() {
  //     let session = localStorage.getItem(SESSION_KEY)
  //     if (session) {
  //         return JSON.parse(session)
  //     }
  //     return session
  // }

  // checkSession() {
  //     return localStorage.getItem(SESSION_KEY) !== null
  // }

  mapQueryParams(queryParams) {
    return queryParams
      ? Object.keys(queryParams)
        .map(function (key) {
          return key + "=" + queryParams[key];
        })
        .join("&")
      : "";
  }
}
