import { useEffect, useState, useContext } from "react";
import ApiService from "../../services/ApiService";
import "./StoreProduct.scss";
import StoreProductList from "./StoreProductList";
import { ProductFilterArrContext } from '../../context/ProductFilterArrContext';
import { RetailerProfileContext } from '../../context/RetailerProfileContext';
import { CollectionArrContext } from '../../context/CollectionArrContext';
import { CategoriesArrContext } from '../../context/CategoriesArrContext';
import { ManufacturersArrContext } from '../../context/ManufacturersArrContext';

function StoreProduct({tipsState, tipsHandler}) {
  const [storeProducts, setStoreProducts] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [isProductsListLoading, setIsProductsListLoading] = useState(false);
  const [filterParams, setFilterParams] = useState('');
  const [productReceiveFromBe, setProductReceiveFromBe] = useState(false);
  const [productFilterArr, setProductFilterArr] = useState([]);
  const [retailerProfile] = useContext(RetailerProfileContext);
  const [collectionArr, setCollectionArr] = useState([]);
  const [categoiesArr, setCategoiesArr] = useState([]);
  const [manufacturersArr, setManufacturersArr] = useState([]);





  useEffect(() => {
    // setStoreProducts([]);
    setIsProductsListLoading(true);
    let query = buildTheFilterQuery();

    if (query !== filterParams) {
      setProductReceiveFromBe(false);
      setStoreProducts([]);
      setFilterParams(query);
    }

    // eslint-disable-next-line
  }, [productFilterArr]);

  const buildTheFilterQuery = () => {
    if (retailerProfile.source === 'shopify') {
      let query = "";
      productFilterArr.forEach((filter, index) => {

        if (filter.filterType === 'title' || filter.filterType === 'sku')
          query += `${index === 0 ? '' : ` AND `}${filter.filterType}:*${filter.values[0]}*`
        else {
          query += `${index === 0 ? '' : ` AND ${filter.searchType === 'OR' ? '( ' : ""}`}`
          filter.values.forEach((value, valIndex) => {
            query += `${valIndex === 0 ? '' : ` ${filter.searchType} `}${filter.filterType}:${value}`
          })
          query += `${index !== 0 && filter.searchType === 'OR' ? ') ' : ""}`
        }
      });
      return query
    }
    else if (retailerProfile.source === 'wix') {
      let query = {};
      productFilterArr.forEach((filter, index) => {
        if (filter.filterType === 'name' || filter.filterType === 'sku') {
          query[filter.filterType] = { $contains: [filter.values[0]] }
        }
        else {
          let idArr = []
          collectionArr.forEach(collection => {
            if (filter.values.includes(collection.name)) {
              idArr.push(collection.id)
            }

          })

          query[filter.filterType + '.id'] = { $hasSome: idArr }
        }

      })
      return JSON.stringify(query)
    }
    else if (retailerProfile.source === 'presta') {
      let query = "";
      productFilterArr.forEach((filter, index) => {
        if(index > 0){
          query += "&"
        }
        if(filter.filterType !== "id_manufacturer" && filter.filterType !== "id_category_default"){
          query += `filter[${filter.filterType}]=dcvfgbnh[${filter.values[0]}]dcvfgbnh`  
        }
        else{
          let values = []
          if(filter.filterType === "id_manufacturer"){
            manufacturersArr.forEach(manufacturer =>{
              if (filter.values.includes(manufacturer.name)) {
                values.push(manufacturer.id)
              }
            });
          }
          else{
            categoiesArr.forEach(category =>{
              if (filter.values.includes(category.name)) {
                values.push(category.id)
              }
            });
          }
          query += `filter[${filter.filterType}]=[${values.join('|')}]`  
        }
      });
      const queryBase64 = btoa(unescape(encodeURIComponent(query)))

      return queryBase64;
    }
    else if (retailerProfile.source === 'magento') {
      let query = {};
      productFilterArr.forEach((filter, index) => {
        if (filter.filterType === 'name' || filter.filterType === 'sku') {
          query[filter.filterType] = { $contains: [filter.values[0]] }
        }
        else {
          let idArr = []
          collectionArr.forEach(collection => {
            if (filter.values.includes(collection.name)) {
              idArr.push(collection.id)
            }

          })

          query[filter.filterType + '.id'] = { $hasSome: idArr }
        }

      })
      return JSON.stringify(query)
    }
  };

  useEffect(() => {
    fetchShopProducts();
    // eslint-disable-next-line
  }, [filterParams]);



  const fetchShopProducts = async (type = null, pageToken = null) => {
    setIsProductsListLoading(true);

    let res = { nextPage: pageToken, products: [...storeProducts] };
    for (let index = 0; index < 1; index++) {
      let queryParam = "";
      queryParam = `?page_info=${res.nextPage === null ? '' : res.nextPage}&q=${filterParams}`;
      const data = await new ApiService().getAllStoreProdut(queryParam);
      if (!data.next_page || data.products.length === 0) {
        res = {
          nextPage: null,
          products: [...res.products, ...data.products ? data.products : []]
        }
        break;
      } else {
        res = {
          nextPage: data.next_page,
          products: [...res.products, ...data.products]
        }
      }
    }

    const newStoreProduct = [...res.products]

    setStoreProducts(newStoreProduct);
    setProductReceiveFromBe(true);
    setNextPage(res.nextPage);

    setIsProductsListLoading(false);
  };



  return (
    <div className="store-prduct-container">
      <ProductFilterArrContext.Provider value={[productFilterArr, setProductFilterArr]}>
        <CollectionArrContext.Provider value={[collectionArr, setCollectionArr]}>
          <CategoriesArrContext.Provider value={[categoiesArr, setCategoiesArr]} >
            <ManufacturersArrContext.Provider value={[manufacturersArr, setManufacturersArr]} >
              <StoreProductList
                productReceiveFromBe={productReceiveFromBe}
                storeProductsList={storeProducts}
                setStoreProductsList={setStoreProducts}
                isProductsListLoading={isProductsListLoading}
                fetchShopProducts={fetchShopProducts}
                nextPage={nextPage}
                tipsState={tipsState}
                tipsHandler={tipsHandler}
              />
            </ManufacturersArrContext.Provider>
          </CategoriesArrContext.Provider>
        </CollectionArrContext.Provider>
      </ProductFilterArrContext.Provider>
    </div>
  );
}

export default StoreProduct;
