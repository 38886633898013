export default class CommandQueue{  
    constructor(ms = 1500) {
      this.queue = []; 
      this.ms = ms;
    }
    enqueue (func){
      this.queue.push(func);
    }  
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, this.ms));
    }
    async executeAll(){
      let command = this.queue.shift();    
      while(command!==undefined){
        command();
        await this.sleep(this.ms);
        command = this.queue.shift();
      }    
    }
  }