import React, { Component, Fragment, useState, useContext, useEffect } from 'react';
import './Uninstalled.scss';
import logo from '../../assets/images/mysize-icon.svg';
import { useNavigate } from 'react-router-dom';


export default function Uninstalled() {
    const navigate = useNavigate();

    useEffect(() => {
        let timer = setTimeout(() => {
            navigate('/welcome', {replace: true})
        }, 5000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <div className="uninstall-thank-you">
            <img className="uninstall-thank-you__logo" alt="logo" src={logo} />
            <p className="uninstall-thank-you__feedback">{'Thanks for your feedback, \nour team appreciates it.'}</p>
        </div>
    )

}


