import React, { Component, Fragment, useState, useContext } from 'react';
import './Uninstall.scss';
import logo from '../../assets/images/mysize-icon.svg';
import saveIcon from '../../assets/images/Icon-save.svg';
import womanLarge from '../../assets/images/woman-large.webp';
import womanMobile from '../../assets/images/woman-mobile.webp';
import ApiService from '../../services/ApiService';
import { useNavigate } from "react-router-dom";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";


export default function Uninstall() {
    const [shouldDisplayTextArea, setShouldDisplayTextArea] = useState(false);
    // const [isUninstalled, setIsUninstalled] = useState(false);
    const [reasons, setReasons] = useState([
        {
            name: 'size_charts',
            text: 'I don\'t have size charts for the products I sell\n(body size charts or specs)'
        },
        { name: 'implementation_proccess', text: 'The implementation process is too time-consuming' },
        { name: 'dont_undersantd', text: 'I don\'t understand how it works' },
        { name: 'widget', text: 'I don\'t like how the widget looks on my site' },
        { name: 'size_recommendation', text: 'The size recommendations are not accurate' },
        { name: 'price', text: 'The price is too high' },
        { name: 'other', text: 'Other' },
    ])

    const [shouldMark, setShouldMark] = useState(null);
    const [textField, setTextField] = useState("");
    const navigate = useNavigate();
    const [retailerProfile, setRetailerProfile] = useContext(
        RetailerProfileContext
    );




    const markInput = (id) => {

        setShouldMark(id);
        setShouldDisplayTextArea(id === 6 ? true : false);

    };

    const changeText = (e) => {
        setTextField(e.target.value);

    };

    const sendRetailerReason = () => {
        // this.props.dispatch(uiActions.toggleWorking(true));
        // if (this.state.shouldMark !== null) {
        //     setTimeout(() => {
        //         this.props.dispatch(userActions.sendUninstallReason(this.state.shouldMark, this.state.textField));
        //         delete_params_in_local_storage('uninstall_active');
        //         put_params_in_local_storage('display_goodbye_screen', 'true');
        //         this.props.history.push('/goodbye_screen')
        //     }, 500);
        // } else {
        //     this.props.dispatch(uiActions.toggleWorking(false));
        // }

        if (!shouldMark) return;
        const api = new ApiService();
        const response = api.postUninstallReason(shouldMark, textField);
        // setIsUninstalled(true);
        // history.replace("/uninstalled");
        navigate('/uninstalled', {replace: true})

        setTimeout(() => {
            redirectHome();
        }, 3000);


    };


    const redirectHome = () => {
        const newRetailerProfile = { ...retailerProfile };
        newRetailerProfile.account_status = "uninstalled";
        newRetailerProfile.pricing_plan = null;
        setRetailerProfile(newRetailerProfile);
    }


    return  (
        <div id="Uninstall-page">
            <img src={logo} className="image_page" alt="logo" />
            <section className="uninstall-content">
                <div className="uninstall-image">
                    <img src={window.matchMedia('(max-width: 600px)').matches ? womanMobile : womanLarge} alt="woman" />
                </div>
                <div className="uninstall-text">
                    <div className="uninstall-sorry">
                        <h1 className='text-wrepper__sorry'>We're sorry to see you go</h1>
                        <ul>
                            <li>The MySizeID widget has been removed from your website</li>
                            <li>Your plan has been cancelled (you will no longer be billed)</li>
                            <li>Your customers can no longer receive personalized size recommendations (this also applied to customers that have already used MySizeID)
                            </li>
                        </ul>
                    </div>

                    <div className="uninstall-middle">
                        <img src={saveIcon} alt="save" />
                        <div className="uninstall-middle-container">
                            <h2>You're always welcome to come back and pick up exactly where you
                                left off.</h2>
                            <p>Your data is saved in a secure environment and any size charts you created will be waiting for
                                you if you decide to reactivate your plan.
                            </p>
                        </div>
                    </div>

                    <div className="uninstall-survey">
                        <h2>We’d be happy to hear why you decided to uninstall MySizeID and see if there’s anything we can do to improve our service and change your mind.
                            Send us a message to:  <a href="mailto:uninstall@mysizeid.com">uninstall@mysizeid.com</a></h2>
                        <div className="uninstall-survey-options">
                            {reasons.map((reason, index) => {
                                return <div className="survey-option" key={index}>
                                    <input onClick={() => {
                                        markInput(index)
                                    }} className={`checkbox-reason ${shouldMark === index ? "checked" : ""}`}
                                        type="radio"
                                        id={reason.name} value={reason.name} name="survey"/>
                                    <label htmlFor={reason.name}>{reason.text}</label>
                                </div>;
                            })}
                        </div>
                        {shouldDisplayTextArea ?
                            <textarea value={textField} onChange={(e) => changeText(e)}
                                placeholder="I'm leaving because..."
                                className="reason-text-area" /> : null}
                    </div>

                    <button onClick={sendRetailerReason}
                        className={`send-button ${shouldMark !== null ? "available" : ""}`}>SEND
                    </button>

                </div>
            </section>
        </div>
    );

}


