import React,{useState, useContext} from 'react';
import { ReactComponent as MySizeIcon } from "../../assets/images/mysize-icon.svg";
import "./ForgotPassword.scss";
import registrationGirl from '../../assets/images/registration-girl.webp'
import ApiService from '../../services/ApiService';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import {useNavigate, Link} from "react-router-dom";
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import Footer from '../Footer/Footer';
import {emailSchema} from "../../validations/input-validations";








export default function ForgotPassword() {

   const [email, setEmail] = useState(null);
   const [isEmailSent, setIsEmailSent] = useState(false)
   const [error, setError] = useState({show: false, message:""});
   const [isLoading, setIsLoading] = useState(false);
   
   const history = useNavigate();





    const submit = async(e) => {
        e.preventDefault();
        if(!email)  {
            setError({show: true, message:  "Empty field" });    
            return;
        }

        const emailIsValid = await emailSchema.isValid({
            email: email,
          });
      
        if(!emailIsValid){
            setError({show: true, message:  !email.trim() ? "Empty field" : "Invalid Email"});
            return;
        }


        if(isEmailSent){
            history("/signin")
        }
        
        setIsLoading(true);

        const api = new ApiService();
        let response;
        try{
            response = await api.resetPassword(email);
        }catch(error){
            setIsLoading(false);

            console.log(error);
            return;
        }
        
        if(response){
            setIsEmailSent(true);
            setError({show: false})

        }
        else {
            setError({show: true, message: "We don't recognize that email"})
           
        }
        
        setIsLoading(false);
    }



    



    return (
        <div className="forgot-password-container">
              {isLoading && <div className="auth-modal">
                <CircularIndeterminate/>
            </div>}
            <div className="left-side ">
                <div><MySizeIcon/></div>

                <h3>{isEmailSent ? ` Email has been sent to ${email}` :  "Please enter your email to reset password"}</h3>
                <form onSubmit={submit}>
                    {!isEmailSent ? 
                    <>
                        <input id="email" required  type="email" className={`${error.show && 'red-border'}`} onChange={(e) => setEmail(e.target.value.trim())}></input> 
                        <label htmlFor="email" className={email?"focused":""}>Email</label>
                    </>
                    :<div>Follow the instructions in the email to reset your password</div>}
                     {error.show && <div className="error">{error.message}</div>}
                    <button className="signup-button" type="submit" value="Submit"  >{!isEmailSent ? "RESET" : "SIGN IN"}</button>
                    {!isEmailSent && <div className="signin-container">
                        <p className="signin-text">Don’t have an account yet? <Link to="/signup">Sign up</Link></p>
                    </div>}
                    <Footer/>
                </form>

            </div>
            <div className="right-side">
                <img src={registrationGirl} width="457px" alt="registration girl"></img>

            </div>
        </div>
    )
}
