import React, {useEffect} from 'react'

import ApiService from '../../services/ApiService';

export default function RedirectionPage() {
    const redirectToCG = async () => {
        const response = await new ApiService().plansRedirection({ pricing_plan_id: null });
        window.location.href = response?.url ? response.url : "/prepare-account";
    }

    useEffect(() => {
        redirectToCG();
    }, [])

    return (
        <div></div>
    )
}
