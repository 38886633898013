import React, { useState, useEffect, useContext } from 'react'
import { productFilters } from '../../utils/product-filter';
import './ProductFilter.scss';
import arrowLeft from '../../assets/images/arrow-left-title.svg';
import searchIcon from '../../assets/images/search-icon.svg';
import { ProductFilterArrContext } from '../../context/ProductFilterArrContext';



export default function FilterOption({ type, setSelectedOption, shopProperties, setIsFilterOpen, filterToEdit, setFilterToEdit, chooseOption }) {

    const [filterInput, setFilterInput] = useState("");
    const [optionsArray, setOptionArray] = useState(null);
    const [isOr, setIsOr] = useState(true);
    const [productFilterArr, setProductFilterArr] = useContext(ProductFilterArrContext);
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [collectionObj, setCollectionObj] = useState([]);


    useEffect(() => {
        if (filterToEdit.filterId) {
            setCheckedOpt();
        }
        if (type === 'status') {
            setOptionArray(["Active", "Draft", "Archived"]);
            return
        }

        //  PREPARATION for pair/unpair filter 

        if(type === 'pair_status'){
            setOptionArray(["Paired", "Unpaired"]);
            return;
        }
    

        for (const [key, value] of Object.entries(productFilters)) {
            if (key === type) {
                if (value.options === 'fromBE') {
                    setOptionArray(shopProperties[key])
                }
                else if (value.options === 'freeText')
                    setOptionArray(null);

            }
        };
        // eslint-disable-next-line
    }, []);
    const setCheckedOpt = () => {
        for (const [key, value] of Object.entries(productFilters)) {
            if (key === type) {
                if (value.options === 'fromBE' || key === 'status') {
                    setCheckedOptions(filterToEdit.values);
                }
                else if (value.options === 'freeText')
                    setFilterInput(filterToEdit.values[0]);

            }
        };
        setIsOr(filterToEdit.searchType === 'OR' ? true : false);
    }

    const buildSelectOption = () => {
        if (optionsArray === null) return;
        let TempArr = optionsArray.filter(option => option.toLowerCase().includes(filterInput.toLowerCase()));
        return TempArr.map((option, index) => {
            return (
                <div key={76543 + index} className='filter-item' onClick={(event) => { hanledOptionClicked(event, option) }}>
                    <label className="control control-checkbox" onClick={(event) => { hanledOptionClicked(event, event.target.value) }}>
                        <p style={{ margin: 0 }}>{option}</p>
                        <input type="checkbox" name='filter-item' checked={checkedOptions.includes(option)} onChange={(event) => { hanledOptionClicked(event, event.target.value) }} className='filter-check-box' value={option} />
                        <div className="control_indicator"></div>
                    </label>
                </div>
            )
        });
    }

    const hanledOptionClicked = (event, option) => {
        event.stopPropagation();
        let tempArr = [];
        if (checkedOptions.includes(option)) {
            tempArr = [...checkedOptions]
            let index = checkedOptions.indexOf(option);
            tempArr.splice(index, 1);
            setCheckedOptions(tempArr);

        } else {
            tempArr = [...checkedOptions, option]
            setCheckedOptions(tempArr);
            ;
        }
    }

    const makeid = length => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }


    const handleSaveFilter = () => {
        let arr = [];
        if (filterToEdit.filterId === undefined) {
            let filterType
            let displayName
            let arr = [];
            switch (type) {
                case 'vendors':
                    filterType = 'vendor';
                    displayName = 'Vendor';
                    break;
                case 'product_types':
                    filterType = 'product_type';
                    displayName = 'Product Type';
                    break;
                case 'tags':
                    filterType = 'tag';
                    displayName = 'Tagged with';
                    break;
                case 'sku':
                    filterType = 'sku';
                    displayName = 'SKU';
                    break;
                case 'title':
                    filterType = 'title';
                    displayName = 'Title';
                    break;
                case 'status':
                    filterType = 'status';
                    displayName = 'Status';
                    break;
                case 'pair_status':
                    filterType = 'pair_status';
                    displayName = 'Pairing Status';
                    break;
                case 'collections':
                    filterType = 'collections';
                    displayName = 'Collection';
                    break;
                case 'name':
                    filterType = 'name';
                    displayName = 'Name';
                    break;
                case 'manufacturer_name':
                    filterType = 'id_manufacturer';
                    displayName = 'Manufacturer Name';
                    break;
                case 'id_category_default':
                    filterType = 'id_category_default';
                    displayName = 'Main Category';
                    break;
                case 'isbn':
                    filterType = 'isbn';
                    displayName = 'ISBN';
                    break;
                case 'ean13':
                    filterType = 'ean13';
                    displayName = 'EAN13';
                    break;
                case 'upc':
                    filterType = 'upc';
                    displayName = 'UPC';
                    break;
                case 'mpn':
                    filterType = 'mpn';
                    displayName = 'MPN';
                    break;
                default:
                    filterType = type
                    break;
            }


        
            if (filterType !== 'sku' && filterType !== 'title' && filterType !== 'name' && filterType !== 'ean13' && filterType !== 'upc' && filterType !== 'mpn' && filterType !== 'isbn') {
                arr = [...checkedOptions]
                arr = arr.filter(prod => prod !== undefined);
            } else {
                if (filterInput !== "")
                    arr = [filterInput]
                arr = arr.filter(prod => prod !== undefined);
            }
            if (arr.length === 0) return;
            let filterObj = { values: [...arr], searchType: isOr ? 'OR' : 'AND', filterType: filterType, displayName: displayName, filterId: makeid(), type: type }

            let tempProductFilterArr = [...productFilterArr, filterObj]
            setIsFilterOpen(false);
            setProductFilterArr(tempProductFilterArr)
        }
        else {
            if (filterToEdit.filterType !== 'title' && filterToEdit.filterType !== 'sku' && filterToEdit.filterType !== 'name' && filterToEdit.filterType !== 'ean13' && filterToEdit.filterType !== 'upc' && filterToEdit.filterType !== 'mpn' && filterToEdit.filterType !== 'isbn') {
                arr = [...checkedOptions];
                arr = arr.filter(prod => prod !== undefined);
            } else {
                if (filterInput !== "")
                    arr = [filterInput]
                arr = arr.filter(prod => prod !== undefined);
            }
            if (arr.length === 0)
                return;

            let tempArr = productFilterArr.map(prod => {
                if (prod.filterId === filterToEdit.filterId) {
                    let tempProduct = { ...prod, values: arr, searchType: isOr ? 'OR' : 'AND' }
                    return tempProduct
                }
                else { return prod }

            })

            setProductFilterArr(tempArr);
            setFilterToEdit({});
        }
    }



    const getTitleDisplayName = () => {
        for (const [key, value] of Object.entries(productFilters)) {
            if (key === type) {
                return value.displayName;
            }
        };
    }

    return (
        <div className='filter-option'>
            <div className='filter-header'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img onClick={() => { setSelectedOption(null); setFilterToEdit({}) }} src={arrowLeft} alt='go back arrow' />
                    <p className='title-para'>{getTitleDisplayName()}</p>
                </div>
                {chooseOption &&
                    <div className='and-or-section'>
                        <p onClick={() => setIsOr(false)} style={{ color: isOr === false && '#f8686e', fontWeight: isOr === false && 'bold' }}>and</p>
                        <p onClick={() => setIsOr(true)} style={{ color: isOr && '#f8686e', fontWeight: isOr && 'bold' }}>or</p>
                    </div>
                }

            </div>
            <div className='input-wrapper'>
                <img src={searchIcon} className="search-icon" alt='search icon' />
                <input value={filterInput} onChange={(event) => { setFilterInput(event.target.value) }} type='text' className='filter-input' />
            </div>
            {/*     //  PREPARATION for pair/unpair filter  */}
            {['vendors', 'product_types', 'tags', 'status',"pair_status", 'collections', 'manufacturer_name', 'id_category_default'].includes(type) && <div className='filter-select-list' >
            {/* {['vendors', 'product_types', 'tags', 'status', 'collections', 'manufacturer_name', 'id_category_default'].includes(type) && <div className='filter-select-list' > */}
                {buildSelectOption()}
            </div>}
            <button className='save-filter' onClick={() => { handleSaveFilter() }}>{filterToEdit.filterId ? 'Update Filter' : 'Add Filter'}</button>
        </div>
    )
}

