import React, { useContext, useState } from 'react';
import './Plans.scss';
import chooseYourPlan from "../../assets/images/plans/choose-your-plan.png"
import planAlertIcon from "../../assets/images/plans/plan-alert-icon.svg"
import noIcon from "../../assets/images/plans/no-icon.svg"
import yesIcon from "../../assets/images/plans/yes-icon.svg"
import ApiService from '../../services/ApiService';
import TopBar from '../TopBar/TopBar';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import currentPlanImg from '../../assets/images/plans/current-plan-img.png';
import { LocalStorageService } from '../../services/LocalStorageService';
import Carousel from 'react-bootstrap/Carousel';
import {config} from '../../config';


const planNames = {
   PETITE: "petite",
   FREE: 'free',
   MEDIUM: 'medium'
}


const { 
   REACT_APP_PETITE_PLAN_ID,
   REACT_APP_FREE_PLAN_ID,
   REACT_APP_MEDIUM_PLAN_ID, 
   REACT_APP_PRO_PLAN_ID
} = config[process.env.REACT_APP_ENV];

export default function Plans() {
   const [retailerProfile] = useContext(
      RetailerProfileContext
   );

   const [planLoading, setPlanLoading] = useState(false);

   let loadingPlan = false

   const choosePlanHandler = async (planName) => {

      const retailersPlanId = retailerProfile.pricing_plan && retailerProfile.pricing_plan.id;

      let planId = null;
      switch (planName) {
         case planNames.PETITE:
            planId = REACT_APP_PETITE_PLAN_ID
            break;
         case planNames.FREE:
            planId = REACT_APP_FREE_PLAN_ID
            break;
         case planNames.MEDIUM:
            planId = REACT_APP_MEDIUM_PLAN_ID
            break;
         default:
            break;
      }


      // Current plan clicked scenario
      // OR
      // plansRedirection is loading

      if (parseInt(planId) === retailersPlanId) {
         return;
      };


      // setPlanLoading(true)
      // if (!planLoading) {
      if (!loadingPlan) {
         document.body.style.cursor='wait'
         loadingPlan=true
         const response = await new ApiService().plansRedirection({ pricing_plan_id: planId });
         // const {apiKey, host, url} = response
         if (retailerProfile.source === 'shopify'){
            console.log('sending msg', response.url);
            window.parent.postMessage({type: "MYSIZE_REDIRECT", redirect_url: response.url}, "*")
            return
         } 
         LocalStorageService.set('MYSIZE_plan', {createdAt:new Date(), currentPlanId: retailersPlanId, targetPlanId: parseInt(planId)})
         document.body.style.cursor='default'
         if (retailerProfile.source !== 'wix') {
            window.location.href = response.url;
         }
         else {
            window.top.location = response.url;
         }
      }


   }

   const retailersPricingPlanId = retailerProfile.pricing_plan && retailerProfile.pricing_plan.id;

   const [isFreeShown, setIsFreeShown] = useState(false);
   const [isPetitShown, setIsPetitShown] = useState(false);
   const [isMediumShown, setIsMediumShown] = useState(false);
   const [isProShown, setIsProShown] = useState(false);

   const [isLevelSuportShown, setIsLevelSuportShown] = useState(false);
   const [isSizeChartSupportShown, setIsSizeChartSupportShown] = useState(false);
   const [isSizeChartDynamicShown, setIsSizeChartDynamicShown] = useState(false);
   const [isMySizeButtonShown, setIsMySizeButtonShown] = useState(false);
   const [isAnaliticSuportShown, setIsAnaliticSuportShown] = useState(false);
   const [isGetFeaturedShown, setIsGetFeaturedShown] = useState(false);
   const [isInStoreShown, setIsInStoreShown] = useState(false);

   const [isPetitTooltipShown, setIsPetitTooltipShown] = useState(false);
   const [isMediumTooltipShown, setIsMediumTooltipShown] = useState(false);

   const handleClick = event => {
      setIsFreeShown(current => !current);
      setIsPetitShown(current => !current);
      setIsMediumShown(current => !current);
      setIsProShown(current => !current);
   }

   const handleFreeClick = event => {
      setIsFreeShown(current => !current);
      setIsPetitShown(false);
      setIsMediumShown(false);
      setIsProShown(false);
   };

   const handlePetitClick = event => {
      setIsPetitShown(current => !current);
      setIsFreeShown(false);
      setIsMediumShown(false);
      setIsProShown(false);
   };

   const handleMediumClick = event => {
      setIsMediumShown(current => !current);
      setIsFreeShown(false);
      setIsPetitShown(false);
      setIsProShown(false);
   };

   const handleProClick = event => {
      setIsProShown(current => !current);
      setIsFreeShown(false);
      setIsPetitShown(false);
      setIsMediumShown(false);
   };

   const handleLevelSuportClick = event => {
      setIsLevelSuportShown(current => !current)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
   };

   const handleSizeChartSupportClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(current => !current);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
   };

   const handleSizeChartDynamicClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(current => !current);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
   };

   const handleMySizeButtonClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(current => !current);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
   };

   const handleAnaliticSuportClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(current => !current);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
   };

   const handleGetFeaturedClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(current => !current);
      setIsInStoreShown(false);
   };

   const handleStoreClick = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(current => !current);
   };

   const handlePetitTooltipShow = event => {
      setIsPetitTooltipShown(current => !current)
   }

   const handleMediumTooltipShow = event => {
      setIsMediumTooltipShown(current => !current)
   }

   const clickOutside = event => {
      setIsLevelSuportShown(false)
      setIsSizeChartSupportShown(false);
      setIsSizeChartDynamicShown(false);
      setIsMySizeButtonShown(false);
      setIsAnaliticSuportShown(false);
      setIsGetFeaturedShown(false);
      setIsInStoreShown(false);
      setIsPetitTooltipShown(false);
      setIsMediumTooltipShown(false);
   }


   return (
      (retailerProfile.source === 'presta' || retailerProfile.source === 'bitrix')  ?
         <div className="bitrix-pricing-plan">
            <TopBar />
            <div className="bitrix-pricing-plan-container">
               <h1>Pick Your MySizeID Plan</h1>
               <h3>MySizeID helps shoppers buy perfectly fitting clothes they won’t want to return</h3>
               <div className="pricing-plan-items">

                  <div style={{ position: 'relative' }} className="pricing-plan-item">
                     {retailerProfile?.pricing_plan?.name === 'Free' && 
                        <img style={{ position: 'absolute', top: '-25px', left: '-20px' }} src={currentPlanImg} />
                     }
                     <span className="pricing-plan-item-title">FREE</span>
                     <p>
                        Ideal for small e-commerce websites that want to improve their customers’ shopping experience, without any added expenses.
                                    <span className="bold-text"> The free plan includes 75 size recommendations.</span>
                     </p>
                     <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box', borderColor: "black" }} className="choose-plan link-btn">
                        <div onClick={() => choosePlanHandler(planNames.FREE)} style={{ fontSize: '13px', fontWeight: 'bold', boxSizing: 'border-box',padding: "10px 0", width: "250px", borderRadius: "30px" }} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) && "plan__active-btn"} `}>Current Plan</div>
                     </div>
                  </div>


                  <div style={{ position: 'relative' }} className="pricing-plan-item">
                  {retailerProfile?.pricing_plan?.name === 'Professional' && 
                        <img style={{ position: 'absolute', top: '-25px', right: '362px' }} src={currentPlanImg} />
                     }
                     <span className="pricing-plan-item-title">PRO</span>
                     <p>Tailored plans for large scale e-commerce websites that need more than 75 size recommendations. Contact our support team at <a href="mailto:contact@mysizeid.com"> contact@mysizeid.com </a>for your customized plan and pricing.</p>
                     <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box' }} className={`choose-plan link-btn` }>
                        <a style={{ fontSize: '13px', fontWeight: 'bold', boxSizing: 'border-box',padding: "10px 0", width: "250px", borderRadius: "30px" }} className={`btn-choose-plan  ${retailersPricingPlanId === parseInt(REACT_APP_PRO_PLAN_ID)}`} href="mailto:contact@mysizeid.com">Contact Support</a>
                     </div>
                  </div>
               </div>
            </div>
         </div> :

         <div className="plans-1">
            <TopBar />
            <div style={{ background: "rgb(245, 245, 245)" }}>
               <div className="gray-background-fixed show" style={{ position: "fixed", width: "100%", height: "100%", background: "rgb(245, 245, 245)", zIndex: "-5", left:0 }}></div>
               <div>
                  <div className="switch-wrapper">
                     <div style={{ opacity: "1" }}>
                        <div className="AuthorizedLayout-root-1page-wrap" style={{ width: "100%" }}>
                           <div className="MuiGrid-container-3">

                              <div className="container">
                                 <div className="plans" style={{ marginTop: "50px" }}>
                                    <div className="plans-static-page">
                                       <div className="plan-container">
                                          <div className="plan-section-left">
                                             <div className="pricing-plan-logo"><img src={chooseYourPlan} alt="choose-your-plan"></img></div>
                                             <div className="pricing-plan-items-section">
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>Level of Support</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">Email Support: 08:00 - 17:00 <br /> We are here to help you with whatever you need.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>Dedicated Account Manager</span></div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>Size Chart Support</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">Your current size charts can be uploaded in the MySizeID MyDash for automatic matchmaking.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>Number of Size Charts</span></div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>Dynamic Size Chart</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">Automatic size chart connection to thr relevant item.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>“What’s My Size” Button</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">You can tailor the button to match your website's look and feel.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>Specialist Reviews</span></div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>Analytics Support</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">Every month you will receive the usage stats from our AI engine.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>Get Featured in MySizeID <br /> App Shopping Center</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">We will feature your store in the MySizeID app so you can get more visibility for your brand.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap">
                                                      <span>In Store</span>
                                                      <span>
                                                         <img src={planAlertIcon} alt="choose-your-plan"></img>
                                                         <div className="notice">Use the MySizeID tool to help your consumers find the right size in your brick and mortal store.</div>
                                                      </span>
                                                   </div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>API Connectors</span></div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>PLM Integration</span></div>
                                                </div>
                                                <div className="pricing-plan-item">
                                                   <div className="pricing-plan-item-wrap"><span>AI Decision</span></div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="plan-section-right">
                                             <div className="plan-section-lite">
                                                <h2>MYSIZEID LITE</h2>
                                                <div className="plan-section-lite-items">
                                                   <div className="plan-section-lite-item">
                                                      <p className="plan-name">Try us out </p>
                                                      <p className="plan-price">Free</p>
                                                      <p className="plan-description">Perfect for small and budget conscious retailers who want to get started with size recommendations</p>
                                                      <p className="plan-size-recommendations">Size Recommendations</p>
                                                      <p className="plan-size-recommendations-count">75</p>
                                                      <p className="choose-plan" style={{ pointerEvents: retailerProfile.source === 'wix' && 'none', opacity: retailerProfile.source === 'wix' && '0.5', cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.FREE)}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} href={'#'} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) && "current-plan noHover"}`} >{retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></p>
                                                      <section className="plan-store-listing">
                                                         <div className="plan-store-listing-item">
                                                            <div className="plan-store-listing-item small-text">Email Support <br />(Business Hours Only)</div>
                                                         </div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">5</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">MySize Branding</div>
                                                         <div className="plan-store-listing-item">5</div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item section-btn" style={{ pointerEvents: retailerProfile.source === 'wix' && 'none', opacity: retailerProfile.source === 'wix' && '0.5', cursor: 'pointer' }} onClick={() => choosePlanHandler(planNames.FREE)}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} href={"#"} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) && "current-plan noHover"}`} >{retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                      </section>
                                                   </div>
                                                   <div className="plan-section-lite-item">
                                                      <p className="plan-name">Petite <img src={planAlertIcon} alt="Petite" style={{ display: "inline" }}></img><span className="notice">You can scale up with 2k more size recommendations per month for only +$39 per month.</span></p>
                                                      <div className="plan-price"><span className="plan-price-currency-symbol"></span><span style={{ height: "10px", left: "39px", top: "10px", position: "absolute", fontSize: "25px" }}>$</span><div style={{ marginRight: "25px" }}>49</div><span className="plan-price-month plan-price-month">/mo</span></div>
                                                      <p className="plan-description">Great for small and mid-size retailers who want to try more size recommendations and brand the size recommendation tool as their own.</p>
                                                      <p className="plan-size-recommendations">Size Recommendations</p>
                                                      <p className="plan-size-recommendations-count">2,000</p>
                                                      <p style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} className="choose-plan" onClick={() => { choosePlanHandler(planNames.PETITE) }}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} href='#' className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></p>
                                                      <section className="plan-store-listing">
                                                         <div className="plan-store-listing-item">
                                                            <div className="plan-store-listing-item small-text">Email Support <br />(Business Hours Only)</div>
                                                         </div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Unlimited</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Custom Branding</div>
                                                         <div className="plan-store-listing-item">15</div>
                                                         <div className="plan-store-listing-item">Basic</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item section-btn" style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => { choosePlanHandler(planNames.PETITE) }}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'default' : 'pointer' }} href={'#'} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) && "current-plan"}`}>{retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                      </section>
                                                   </div>
                                                   <div className="plan-section-lite-item">
                                                      <p className="plan-name">Medium <img src={planAlertIcon} alt="Petite" style={{ display: "inline" }}></img><span className="notice large-text">You can scale up with 5k more size recommendations per month for only +$49 per month.</span></p>
                                                      <div className="plan-price"><span className="plan-price-currency-symbol plan-price-currency-symbol-large"></span><div style={{ marginRight: "25px" }}><span style={{ height: "10px", left: "30px", top: "10px", position: "absolute", fontSize: "25px" }}>$</span>199</div><span className="plan-price-month plan-price-month-large">/mo</span></div>
                                                      <p className="plan-description">Great for mid-size retailers who want to scale up further with more size recommendations than the Petite Plan.</p>
                                                      <p className="plan-size-recommendations">Size Recommendations</p>
                                                      <p className="plan-size-recommendations-count">12,000</p>
                                                      <p className={"choose-plan"} style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.MEDIUM)} ><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} href={"#"} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></p>
                                                      <section className="plan-store-listing">
                                                         <div className="plan-store-listing-item">
                                                            <div className="plan-store-listing-item small-text">Email Support <br />(Business Hours Only)</div>
                                                         </div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Unlimited</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Custom Branding</div>
                                                         <div className="plan-store-listing-item">25</div>
                                                         <div className="plan-store-listing-item">Basic</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={noIcon} alt="no"></img></span></div>
                                                         <div style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} className="plan-store-listing-item section-btn" onClick={() => choosePlanHandler(planNames.MEDIUM)}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} href={'#'} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                      </section>
                                                   </div>
                                                </div>
                                             </div>

                                             {(retailerProfile.source !== 'shopify' && retailerProfile.source !== 'wix') && <div className="plan-section-pro">
                                                <h2>MYSIZEID PRO</h2>
                                                <div className="plan-section-lite-items">
                                                   <div className="plan-section-lite-item">
                                                      <p className="plan-name">Pro </p>
                                                      <p className="plan-price">Call Us</p>
                                                      <p className="plan-description">Essential for enterprise and large-scale fashion brands and groups; multi-site, multi-location and marketplaces.</p>
                                                      <p className="plan-size-recommendations">Size Recommendations</p>
                                                      <p className="plan-size-recommendations-count">Unlimited</p>
                                                      <p className="choose-plan"><a href="tel:+18008474412" className="btn-choose-plan">(+1)800-847-4412</a></p>
                                                      <section className="plan-store-listing">
                                                         <div className="plan-store-listing-item">
                                                            <div className="plan-store-listing-item small-text">24/7 Support; Email<br />&amp; Phone</div>
                                                         </div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Unlimited</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item">Custom Branding</div>
                                                         <div className="plan-store-listing-item">Unlimited</div>
                                                         <div className="plan-store-listing-item">Full</div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item"><span><img src={yesIcon} alt="yes"></img></span></div>
                                                         <div className="plan-store-listing-item section-btn"><a href="tel:+18008474412" className="btn-choose-plan">(+1)800-847-4412</a></div>
                                                      </section>
                                                   </div>
                                                </div>
                                             </div>}

                                          </div>
                                       </div>

                                       <div className='mobile-carousel' onMouseOut={clickOutside}>
                                          <h1>Choose Your Plan</h1>
                                          <Carousel interval={isFreeShown || isPetitShown || isMediumShown || isProShown ? null : 10000}>
                                             <Carousel.Item>
                                                <section className="pricing-plan-mobile">
                                                   <div className="pricing-plan-mobile-container">
                                                      <div className="pricing-plan-mobile-item">
                                                         <div className="pricing-plan-mobile-title">MYSIZEID LITE</div>
                                                         <h3>Try us out</h3>
                                                         <div className="pricing-plan-mobile-price">Free</div>
                                                         <div className="pricing-plan-mobile-desc">Perfect for small and budget conscious retailers who want to get started with size recommendations</div>
                                                         <div className="pricing-plan-mobile-size-recomendation">Size Recommendations</div>
                                                         <div className="pricing-plan-mobile-sizecount">75</div>
                                                         <div className="pricing-plan-mobile-choose-plan" style={{ pointerEvents: retailerProfile.source === 'wix' && 'none', opacity: retailerProfile.source === 'wix' && '0.5', cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.FREE)}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} href={'#'} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) && "current-plan noHover"}`} >{retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                         <div className="pricing-plan-mobile-show-details"><a onClick={handleClick}>Show details</a></div>
                                                      </div>
                                                   </div>
                                                </section>
                                                { isFreeShown
                                                    ?
                                                    <div className="pricing-plan-mobile-container more-info" id={"results"}>
                                                       <div className="pricing-plan-mobile-listing">
                                                          <h1>Free</h1>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Level of Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleLevelSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Email Support (Business Hours Only)</div>
                                                             {isLevelSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Email Support: 08:00 – 17:00 <br/>We are here to help you with whatever you need </div>
                                                                 : null
                                                             }

                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dedicated Account Manager</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Size Chart Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartSupportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartSupportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Your current size charts can be uploaded in the MySizeID MyDash for automatic matchmaking </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Number of Size Charts</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">5</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dynamic Size Chart</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartDynamicClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartDynamicShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Automatic size chart connection to the relevant item </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">“What’s My Size” Button</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleMySizeButtonClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">MySize Branding</div>
                                                             {isMySizeButtonShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">You can tailor the button to match your website’s look and feel.</div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Specialist Reviews</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">5</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Analytics Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleAnaliticSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                             {isAnaliticSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Every month you will receive the usage stats from our AI engine. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Get Featured in MySizeID App Shopping Center</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleGetFeaturedClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                             {isGetFeaturedShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">We will feature your store in the MySizeID app so you can get more visibility for your brand. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">In Store</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleStoreClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                             {isInStoreShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Use the MySizeID tool to help your consumers find the right size in your brick and mortar store </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">API Connectors</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">PLM Integration</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">AI Decision</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item button-area">
                                                             <div className="pricing-plan-mobile-choose-plan" style={{ pointerEvents: retailerProfile.source === 'wix' && 'none', opacity: retailerProfile.source === 'wix' && '0.5', cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.FREE)}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? 'default' : 'pointer' }} href={'#'} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) && "current-plan noHover"}`} >{retailersPricingPlanId === parseInt(REACT_APP_FREE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                          </div>
                                                       </div>
                                                    </div>
                                                    : null
                                                }
                                             </Carousel.Item>
                                             <Carousel.Item>
                                                <section className="pricing-plan-mobile">
                                                   <div className="pricing-plan-mobile-container">
                                                      <div className="pricing-plan-mobile-item">
                                                         <div className="pricing-plan-mobile-title">MYSIZEID LITE</div>
                                                         <div className="title-plan-h1"><span>Petite</span><img src={planAlertIcon} alt="notice" onClick={handlePetitTooltipShow}></img></div>
                                                         {isPetitTooltipShown
                                                             ? <div className='pricing-plan-mobile-listing-item-tooltip-top'>You can scale up with 2k more size recommendations per month for only +$39 per month.</div>
                                                         : null
                                                         }
                                                         <div className="pricing-plan-mobile-price"><span className="currency-symbol">$</span><span style={{ paddingLeft: "15px" }}>49</span><span className="period-symbol">/mo</span></div>
                                                         <div className="pricing-plan-mobile-desc">Perfect for small and budget conscious retailers who want to get started with size recommendations</div>
                                                         <div className="pricing-plan-mobile-size-recomendation">Size Recommendations</div>
                                                         <div className="pricing-plan-mobile-sizecount">2,000</div>
                                                         <div style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} className="pricing-plan-mobile-choose-plan" onClick={() => { choosePlanHandler(planNames.PETITE) }}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} href='#' className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                         <div className="pricing-plan-mobile-show-details"><a onClick={handleClick}>Show details</a></div>
                                                      </div>
                                                   </div>
                                                </section>
                                                {isPetitShown
                                                    ?
                                                    <div className="pricing-plan-mobile-container more-info">
                                                       <div className="pricing-plan-mobile-listing">
                                                          <h1>Petite</h1>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Level of Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleLevelSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Email Support (Business Hours Only)</div>
                                                             {isLevelSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Email Support: 08:00 – 17:00 <br/>We are here to help you with whatever you need </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dedicated Account Manager</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Size Chart Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartSupportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartSupportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Your current size charts can be uploaded in the MySizeID MyDash for automatic matchmaking </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Number of Size Charts</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Unlimited</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dynamic Size Chart</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartDynamicClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartDynamicShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Automatic size chart connection to the relevant item </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">“What’s My Size” Button</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleMySizeButtonClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Custom Branding</div>
                                                             {isMySizeButtonShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">You can tailor the button to match your website’s look and feel.</div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Specialist Reviews</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">15</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Analytics Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleAnaliticSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Basic</div>
                                                             {isAnaliticSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Every month you will receive the usage stats from our AI engine. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Get Featured in MySizeID App Shopping Center</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleGetFeaturedClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isGetFeaturedShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">We will feature your store in the MySizeID app so you can get more visibility for your brand. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">In Store</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleStoreClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                             {isInStoreShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Use the MySizeID tool to help your consumers find the right size in your brick and mortar store </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">API Connectors</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">PLM Integration</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">AI Decision</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item button-area">
                                                             <div style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} className="pricing-plan-mobile-choose-plan" onClick={() => { choosePlanHandler(planNames.PETITE) }}><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? 'none' : 'pointer' }} href='#' className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_PETITE_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                          </div>
                                                       </div>
                                                    </div>
                                                    : null
                                                }
                                             </Carousel.Item>
                                             <Carousel.Item>
                                                <section className="pricing-plan-mobile">
                                                   <div className="pricing-plan-mobile-container">
                                                      <div className="pricing-plan-mobile-item">
                                                         <div className="pricing-plan-mobile-title">MYSIZEID LITE</div>
                                                         <div className="title-plan-h1"><span>Medium</span><img src={planAlertIcon} alt="notice" onClick={handleMediumTooltipShow}></img></div>
                                                         {isMediumTooltipShown
                                                             ? <div className='pricing-plan-mobile-listing-item-tooltip-top'>You can scale up with 5k more size recommendations per month for only +$49 per month.</div>
                                                             : null
                                                         }
                                                         <div className="pricing-plan-mobile-price"><span className="currency-symbol">$</span><span style={{ paddingLeft: "15px" }}>199</span><span className="period-symbol">/mo</span></div>
                                                         <div className="pricing-plan-mobile-desc">Perfect for small and budget conscious retailers who want to get started with size recommendations</div>
                                                         <div className="pricing-plan-mobile-size-recomendation">Size Recommendations</div>
                                                         <div className="pricing-plan-mobile-sizecount">12,000</div>
                                                         <div className={"pricing-plan-mobile-choose-plan"} style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.MEDIUM)} ><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} href={"#"} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                         <div className="pricing-plan-mobile-show-details"><a onClick={handleClick}>Show details</a></div>
                                                      </div>
                                                   </div>
                                                </section>
                                                {isMediumShown
                                                    ?
                                                    <div className="pricing-plan-mobile-container  more-info">
                                                       <div className="pricing-plan-mobile-listing">
                                                          <h1>Medium</h1>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Level of Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleLevelSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Email Support (Business Hours Only)</div>
                                                             {isLevelSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Email Support: 08:00 – 17:00 <br/>We are here to help you with whatever you need </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dedicated Account Manager</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Size Chart Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartSupportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartSupportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Your current size charts can be uploaded in the MySizeID MyDash for automatic matchmaking </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Number of Size Charts</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Unlimited</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dynamic Size Chart</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartDynamicClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartDynamicShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Automatic size chart connection to the relevant item </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">“What’s My Size” Button</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleMySizeButtonClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Custom Branding</div>
                                                             {isMySizeButtonShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">You can tailor the button to match your website’s look and feel.</div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Specialist Reviews</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">25</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Analytics Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleAnaliticSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Basic</div>
                                                             {isAnaliticSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Every month you will receive the usage stats from our AI engine. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Get Featured in MySizeID App Shopping Center</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleGetFeaturedClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isGetFeaturedShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">We will feature your store in the MySizeID app so you can get more visibility for your brand. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">In Store</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleStoreClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                             {isInStoreShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Use the MySizeID tool to help your consumers find the right size in your brick and mortar store </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">API Connectors</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">PLM Integration</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">AI Decision</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={noIcon} alt="no"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item button-area">
                                                             <div className={"pricing-plan-mobile-choose-plan"} style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} onClick={() => choosePlanHandler(planNames.MEDIUM)} ><a style={{ cursor: retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? 'default' : 'pointer' }} href={"#"} className={`btn-choose-plan ${retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) && "current-plan noHover"}`}>{retailersPricingPlanId === parseInt(REACT_APP_MEDIUM_PLAN_ID) ? "CURRENT PLAN" : "CHOOSE PLAN"}</a></div>
                                                          </div>
                                                       </div>
                                                    </div>
                                                    :null
                                                }
                                             </Carousel.Item>
                                             {retailerProfile.source !== 'wix' && retailerProfile.source !== 'shopify'  && <Carousel.Item>
                                                <section className="pricing-plan-mobile">
                                                   <div className="pricing-plan-mobile-container">
                                                      <div className="pricing-plan-mobile-item">
                                                         <div className="pricing-plan-mobile-title">MYSIZEID PRO</div>
                                                         <h3>Pro</h3>
                                                         <div className="pricing-plan-mobile-price">Call Us</div>
                                                         <div className="pricing-plan-mobile-desc">Perfect for small and budget conscious retailers who want to get started with size recommendations</div>
                                                         <div className="pricing-plan-mobile-size-recomendation">Size Recommendations</div>
                                                         <div className="pricing-plan-mobile-sizecount">Unlimited</div>
                                                         <div className="pricing-plan-mobile-choose-plan"><a href="tel:+18008474412" className="btn-choose-plan">(+1)800-847-4412</a></div>
                                                         <div className="pricing-plan-mobile-show-details"><a onClick={handleClick}>Show details</a></div>
                                                      </div>
                                                   </div>
                                                </section>
                                                {isProShown
                                                    ?
                                                    <div className="pricing-plan-mobile-container  more-info">
                                                       <div className="pricing-plan-mobile-listing">
                                                          <h1>Pro</h1>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Level of Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleLevelSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Email Support (Business Hours Only)</div>
                                                             {isLevelSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Email Support: 08:00 – 17:00 <br/>We are here to help you with whatever you need </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dedicated Account Manager</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Size Chart Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartSupportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartSupportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Your current size charts can be uploaded in the MySizeID MyDash for automatic matchmaking </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Number of Size Charts</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Unlimited</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Dynamic Size Chart</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleSizeChartDynamicClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isSizeChartDynamicShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Automatic size chart connection to the relevant item </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">“What’s My Size” Button</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleMySizeButtonClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Custom Branding</div>
                                                             {isMySizeButtonShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">You can tailor the button to match your website’s look and feel.</div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Specialist Reviews</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Unlimited</div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Analytics Support</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleAnaliticSuportClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value">Full</div>
                                                             {isAnaliticSuportShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Every month you will receive the usage stats from our AI engine. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">Get Featured in MySizeID App Shopping Center</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleGetFeaturedClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isGetFeaturedShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">We will feature your store in the MySizeID app so you can get more visibility for your brand. </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">In Store</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"><img src={planAlertIcon} alt="notice" onClick={handleStoreClick}></img></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                             {isInStoreShown
                                                                 ? <div className="pricing-plan-mobile-listing-item-tooltip">Use the MySizeID tool to help your consumers find the right size in your brick and mortar store </div>
                                                                 : null
                                                             }
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">API Connectors</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">PLM Integration</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item">
                                                             <div className="pricing-plan-mobile-listing-item-title">AI Decision</div>
                                                             <div className="pricing-plan-mobile-listing-item-info"></div>
                                                             <div className="pricing-plan-mobile-listing-item-value"><img src={yesIcon} alt="yes"></img></div>
                                                          </div>
                                                          <div className="pricing-plan-mobile-listing-item button-area">
                                                             <div className="pricing-plan-mobile-choose-plan"><a href="tel:+18008474412" className="btn-choose-plan">(+1)800-847-4412</a></div>
                                                          </div>
                                                       </div>
                                                    </div>
                                                    : null
                                                }
                                             </Carousel.Item>}
                                          </Carousel>
                                       </div>
                                       <section className="bottom-text-section">
                                          <h2>Good to know</h2>
                                          <h3>All plans are renewed monthly.<br />Pricing plans are based on the number of size recommendations, counted each time a user interacts with the MySizeID size recommendation widget. <br /> Size recommendations are based on:<br />1. Size chart data uploaded to MyDash<br />2. A shoppers’ profile, built via the MySizeID app OR via a 3 questions wizard</h3>
                                       </section>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
   )
}

