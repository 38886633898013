const rangesValidation = require("./range-validation");
const emptyFieldsValidation = require("./empty-field-validation");
const consecuutiveGrowthVlidation = require("./consecutive-growth-validation");
const minimumMaximumValidation = require("./minimum-maximum-validations");
const sameSizeNameValidation = require("./same-size-name-validation");



export const validate = (savedData,matrix,chartMeasurementSystem,tableType) => {
    let consecuutiveGrowthVlidationErrors = [];
    const rangesValidationErrors = rangesValidation.validate(matrix,chartMeasurementSystem,tableType);
    const emptyFieldsValidationErrors = emptyFieldsValidation.validate(matrix,tableType);
    if(savedData.size_chart_type !== 'shoes_0' && savedData.size_chart_type !== 'kids_general_overall' && savedData.size_chart_type !== 'kids_general_bottoms' && savedData.size_chart_type !== 'kids_general_tops' && savedData.size_chart_type !== 'kids_general_new'){
        consecuutiveGrowthVlidationErrors = consecuutiveGrowthVlidation.validate(matrix,chartMeasurementSystem,tableType);
    }
    const minimumMaximumValidationErrors = minimumMaximumValidation.validate(matrix,tableType);
    const sameSizeNameValidationErrors = sameSizeNameValidation.validate(matrix,tableType)
    
    let errorList = [...rangesValidationErrors,...emptyFieldsValidationErrors,...consecuutiveGrowthVlidationErrors,...minimumMaximumValidationErrors,...sameSizeNameValidationErrors];
    return errorList;
    
}

