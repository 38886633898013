import waistc from "../../assets/images/measurement-types/waistc.svg";
import arm_length from "../../assets/images/measurement-types/arm_length.svg";
import inseam_bone from "../../assets/images/measurement-types/inseam_bone.svg";
import inseam from "../../assets/images/measurement-types/inseam.svg";
import neck_base from "../../assets/images/measurement-types/neck_base.svg";
import neckc from "../../assets/images/measurement-types/neckc.svg";
import over_bust from "../../assets/images/measurement-types/over_bust.svg";
import under_bust from "../../assets/images/measurement-types/under_bust.svg";
import waist_navel from "../../assets/images/measurement-types/waist_navel.svg";
import sleevecm from "../../assets/images/measurement-types/sleevecm.svg";
import waist_pants from "../../assets/images/measurement-types/waist_pants.svg";

const measurementTypesImages = {
  waistc: waistc,
  arm_length: arm_length,
  InseamBone: inseam_bone,
  inseam: inseam,
  neck_base: neck_base,
  neckc: neckc,
  over_bust: over_bust,
  under_bust: under_bust,
  waist_navel: waist_navel,
  sleevecm: sleevecm,
  waist_pants: waist_pants,
};

export default measurementTypesImages;
