import React from "react";
import Dropzone from "react-dropzone";
import dragAndDropIcon from "../../assets/images/drag-and-drop-icon.svg";
import "./DragAndDrop.scss";
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate'


export default function DragAndDrop({
  setUploadedFile,
  uploadedFile,
  uploading,
  maxSize,
  setIsFileValid,
  precentProgress
}) {
  let dropState = (
    <div>
      <p>
        Drop your file here, or <span>browse</span>
      </p>
      <p>Multiple Files should be Zipped</p>
      <p>Max size 100 MB</p>
    </div>
  );

  if (uploadedFile) {
    const isFileTooLarge = uploadedFile[0].size > maxSize;
    setIsFileValid(isFileTooLarge ? false : true)
      dropState = (
      <div>
        {isFileTooLarge && (<p className="file-name">Max size 100 MB. File is too large.</p>)}
        {!isFileTooLarge && (<p className="file-name">{uploadedFile[0].name}</p>)}
        <div
          className="remove-file-button"
          onClick={(event) => {
            event.stopPropagation();
            setUploadedFile(null)
            setIsFileValid(false)
          }}
        >
          Remove
        </div>
      </div>
    );
  }
  if (uploading) {
    dropState = <CircularIndeterminate precentProgress={precentProgress}/>
  }

  return (
    <Dropzone
      style={{ background: "yellow" }}
      onDrop={(acceptedFiles) => setUploadedFile(acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="drop-down">
              <div className="drop-down-content-wrapper">
                <img src={dragAndDropIcon} alt="drag and drop icon"></img>
                {dropState}
              </div>
              {/* {uploading && <label className="progress-precent">{precentProgress}%</label>} */}

            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
}
