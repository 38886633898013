import React, {useContext} from 'react'
import './PreparingYourProfilePage.scss';
import { Link } from "react-router-dom";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";

import MySizeIDLogo from "../../assets/images/mysize-icon.svg";
import registrationGirl from '../../assets/images/registration-girl.webp'

export default function PreparingYourProfilePage() {

  const [retailerProfile, setRetailerProfile] = useContext(
    RetailerProfileContext
  );

  return (
    <div className="main-page">
      <div className="main-page-upper">
        <div className="main-page-upper-left">
          <img src={MySizeIDLogo} className="main-page-upper-left-mysizeid-logo" />
          <div className="main-page-upper-left-hi-there-title">Hey there!</div>
          { retailerProfile?.pending_pricing_plan_id ? 
            <>
              <div className="main-page-upper-left-hi-there-subtitle">Your account is ready.</div>
              <Link to="/credit-card" className="button main-page-upper-left-add-payment-details-button">Add Payment Details</Link>
            </>
            :
            <div className="main-page-upper-left-hi-there-subtitle">{`Your account is being set up.
            We’ll reach out once it’s ready!`}</div>            
          }
          <i><div className="main-page-upper-left-hi-there-thank-you-subtitle">{`


Thank you,
the MySize team.`}</div></i>
        </div>
        <div className="main-page-upper-right">
          <img src={registrationGirl} className="main-page-upper-right-woman-image" />
        </div>
      </div>
    </div>
  )
}