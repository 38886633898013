import React from 'react'
import './UnisexSizeCharts.scss'
import { makeStyles, createTheme } from "@material-ui/core/styles"

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';



export default function UnisexSizeCharts({unisexSizeCharts, setUnisexSizeCharts}) {



  const customRadio = <Radio  size="medium"   />

  return (
      <div className="unisex-size-charts-content">
          <p className="unisex-size-charts-title">Unisex items will be based on:</p>
          <RadioGroup 
                  name="unisex-size-charts" 
  
                  value={unisexSizeCharts} 
                  onChange={() => setUnisexSizeCharts(unisexSizeCharts === "male" ? "female" : "male")}
          >
              <FormControlLabel   style={{width: "10px !important"}}  value="male" control={customRadio} label="Man Size Charts" />
              <FormControlLabel  value="female" control={customRadio} label="Woman Size Charts" />
          </RadioGroup>
      </div>
      // <div className="unisex-size-charts-content">
      //     Unisex items will be based on:

      //     <div className="unisex-size-charts-radio-buttons">
      //         <div className="unisex-size-charts-male-radio">
      //             <input checked={maleSizeChartsEnabled} onChange={() => setMaleSizeChartsEnabled(true)} id="male-size-charts" type="radio" name="unisex-size-charts" />
      //             <label className="unisex-size-charts-radio-label" for="male-size-charts" for="male-size-charts">Male Size Charts</label>
      //         </div>
      //         <div className="unisex-size-charts-female-radio">
      //             <input checked={!maleSizeChartsEnabled} onChange={() => setMaleSizeChartsEnabled(false)} id="female-size-charts" type="radio" name="unisex-size-charts" />
      //             <label className="unisex-size-charts-radio-label" for="female-size-charts" for="female-size-charts">Female Size Charts</label>
      //         </div>
      //     </div>
      // </div>
  )
}
