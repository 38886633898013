import "./ProductPair.scss";
import React, { useRef, useEffect, useState, useContext } from "react";
import ChartWizard from "../../components/ChartWizard/ChartWizard";
import { useLocation, Link } from "react-router-dom";
import { clickedButton } from "../../utils/page-state";
import SizeChartListWrapper from "../../components/SizeChartListWrapper/SizeChartListWrapper";
import StoreProduct from "../../containers/StoreProduct/StoreProduct";
import { SelectedSizeChartContext } from "../../context/SelectedSizeChartContext";
import AddGenericCharts from "../../components/AddGenericCharts/AddGenericCharts";
import StandartSizeChartCreator from "../../components/StandartSizeChartCreator/StandartSizeChartCreator";
import { SelectedOpenSizeChartContext } from "../../context/SelectedOpenSizeChartContext";
import JeansSizeChartCreator from '../JeansSizeChartCreator/JeansSizeChartCreator';
import BraStandartCreator from '../BraSizeChartCreator/BraSizeChartCreator';
import Tip from "../UI/Tip/Tip";
import TopBar from "../TopBar/TopBar";
import { isSizeChartChangedContext } from '../../context/isSizeChartChangedContext';
import AlertDialog from "../AlertDialog/AlertDialog";
import {RetailerProfileContext} from '../../context/RetailerProfileContext';
import {TipsContext} from '../../context/TipsContext';
import plusIcon from '../../assets/images/plus-icon.svg';





function ProductPair() {
  const [showChartWizard, setShowChartWizard] = useState(false);
  const [selectedSizeChart, setSelectedSizeChart] = useState({});
  const [openSelectedSizeChart, setOpenSelectedSizeChart] = useState({});
  const [showAddGenericCharts, setShowAddGenericCharts] = useState(false);
  const [selectedTypeCategory, setSelectedTypeCategory] = useState("");
  const [genericChartListUpdater, setGenericChartListUpdater] = useState("");
  const [isChartChanged, setIsChartChanged] = useState(false);
  const [isChartSaveDialogIsOpen, setIsChartSaveDialogIsOpen] = useState(false);


  const [ShowCreateSizeChart, setShowCreateSizeChart] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [sizeChartLen, setSizeChartLen] = useState(0);
  const [isHelpTips, setIsHelpTips] = useState(false);

  const elRefIntegrationCode = useRef()



  const [tipsRendered, setTipsRendered] = useState(false);

  const [productPairRender, setProductPairRender] = useState(false);
  const [retailerProfile, setRetailerProfile] = useContext(
    RetailerProfileContext
  );


  var isSizeChartIsOpen =
    openSelectedSizeChart &&
    Object.keys(openSelectedSizeChart).length === 0 &&
    openSelectedSizeChart.constructor === Object;

  const closeModel = () => {
    if (openSelectedSizeChart.name !== undefined && isChartChanged) {
      setIsChartSaveDialogIsOpen(true);
    }
    else {
      setOpenSelectedSizeChart({});
    }
    setShowAddGenericCharts(false);
    setShowChartWizard(false);
  };

  const location = useLocation();

  useEffect(() => {


    const renderedFrom = location.state ? location.state.from : null;

    switch (renderedFrom) {
      case clickedButton.I_HAVE_A_SIZE_CHART_ENTER:
        setShowChartWizard(true);
        break;
      case clickedButton.I_DONT_HAVE_A_SIZE_CHART:
        setShowAddGenericCharts(true);
        break;
      default:
        break;
    }
    window.history.replaceState(null, "");
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if(retailerProfile.size_chart_count === 0 && !localStorage.getItem("added_size_chart")){
  //     setTipsState( prev => ({...prev, create_size_chart: true}));
  //   }else if(retailerProfile.size_chart_count !== 0 && !localStorage.getItem("added_size_chart")){
  //     console.log("============");
  //     localStorage.setItem("added_size_chart", true);
  //     setTipsState((prevState) => ({...prevState, create_size_chart: false}))
  //   }

  // },[setTipsRendered])

  const openCreatorDependOnMeasurementType = () => {
    if (openSelectedSizeChart.size_chart_type.includes('bra_cup')) return (<BraStandartCreator
      isLockedByMysize={openSelectedSizeChart.is_locked_by_mysize}
      name={openSelectedSizeChart.name}
      gender={openSelectedSizeChart.gender}
      measurementSystem={openSelectedSizeChart.measurement_system}
      setSelectedTypeCategory={setSelectedTypeCategory}
      selectedTypeCategory={selectedTypeCategory}
      ageCategory={"Adults"}
      sizeChartType={openSelectedSizeChart.size_chart_type}
      setProductPairRender={setProductPairRender}
      approvalStatus={openSelectedSizeChart.approval_status}
      setIsChartSaveDialogIsOpen={setIsChartSaveDialogIsOpen}


    />)
    else if (openSelectedSizeChart.size_chart_type.includes('grouping')) {
      return (<JeansSizeChartCreator
        isLockedByMysize={openSelectedSizeChart.is_locked_by_mysize}
        name={openSelectedSizeChart.name}
        gender={openSelectedSizeChart.gender}
        measurementSystem={openSelectedSizeChart.measurement_system}
        setSelectedTypeCategory={setSelectedTypeCategory}
        selectedTypeCategory={selectedTypeCategory}
        ageCategory={"Adults"}
        sizeChartType={openSelectedSizeChart.size_chart_type}
        setProductPairRender={setProductPairRender}
        approvalStatus={openSelectedSizeChart.approval_status}
        setIsChartSaveDialogIsOpen={setIsChartSaveDialogIsOpen}


      />)
    }
    else {
      return (<StandartSizeChartCreator
        isLockedByMysize={openSelectedSizeChart.is_locked_by_mysize}
        name={openSelectedSizeChart.name}
        gender={openSelectedSizeChart.gender}
        measurementSystem={openSelectedSizeChart.measurement_system}
        setSelectedTypeCategory={setSelectedTypeCategory}
        selectedTypeCategory={selectedTypeCategory}
        ageCategory={openSelectedSizeChart.age_category}
        sizeChartType={openSelectedSizeChart.size_chart_type}
        setProductPairRender={setProductPairRender}
        approvalStatus={openSelectedSizeChart.approval_status}
        setIsChartSaveDialogIsOpen={setIsChartSaveDialogIsOpen}
      />)
    }
  }


  


  const resetAndShowTips = () => {
    // localStorage.removeItem("alreadyVisited");
    // const updatedTips = { ...tipsState };

    // for (let key in updatedTips) {
    //   updatedTips[key] = false;
    // }

    // if(isSourceWithOutProductsPair()){
    //   updatedTips.add_chart = true;
    // }else {
    //   updatedTips.select_chart = true;
    // }
    
    // setTipsState(updatedTips);
    // // setIsHelpTips(!isHelpTips)

  }

  const handleCloseEditor = async (isAgree) => {
    if (isAgree) {
      setOpenSelectedSizeChart({});
      setIsChartChanged(false);
    }
    setIsChartSaveDialogIsOpen(false);

  };


  const isSourceWithOutProductsPair = () =>{
      return  ['external', 'woocommerce', 'bitrix', 'plugin', 'lightspeed'].includes(retailerProfile.source)
  }

  return (
   <React.Fragment>
    {
      isSourceWithOutProductsPair() &&
      <TopBar  tipsRendered={tipsRendered} resetAndShowTips={resetAndShowTips} isHelpTips={isHelpTips}/>
    }
    
      {/* Modal */}
      <div
        onClick={() => closeModel()}
        style={{
          display:
            !isSizeChartIsOpen || showAddGenericCharts || showChartWizard
              ? "block"
              : "none",

        }}
        className="modal"
      ></div>

      <SelectedOpenSizeChartContext.Provider
        value={[openSelectedSizeChart, setOpenSelectedSizeChart]}
      >
        <div className={`product-pair-container ${isSourceWithOutProductsPair() && "product-pair-container-external"}`} >
          <SelectedSizeChartContext.Provider
            value={[selectedSizeChart, setSelectedSizeChart]}
          >
            <div className={`size-chart-wrapper ${isSourceWithOutProductsPair() && "size-chart-external"}`}>
              {/* Plan expand error */}
            {
            (isSourceWithOutProductsPair() && 
              ((retailerProfile.monthly_package_usage >= retailerProfile.plan_package_size * 0.8 && retailerProfile.monthly_package_usage < retailerProfile.plan_package_size) || (!retailerProfile.pricing_plan?.is_free && retailerProfile.pricing_plan?.is_limited && retailerProfile.monthly_package_usage >= retailerProfile.plan_package_size * 0.8 && retailerProfile.monthly_package_usage < retailerProfile.plan_package_size))) &&
              <div className="expand-warning">Looks like your free plan is not enough. <span><Link to="/plans">Choose a plan</Link></span></div> 
              }
             
              {/* {tipsArray.select_chart && <Tip tip={"select_chart"} tipsArray={tipsArray} setTipsArray={setTipsArray} isNext={true}>
                Select a chart to pair with your products.
              </Tip> } */}
              {/* {tipsArray.add_chart && <Tip tip={"add_chart"} tipsArray={tipsArray} setTipsArray={setTipsArray} isNext={sizeChartLen}>
                Add A New Chart
              </Tip>} */}

              
              <SizeChartListWrapper
                productPairRender={productPairRender}
                setProductPairRender={setProductPairRender}
                genericChartListUpdater={genericChartListUpdater}
                setGenericChartListUpdater={setGenericChartListUpdater}
                setTipsRendered={setTipsRendered}
                setShowChartWizard={setShowChartWizard}
                setShowAddGenericCharts={setShowAddGenericCharts}
                elRefIntegrationCode={elRefIntegrationCode}
                setSizeChartLen={setSizeChartLen}
              />
            </div>


            {!isSourceWithOutProductsPair() ?  
            <div  style={{ flexGrow:"1", position: "relative", paddingLeft:"0px", width: "712px", paddingBottom: "0px", overflow: "hidden"}}>
              <TopBar  tipsRendered={tipsRendered} resetAndShowTips={resetAndShowTips} isHelpTips={isHelpTips}/>  
       
             
             {/* {tipsArray.pair_chart && <Tip tip={"pair_chart"} tipsArray={tipsArray} setTipsArray={setTipsArray} isNext={true}>
                Click “Pair” to associate it with the product.
              </Tip> } */}
             

              {/* {tipsArray.preview_live && <Tip tip={"preview_live"} tipsArray={tipsArray} setTipsArray={setTipsArray}>Preview your page</Tip>} */}
              <StoreProduct />
            </div> :
            <>
              {/* {tipsArray.copy_chart && <Tip tip={"copy_chart"} tipsArray={tipsArray} setTipsArray={setTipsArray} elRef={elRefIntegrationCode.current} isNext={true}>
              Copy the size chart Code\nfor Implementation.
              </Tip>} */}

            </>
            }
              
           
           

            {showAddGenericCharts && (
              <AddGenericCharts
                setShowAddGenericCharts={setShowAddGenericCharts}
                setGenericChartListUpdater={setGenericChartListUpdater}
                setTipsRendered={setTipsRendered}
              />
            )}
          </SelectedSizeChartContext.Provider>
        </div>
        <isSizeChartChangedContext.Provider value={[isChartChanged, setIsChartChanged]} >
          {isSizeChartIsOpen ? null : openCreatorDependOnMeasurementType()}
        </isSizeChartChangedContext.Provider>
      </SelectedOpenSizeChartContext.Provider>

      {showChartWizard && (
        <ChartWizard setShowChartWizard={setShowChartWizard} setProductPairRender={setProductPairRender} productPairRender={productPairRender} setOpenSelectedSizeChart={setOpenSelectedSizeChart} />
      )}
      { isChartSaveDialogIsOpen && <AlertDialog
        setIsDialogOpen={handleCloseEditor}
        header="Unsaved changes notice"
        description="There are unsaved changes. Would you like to discard them?"
        okButton='Discard changes'
        cancelButton='Continue editing'
      />}
    </React.Fragment>
  );
}

export default ProductPair;
