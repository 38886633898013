export const productFilters =  {
    "name" : {
        "displayName": "Name",
        "haveOptionsFromBE": false,
        "options": "freeText",
        "platform": ["wix","presta", "magento"]
    },
    "manufacturer_name" : {
        "displayName": "Manufacturer name",
        "haveOptionsFromBE": false,
        "options": "fromBE",
        "platform": ["presta"]
    },
    "sku" : {
        "displayName": "SKU",
        'haveOptionsFromBE': false,
        "options": 'freeText',
        "platform": ["wix","shopify"]
    },
    "isbn" : {
        "displayName": "ISBN",
        'haveOptionsFromBE': false,
        "options": 'freeText',
        "platform": ["presta"]
    },
    "ean13" : {
        "displayName": "EAN13",
        'haveOptionsFromBE': false,
        "options": 'freeText',
        "platform": ["presta"]
    },
    "upc" : {
        "displayName": "UPC",
        'haveOptionsFromBE': false,
        "options": 'freeText',
        "platform": ["presta"]
    },
    "mpn" : {
        "displayName": "MPN",
        'haveOptionsFromBE': false,
        "options": 'freeText',
        "platform": ["presta"]
    },
    "id_category_default" : {
        "displayName": "Main category",
        "haveOptionsFromBE": false,
        "options": "fromBE",
        "platform": ["presta"]
    },
    "title" : {
        "displayName": "Title",
        "haveOptionsFromBE": false,
        "options": "freeText",
        "platform": ["shopify"]
    },
    "vendors" : {
        "displayName": "Vendor",
        "haveOptionsFromBE": true,
        "options": "fromBE",
        "platform": ["shopify"]
    },
    "product_types" : {
        "displayName": "Product type",
        "haveOptionsFromBE": true,
        "options": "fromBE",
        "platform": ["shopify"]
    },
    "tags" : {
        "displayName": "Tags",
        "haveOptionsFromBE": true,
        "options": "fromBE",
        "platform": ["shopify"]
    },
    "pair_status" : {
        "displayName": "Pairing Status",
        "haveOptionsFromBE": false,
        "options": ["Active","Draft", "Archived"],
        "platform": ["shopify"]
    },
    "status" : {
        "displayName": "Status",
        "haveOptionsFromBE": false,
        "options": ["Active","Draft", "Archived"],
        "platform": ["shopify"]
    },
    
    "collections" : {
        "displayName": "Collection",
        "haveOptionsFromBE": true,
        "options": "fromBE",
        "platform": ["wix"]
    } 
}
