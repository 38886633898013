import React,{useEffect, useContext, useState, useRef} from 'react';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import './BrandIdentity.scss';
import grayAddLogo from '../../assets/images/gray-add-logo.svg';
import editPen from '../../assets/images/edit-pen.svg';
import Dropzone from "react-dropzone";
import ApiService from '../../services/ApiService';
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import copyIcon from '../../assets/images/copy-icon.svg';
import checkIcon from '../../assets/images/check-icon.svg';



const  BrandIdentity = () => {
    const [retailerProfile, setRetailerProfile] = useContext(RetailerProfileContext);

    const [loading, setLoading] = useState(false);
    const [brandName, setBrandName] = useState(retailerProfile.brand_name);
    const [website, setWebsite] = useState(retailerProfile.website);
    const [logo, setLogo] = useState(null);
    const [editBrandName, setEditBrandName] = useState(false);
    const [editWebsite, setEditWebsite] = useState(false);
    const [showCopiedAlert, setShowCopiedAlert] = useState(false);
    const brandNameRef = useRef();
    const websiteRef = useRef();

    useEffect(() => {
        if(editBrandName){
            brandNameRef.current.focus();
        }
        else{
            brandNameRef.current.blur();
        }


        if(editWebsite){
            websiteRef.current.focus();
        }
        else{
            websiteRef.current.blur();
        }
        
    }, [editBrandName, editWebsite])

    const isSaveBtnActive = () => {
        if(brandName.trim().length === 0 || website.trim().length === 0) return false; 
        return (retailerProfile?.brand_name !== brandName.trim() || retailerProfile.website !== website.trim() || logo);
    }


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          setEditBrandName(false);
          setEditWebsite(false);
        }
      }


	const handleFileChange = (e) => {
		const file = e.target.files[0];
		generatePreviewImgUrl(file, logo => {
			setLogo( logo );
		});

	};

	const generatePreviewImgUrl = (file, callback) => {
		const reader = new FileReader();
		const url = reader.readAsDataURL(file);
		reader.onloadend = e => callback(reader.result)
	};

    const submitHandler = async () => {
        const api = new ApiService();
        setLoading(true);
        await api.updateRetailerProfile(brandName, website, logo || retailerProfile?.logo);
        const user_profile = await api.getRetailerProfile();
        const newRetailerProfile = {...retailerProfile,...user_profile, logo: logo || retailerProfile?.logo}
        setLogo(null);
        setRetailerProfile(newRetailerProfile);
        setLoading(false);

    }
   


    let userAvatarLogo = null
    if(logo){
        userAvatarLogo = <img src={logo} className="logo-preview"   alt="input logo"/> 
    }else if(retailerProfile?.logo){
        userAvatarLogo = <img src={retailerProfile?.logo} className="logo-preview"   alt="retailers logo"/> 
    }else {
        userAvatarLogo = <React.Fragment><img src={grayAddLogo} alt="add logo"/><p>Add your logo</p></React.Fragment>
    }

    const copyToClipboard = (str) => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText){
            setShowCopiedAlert(true);
            setTimeout(() => {
                setShowCopiedAlert(false);
            }, 1000);
            return navigator.clipboard.writeText(str);
        }
    }

    console.log(navigator);
    return (
        <div className="brand-identity-container">
       <div className="brand-identity-header">Your Brand’s Identity</div>
       <div className="brand-identity-body">
            <div  className="brand-identity-body__left-section">
                <div className="brand-identity-body__logo">

                <input 
                 className="brand-identity-body__file"
                 type="file"  
                //  onChange={(e) => setLogo(URL.createObjectURL( e.target.files[0]))}
                 onChange={(e) => handleFileChange( e)}
                 />
                    {userAvatarLogo }
                   
                    
                </div>
                <div className="brand-identity-body__info">
                    <div className='brand-identity-body__brand-name'>
                        <input 
                            ref={brandNameRef} 
                            style={{width: brandName.length * 12, maxWidth: "230px"}}
                            onKeyDown={handleKeyDown}
                            className="brand-identity-body__brand-name-text" 
                            onChange={e => setBrandName(e.target.value)}
                            type="text" 
                            onBlur={() =>  setEditBrandName(false)}
                            disabled={!editBrandName} 
                            value={brandName}/>
                        <img className="brand-identity-body__edit-pen"  src={editPen} onClick={() => setEditBrandName(!editBrandName)}  alt="edit pen"/>    
                    </div>
                    <div className='brand-identity-body__brand-url'>
                    <input 
                            ref={websiteRef} 
                            style={{width: website.length * 9,maxWidth: "230px"}}
                            className="brand-identity-body__website-text" 
                            onKeyDown={handleKeyDown}
                            onChange={e => setWebsite(e.target.value)}
                            type="text" 
                            onBlur={() =>  setEditWebsite(false)}
                            disabled={!editWebsite} 
                            value={website}/>
                        <img className="brand-identity-body__edit-pen" onClick={() => setEditWebsite(!editWebsite)} src={editPen} alt="edit pen"/>    
                    </div>
                    <div className="brand-identity-body__retailer-token">
                        <strong>Retailer Token: &nbsp;</strong>
                        
                        {retailerProfile?.shop_id} 
                        
                        <span>{showCopiedAlert ? <img className="check" src={checkIcon} alt="check icon"/> : <img src={copyIcon} onClick={() => copyToClipboard(retailerProfile?.shop_id)} alt="copy icon"/>}</span>
                        </div>
                </div>
            </div>
            <div className="brand-identity-body__right-section">
                <div 
                className={`brand-identity-body__save-btn ${isSaveBtnActive() ? "brand-identity-body__save-btn--active" : ""}`}
                onClick={isSaveBtnActive() ? submitHandler : null}  
                >
                    {loading ? <CircularIndeterminate color="white" size="25px"/> : "SAVE CHANGES"}

                    
                </div>
            </div>
       </div>


    </div>
    )
}


export default BrandIdentity;