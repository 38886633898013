import React from "react";
import "./SizeChartAge.scss";
import { chartWizardPageNames } from "../../../utils/page-state";

const ages = {
  ADULTS: "adults",
  KIDS: "kids",
  TODDLERS: "toddlers",
};

export default function SizeChartAge({
  setChartPageState,
  ageCategory,
  setAgeCategory,
  chartAgeCategory,
  setChartAgeCategory,
  fromEdit,
  setEditRenderer
}) {
  // SizeChartAge rendered from editor
  if(fromEdit) {  
    return (
      <div className="size-chart-age">
        <h1 className="age-title">Age Group</h1>
        <div className="buttons-wrapper">
          <div
            className={`button ${chartAgeCategory === ages.ADULTS && "active"}`}
            onClick={() => setChartAgeCategory(ages.ADULTS)}
          >
            Adults
          </div>
          <div
            className={`button ${chartAgeCategory === ages.KIDS && "active"}`}
            onClick={() => setChartAgeCategory(ages.KIDS)}
          >
            Kids
          </div>
          {/* <div
            className={`button ${chartAgeCategory === ages.TODDLERS && "active"}`}
            onClick={() => setChartAgeCategory(ages.TODDLERS)}
          >
            Toddlers
          </div> */}
        </div>
  
        <div className={`next-button ${!chartAgeCategory && "unable"}` } onClick={() => setEditRenderer(null)}>
          Ok
        </div>
      </div>
    );
  }




  const nextClickHandler = () => {
    // check for input
    if (!ageCategory) {
      return;
    }
    setChartPageState(chartWizardPageNames.CHART_WIZARD_CATEGORY);
  };


  return (
    <div className="size-chart-age">
      <h1>Age Group</h1>
      <div className="buttons-wrapper">
        <div
          className={`button ${ageCategory === ages.ADULTS && "active"}`}
          onClick={() => setAgeCategory(ages.ADULTS)}
        >
          Adults
        </div>
        <div
          className={`button ${ageCategory === ages.KIDS && "active"}`}
          onClick={() => setAgeCategory(ages.KIDS)}
        >
          Kids
        </div>
        {/* <div
          className={`button ${ageCategory === ages.TODDLERS && "active"}`}
          onClick={() => setAgeCategory(ages.TODDLERS)}
        >
          Toddlers
        </div> */}
      </div>

      <div className={`next-button ${!ageCategory && "unable"}` }onClick={nextClickHandler}>
        Next
      </div>
    </div>
  );
}
