import { useEffect, useRef, useState } from "react";
import './Modal.scss';

export const Modal = ({ msg, timer, showCloseBtn=false, showX=false, closeCB=null, bgcScreen='#333', bgcMsg='#eee', titleClr='#333', bodyClr='#333', btnBgc='lightGreen', btnText='#333', xClr='#333', borderRadius=0}) => {
    const [isOpen, setIsOpen] = useState(true);
    const totalTimer = timer ? (timer + 2500) : 360_000_000;

    const timeout = useRef();
    useEffect(() => {
        if (!timer) return
        timeout.current = setTimeout(() => {
            setIsOpen(false);
        }, totalTimer)
    },[])

    useEffect(() => {
        if (isOpen) return
        closeCB()
        clearTimeout(timeout);
    },[isOpen])

    return <div className={`tx-modal${isOpen ? " active" : ""}`} style={{'--timer': `${totalTimer}ms`, '--bgc-screen':`${bgcScreen}`, '--bgc-msg':`${bgcMsg}`, '--title-clr':`${titleClr}`, '--body-clr':`${bodyClr}`, '--btn-bgc':`${btnBgc}`, '--btn-text':`${btnText}`, '--x-clr':`${xClr}`, '--border-radius':`${borderRadius}`}} 
    onClick={()=>setIsOpen(false)}>
        {<div className="modal-content" onClick={(ev)=>{
            ev.stopPropagation()
        }}>
            <h1>{msg.title || msg}</h1>
            {msg.body && <p>{msg.body}</p>}
            {showCloseBtn && <button className="close-btn" onClick={()=>setIsOpen(false)}>{showCloseBtn}</button>}
            {showX && <button className="x-btn" onClick={()=>setIsOpen(false)}>X</button>}
        </div>}
    </div>
}