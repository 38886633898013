import { BaseService } from "./BaseService";
import { LocalStorageService } from "./LocalStorageService";

export default class ApiService extends BaseService {
  
  async disconnectSizeChartFromProduct(externalProductID) {
    let response = await this.http.remove(
      `/retailers/me/shop_products/${externalProductID}/pair`
    );
    return response;
  }

  async submmitSizeChart(sizeChartID, body) {
    let response = await this.http.post(
      `/retailers/me/size_charts/${sizeChartID}/approval_statuses`,
      body
    );
    return response;
  }
  
  async connectSizeChartFromProduct(externalProductID, body) {
    let response = await this.http.post(
      `/retailers/me/shop_products/${externalProductID}/pair`,
      body
    );
    return response;
  }

  async getAllStoreProdut(pageInfoParam) {
    let response = await this.http.get(
      `/retailers/me/shop_products${pageInfoParam}`
    );
    return response;
  }

  async getFilteredStoreProduct(pageInfoParam) {
    let response = await this.http.get(
      `/retailers/me/shop_products${pageInfoParam}`
    );
    return response;
  }

  async getFilterOptions() {
    let response = await this.http.get(
      `/retailers/me/shop_products/filter_options`
    );
    return response;
  }


  async getAllSizeCharts() {
    let response = await this.http.get("/retailers/me/size_charts");
    response = response.sort((a, b) => (a.id < b.id) ? 1 : -1);
    return response;
  }

  async deleteSelectedSizeChart(id) {
    let response = await this.http.remove(`/retailers/me/size_charts/${id}`);
    return response;
  }
  async addSizeChart(body) {
    let response = await this.http.post(`/retailers/me/size_charts`, body);
    if(!LocalStorageService.get("added_size_chart")){
      LocalStorageService.set("added_size_chart", true)

    }
    return response;
  }

  async getPresignedUrlForUploadingFile(body) {
    let response = await this.http.post(
      `/retailers/me/size_chart_file_uploads`,
      body
    );
    return response;
  }

  async updateSizeChartUploadFileStatus(body, id) {
    let response = await this.http.post(
      `/retailers/me/size_chart_file_uploads/${id}`,
      body
    );
    return response;
  }

  async getSizeChartByID(id) {
    let response = await this.http.get(`/retailers/me/size_charts/${id}`);
    return response;
  }

  async updateSizeChartByID(id, body) {
    let response = await this.http.post(
      `/retailers/me/size_charts/${id}`,
      body
    );
    return response;
  }

  addGenericSizeChartToRetailer = async (body) => {
    let response = await this.http.post(
        `/retailers/me/size_charts/templates`,
        body
      );
      if(!LocalStorageService.get("added_size_chart")){
        LocalStorageService.set("added_size_chart", true)
      }
      return response;
  };
  plansRedirection = async (body) => {
    let response = await this.http.post(
        `/retailers/me/payments`,
        body
      );
      return response;
  };


  getRetailerProfile = async () => {
    let response = await this.http.get(
        `/retailers/me`,
    );
    return response;
  };
  deleteRetailerProfile = async () => {
    let response = await this.http.remove(
        `/retailers/me`,
    );
    return response;
  };

  updateRetailerProfile = async (brand_name, website, logo ) => {
    let response = null;
    try {
      if(logo){
         response = await this.http.post(`/retailers/me`,{brand_name, website, logo});
      }else{
         response = await this.http.post(`/retailers/me`,{brand_name, website});
      }
      return response;
      
    } catch (error) {
      return error;
    }
  }


  signupRetailer = async(params) => {
    let response;
    try {
      response = await this.http.post(`/retailers`, params, null, false);
    } catch (error) {
      response = error;
    }
    return response;
  }

  getRetailerMeta = async(token) => {
    let response = await this.http.get(`/retailers/meta`);
    return response;
  }

  externalSignIn = async(email, password) => {
    const headers = {...this.http.headers};
    headers.Email = email;
    headers.Password = password;
    headers['X-Auth-Token'] = null;
    this.http.headers = headers;
    let response;
    try {
       response = await this.http.get(`/retailers/me`);
      
    } catch (error) {
      console.log(error);
      response = error;
    }
    
    return response;
  }
  postUninstallReason = async(reason, comment) => {
   
    let response = null;
    try {
       response = await this.http.post(`/retailers/me`, {uninstall_reason: reason, uninstall_reason_comment: comment});
      
    } catch (error) {
      console.log(error);
      response = error;
    }
    
    return response;
  }

  resetPassword = async (email) => {
    const response = await this.http.post("/retailers/reset_password", {email});
    return response;
  }


  postWidgetStyles = async (params) => {
    let response = null;
    try {
       response = await this.http.post(`/retailers/me/widget`, params);
       return response;
    } catch (error) {
      throw(error);
    }
    
  }


  getWidgetStyle = async (website) => {

    let response = null;
    try {
       response = await this.http.get(`/retailers/widget_meta?shop_id=${website}`);
      
    } catch (error) {
      console.log(error);
      response = error;
    }
    
    return response;
  }


  
}
