import React, { useContext, useEffect, useState } from "react";
import "./WidgetSettings.scss";
import TopBar from "../TopBar/TopBar";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import WidgetAppearanceSettings from '../WidgetAppearanceSettings/WidgetAppearanceSettings'
import WidgetPopupSettings from "../WidgetPopupSettings/WidgetPopupSettings";
import backToBasicSettingsButtonImage from '../../assets/images/arrow-left.svg'
import ApiService from "../../services/ApiService";
import successIcon from '../../assets/images/success-icon.svg';
import WidgetPlacementSettings from '../WidgetPlacementSettings/WidgetPlacementSettings'
import * as widgetSettingsType from '../../utils/widget-settings-types'
import * as AnchorTypes from '../../utils/widget-placement-settings-anchor-type'
import openAnchorDescInNewTab from '../../assets/images/open-in-new.svg'


const defaultWidget = {
    widget_color: "253340",
    widget_background: "ffffff",
    widget_border_color: "000000",
    widget_hide_gender_selection: false,
    widget_language: "en",
    widget_size: "medium",
    widget_border_radius: 0,
    widget_unisex_preselected_gender: "male",
    widget_font_family: "Roboto",
    css_link: null,
    widget_anchor_element: "",
    widget_anchor_element_type: AnchorTypes.SIBLING_BEFORE
}

export default function WidgetSettings() {
  const [retailerProfile, setRetailerProfile] = useContext(RetailerProfileContext);

  // Widget Appearance Settings
  const [fillColor, setFillColor] = useState("")
  const [textColor, setTextColor] = useState("")
  const [borderColor, setBorderColor] = useState("")
  const [showLogo, setShowLogo] = useState("")
  const [size, setSize] = useState("medium")
  const [radius, setRadius] = useState("")
  const [language, setLanguage] = useState("")
  const [settingsType, setSettingsType] = useState(widgetSettingsType.WIDGET_APPEARANCE_SETTINGS_TYPE)

  // Widget Popup Settings
  const [hideGender, setHideGender] = useState("")
  const [unisexSizeCharts, setUnisexSizeCharts] = useState("")
  const [fontName, setFontName] = useState("")
  const [cssLink, setCssLink] = useState("")

  // Widget Placement Settings
  const [anchorElementSelector, setAnchorElementSelector] = useState("")
  const [anchorElementType, setAnchorElementType] = useState("")

  const [updatedDialog, setUpdatedDialog] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  useEffect(() => {
    initializeSettings();
  }, [retailerProfile.widget])

  const initializeSettings = () => {
    setFillColor((retailerProfile?.widget?.widget_background) || defaultWidget.widget_background);
    setTextColor((retailerProfile?.widget?.widget_color) || defaultWidget.widget_color);
    setBorderColor((retailerProfile?.widget?.widget_border_color) || defaultWidget.widget_border_color);
    setHideGender(retailerProfile?.widget?.widget_hide_gender_selection || defaultWidget.widget_hide_gender_selection);
    setLanguage(retailerProfile?.widget?.widget_language || defaultWidget.widget_language);
    setShowLogo(true);
    setSize(retailerProfile?.widget?.widget_size || defaultWidget.widget_size);
    setRadius(retailerProfile?.widget?.widget_border_radius?.toString() || defaultWidget.widget_border_radius)  ;
    setUnisexSizeCharts(retailerProfile?.widget?.widget_unisex_preselected_gender || defaultWidget.widget_unisex_preselected_gender)
    setFontName(retailerProfile?.widget?.widget_font_family || defaultWidget.widget_font_family)
    setCssLink(retailerProfile?.widget?.css_link || defaultWidget.css_link )
    setAnchorElementSelector(retailerProfile?.widget?.widget_anchor_element || defaultWidget.widget_anchor_element)
    setAnchorElementType(retailerProfile?.widget?.widget_anchor_element_type || defaultWidget.widget_anchor_element_type)
  }

  const getSizeForDiscretValue = (value) => {
    switch(value) {
      case 0: return "small"
      case 50: return "medium"
      default: return "large"
    }
  }

  const fixColorText = (color) => {
    if(color && color.charAt(0) !== "#"){
      return `#${color}`
    }
    return color;
  }

  const resetToDefaultWidgetAppearanceSettings = () => {
    setFillColor(defaultWidget.widget_background)
    setTextColor(defaultWidget.widget_color)
    setBorderColor(defaultWidget.widget_border_color)
    setHideGender(defaultWidget.widget_hide_gender_selection)
    setLanguage(defaultWidget.widget_language)
    setSize(defaultWidget.widget_size)
    setRadius(defaultWidget.widget_border_radius)
  }

  const resetToDefaultWidgetPopupSettings = () => {
    setUnisexSizeCharts(defaultWidget.widget_unisex_preselected_gender)
    setFontName(defaultWidget.widget_font_family)
    setCssLink(defaultWidget.css_link)
    setHideGender(defaultWidget.widget_hide_gender_selection)
  }

  const resetToDefaultWidgetPlacementSettings = () => {
    setAnchorElementSelector(defaultWidget.widget_anchor_element)
    setAnchorElementType(defaultWidget.widget_anchor_element_type)
  }

  const onSizeChangeHandler = (size) => {
    setSize(getSizeForDiscretValue(size));
  }

  const updateRetailerProfileWidget = () => {
    const updatedWidget = {...retailerProfile.widget};
    updatedWidget.widget_background = fillColor;
    updatedWidget.widget_color = textColor;
    updatedWidget.widget_border_color = borderColor;
    updatedWidget.widget_hide_gender_selection = hideGender;
    updatedWidget.widget_language = language;
    updatedWidget.widget_size = size;
    updatedWidget.widget_border_radius = radius;
    updatedWidget.widget_unisex_preselected_gender = unisexSizeCharts
    updatedWidget.widget_font_family = fontName
    updatedWidget.css_link = cssLink
    updatedWidget.widget_anchor_element = anchorElementSelector
    updatedWidget.widget_anchor_element_type = anchorElementType

    setRetailerProfile(prevState => ({...prevState, widget: updatedWidget}));
  }

  const onSaveHandler = async() => {
    setSaveLoader(true);
    const api = new ApiService();
    const params = {
      widget_color: textColor,
      widget_background: fillColor,
      widget_size: size,
      widget_border_radius: radius,
      widget_border_color:  borderColor,
      widget_language: language,
      widget_logo_color: showLogo,
      widget_hide_gender_selection: hideGender.toString(), // stringify because of bad BE code
      widget_unisex_preselected_gender: unisexSizeCharts,
      widget_font_family: fontName.trim(),
      css_link: cssLink?.trim() === '' ? null : cssLink?.trim(),
      widget_anchor_element: anchorElementSelector,
      widget_anchor_element_type: anchorElementType
    }


    let response = null;
    try {
       response = await api.postWidgetStyles(params);
    } catch (err) {
      response = false;
    }

    setSaveLoader(false);
    if (response) {
      updateRetailerProfileWidget();
      setUpdatedDialog(true);
      setTimeout(() => {
        setUpdatedDialog(false);
      }, 2000);
    }
  }

  const isWidgetAppearanceSettingsChanged = () => {
    return (
      fillColor !== `${retailerProfile?.widget?.widget_background}` ||
      textColor !== `${retailerProfile?.widget?.widget_color }` ||
      borderColor !== `${retailerProfile?.widget?.widget_border_color}` ||
      language !== retailerProfile?.widget?.widget_language ||
      size !== retailerProfile?.widget?.widget_size ||
      radius !== retailerProfile?.widget?.widget_border_radius?.toString()
    )
  }
  
  const isWidgetPopupSettingsChanged = () => {
    return (
      hideGender !== retailerProfile?.widget?.widget_hide_gender_selection ||
      unisexSizeCharts !== retailerProfile?.widget?.widget_unisex_preselected_gender ||
      fontName !== retailerProfile?.widget?.widget_font_family ||
      cssLink !== retailerProfile?.widget?.css_link
      )
    }

  const isWidgetPlacementSettingsChanged = () => {
    return (
      (anchorElementSelector !== retailerProfile?.widget?.widget_anchor_element && (anchorElementSelector !== "" || retailerProfile?.widget?.widget_anchor_element !== null)) ||
      anchorElementType !== retailerProfile?.widget?.widget_anchor_element_type
    )
  }

  const renderSettings = (type) => {
    switch (type) {
      case widgetSettingsType.WIDGET_APPEARANCE_SETTINGS_TYPE:
        return <WidgetAppearanceSettings
                fillColor={fillColor}
                setFillColor={setFillColor}
                borderColor={borderColor}
                setBorderColor={setBorderColor}
                textColor={textColor}
                setTextColor={setTextColor}
                showLogo={showLogo}
                setShowLogo={setShowLogo}
                size={size}
                radius={radius}
                onSizeChangeHandler={onSizeChangeHandler}
                onRadiusChangeHandler={setRadius}
                language={language} 
                onLanguageChange={setLanguage}
                resetToDefaultSettings={resetToDefaultWidgetAppearanceSettings}
                setWidgetSettingsType={setSettingsType}
                onSaveHandler={onSaveHandler}
                isWidgetSettingsChanged={isWidgetAppearanceSettingsChanged}
                saveLoader={saveLoader}/>
      
      case widgetSettingsType.WIDGET_POPUP_SETTINGS_TYPE:
        return <WidgetPopupSettings
                hideGender={hideGender}
                setHideGender={setHideGender}
                unisexSizeCharts={unisexSizeCharts}
                setUnisexSizeCharts={setUnisexSizeCharts}
                fontName={fontName}
                setFontName={setFontName}
                cssLink={cssLink}
                setCssLink={setCssLink}
                resetToDefaultSettings={resetToDefaultWidgetPopupSettings}
                onSaveHandler={onSaveHandler}
                isWidgetSettingsChanged={isWidgetPopupSettingsChanged}
                saveLoader={saveLoader}/>

      case widgetSettingsType.WIDGET_PLACEMENT_SETTINGS_TYPE:
        return <WidgetPlacementSettings
                anchorElementSelector={anchorElementSelector}
                setAnchorElementSelector={setAnchorElementSelector}
                anchorElementType={anchorElementType}
                setAnchorElementType={setAnchorElementType}
                isWidgetSettingsChanged={isWidgetPlacementSettingsChanged}
                resetToDefaultSettings={resetToDefaultWidgetPlacementSettings}
                saveLoader={saveLoader}
                onSaveHandler={onSaveHandler}/>
    }
  }

  const renderSettingsTitle = (type) => {
    switch (type) {
      case widgetSettingsType.WIDGET_APPEARANCE_SETTINGS_TYPE:
        return <div className="widget-settings-header">
                <p className="widget-settings-header__title">Customize the widget’s appearance</p> 
              </div>
      
      case widgetSettingsType.WIDGET_POPUP_SETTINGS_TYPE:
        return <div className="widget-settings-header">
                <img className="widget-settings-header__back-arrow" src={backToBasicSettingsButtonImage} onClick={() => setSettingsType(widgetSettingsType.WIDGET_APPEARANCE_SETTINGS_TYPE)}/>
                <p className="widget-settings-header__title">Customize the widget’s pop-up</p>
              </div>

      case widgetSettingsType.WIDGET_PLACEMENT_SETTINGS_TYPE:
        return <div className="widget-settings-header">
                <img className="widget-settings-header__back-arrow" src={backToBasicSettingsButtonImage} onClick={() => setSettingsType(widgetSettingsType.WIDGET_APPEARANCE_SETTINGS_TYPE)}/>
                <p className="widget-settings-header__title">Customize the widget’s placement</p>
                <a href="https://privacy.mysz.io/anchor-element.pdf" target="_blank">
                  <div className="widget-settings-header-open-user-guide">
                    <img src={openAnchorDescInNewTab} />
                    <p className="widget-settings-header-open-user-guide-text">Open User Guide</p>
                  </div>
                </a>
              </div>
    }
  }


  return (
    <div className={`widget-settings ${(retailerProfile.source === "lightspeed" || retailerProfile.source === "woocommerce"|| retailerProfile.source === "bitrix") && "widget-settings__wide-topbar"}`}>
      <TopBar />

      {renderSettingsTitle(settingsType)}
      {renderSettings(settingsType)}

      {updatedDialog && <div className="widget-settings__modal">
        <div className="widget-settings__feedback">
          <img src={successIcon} alt="success icon"/>
          <p>Widget settings<br/> have been saved!</p>
        </div>
      </div>}
    </div>
  );
}
