import React, { useEffect, useRef, useState, useContext } from "react";
import "./AddGenericCharts.scss";
import closeButton from "../../assets/images/x-close-button.svg";
import clothesNameToImage from "../../utils/clothes-name-to-image";
import downArrow from "../../assets/images/down-arrow.svg";
import upArrow from "../../assets/images/up-arrow.svg";
import AddGenericChartItem from "./AddGenericChartItem/AddGenericChartItem";
import {RetailerProfileContext} from '../../context/RetailerProfileContext';



const sizeCharts = {
  women: [
    "tops",
    "bottoms",
    "jeans",
    "dresses | Overalls",
    "bras",
    "shoes(US)",
    "shoes(EU)",
    "socks",
  ],
  men: [
    "tops",
    "bottoms",
    "jeans",
    "dress Shirts",
    "overalls",
    "shoes(US)",
    "shoes(EU)",
    "socks",
  ],
  unisex: [
    "tops",
    "bottoms",
    "jeans",
    // "shoes(US)",
    // "shoes(EU)",
    "hats",
  ],
  kids: ["girls", "boys"],
};

const backendSizeChartNames = {
  tops: "top",
  bottoms: "bottom",
  "dress Shirts": "dress_shirt",
  "shoes(US)": "shoes_us",
  "shoes(EU)": "shoes_eu",
  socks: "sock",
  overalls: "overall",
  hats: "hat",
  bras: "bra",
  dresses: "dress",
  girls: "girls",
  boys: "boys",
  babies: "baby",
  jeans: 'jeans',
  "dresses | Overalls" : "dress_overall",

};

export default function AddGenericChart({
  setShowAddGenericCharts,
  setGenericChartListUpdater,
  setTipsRendered
}) {
  const [unit, setUnit] = useState("metric");
  
  const [, setWomenArray] = useState([]);
  const [, setMenArray] = useState([]);
  const [, setUnisexArray] = useState([]);
  const [, setKidsArray] = useState([]);
  const [retailerProfile] = useContext(RetailerProfileContext);

  const menRef = useRef();
  const menArrowRef = useRef();
  const womenRef = useRef();
  const womenArrowRef = useRef();
  const unisexRef = useRef();
  const unisexArrowRef = useRef();
  const kidsRef = useRef();
  const kidsArrowRef = useRef();

  useEffect(() => {
    const womenLength = sizeCharts.women.length;
    const menLength = sizeCharts.men.length;
    const unisexLength = sizeCharts.unisex.length;
    const kidsLength = sizeCharts.kids.length;
    const womenState = new Array(womenLength).fill(0);
    const menState = new Array(menLength).fill(0);
    const unisexState = new Array(unisexLength).fill(0);
    const kidsState = new Array(kidsLength).fill(0);

    setWomenArray(womenState);
    setMenArray(menState);
    setUnisexArray(unisexState);
    setKidsArray(kidsState);
  }, []);

  useEffect(() => {
  });

  const listCollapseHandler = (ref, arrowRef) => {
    if (ref.current.style.display === "block") {
      ref.current.style.display = "none";
      arrowRef.current.src = downArrow;
    } else {
      ref.current.style.display = "block";
      arrowRef.current.src = upArrow;
    }
  };


  // ////////////////////
  // Mens list creation
  // ////////////////////
  const men = sizeCharts.men.map((category, index) => {
    return <li key={`men-${index}`}>
      <AddGenericChartItem 
        img={clothesNameToImage[category]} 
        category={category} 
        title={category.charAt(0).toUpperCase() + category.slice(1)}
        template={`${backendSizeChartNames[category]}_men_${unit}`}
        setGenericChartListUpdater={setGenericChartListUpdater}
        setTipsRendered={setTipsRendered}
        />
      </li>
    }
  )
  // ////////////////////
  // Weman list creation
  // ////////////////////
  const woman = sizeCharts.women.map((category, index) => {

  
    return <li key={`woman-${index}`}>
      <AddGenericChartItem 
        img={clothesNameToImage[category]} 
        category={category} 
        title={category.charAt(0).toUpperCase() + category.slice(1)}
        template={`${backendSizeChartNames[category]}_women_${unit}`}
        setGenericChartListUpdater={setGenericChartListUpdater}
        setTipsRendered={setTipsRendered}
        />
        
      </li>
    }
  )
  // ////////////////////
  // Unisex list creation
  // ////////////////////
  const unisex = sizeCharts.unisex.map((category, index) => {

  
    return <li key={`unisex-${index}`}>
      <AddGenericChartItem 
        img={clothesNameToImage[category]} 
        category={category} 
        title={category.charAt(0).toUpperCase() + category.slice(1)}
        template={`${backendSizeChartNames[category]}_unisex_${unit}`}
        setGenericChartListUpdater={setGenericChartListUpdater}
        setTipsRendered={setTipsRendered}
        />
        
      </li>
    }
  )
  // ////////////////////
  // Kids list creation
  // ////////////////////
  const kids = sizeCharts.kids.map((category, index) => {

  
    return <li key={`kids-${index}`}>
      <AddGenericChartItem 
        img={clothesNameToImage[category]} 
        category={category} 
        title={category.charAt(0).toUpperCase() + category.slice(1)}
        template={`${backendSizeChartNames[category]}_${unit}`}
        setGenericChartListUpdater={setGenericChartListUpdater}
        setTipsRendered={setTipsRendered}
        />
        
      </li>
    }
  )








  return (
    <div style={{top: retailerProfile.source === 'presta' && "unset", transform: retailerProfile.source === 'presta' && 'translate(-50%)'}} className="add-generic-charts">
      {/* <div
        className="modal"
        onClick={() => setShowAddGenericCharts(false)}
      ></div> */}

      <div className="add-charts-box">
        <div className="top-dashboard">
          <img
            src={closeButton}
            className="close-button"
            alt="close"
            onClick={() => setShowAddGenericCharts(false)}
          ></img>


          <h1 className="item title">Add Generic Charts</h1>
          <div className="item switcher">
            <div
              className={`cm-switcher switcher-item ${
                unit === "metric" && "active"
              }`}
              onClick={() => setUnit("metric")}
            >
              cm
            </div>
            <div
              className={`in-switcher switcher-item ${
                unit === "imperial" && "active"
              }`}
              onClick={() => setUnit("imperial")}
            >
              in
            </div>
          </div>
        </div>

        <div className="list-wrapper">
          {/* Men list */}
          <div className="gender-list">
            <div className="gender-title" onClick={() => listCollapseHandler(menRef, menArrowRef)}>
              <div className="title"> Men</div>
              <img
                className="arrow"
                src={downArrow}
                ref={menArrowRef}
                alt="arrow"
              ></img>
            </div>
            <ul className="list" style={{ display: "none" }} ref={menRef}>
              {men}{" "}
            </ul>
          </div>

          {/* Women list */}
          <div className="gender-list">
            <div className="gender-title" onClick={() => listCollapseHandler(womenRef, womenArrowRef)}>
              <div className="title">Women</div>
              <img
                className="arrow"
                src={downArrow}
                ref={womenArrowRef}
                alt={'arrow'}
              ></img>
            </div>

            <ul className="list" style={{ display: "none" }} ref={womenRef}>
              {woman}
            </ul>
          </div>

          {/* Unisex list */}
          <div className="gender-list">
            <div className="gender-title" onClick={() => listCollapseHandler(unisexRef, unisexArrowRef)}>
              <div className="title"> Unisex</div>
              <img
                className="arrow"
                ref={unisexArrowRef}
                src={downArrow}
                alt={'arrow'}
              ></img>
            </div>

            <ul className="list" style={{ display: "none" }} ref={unisexRef}>
              {unisex}
            </ul>
          </div>
          {/* Kids list */}
          <div className="gender-list">
            <div className="gender-title" onClick={() => listCollapseHandler(kidsRef, kidsArrowRef)}>
              <div className="title"> Kids</div>
              <img
                className="arrow"
                ref={kidsArrowRef}
                src={downArrow}
                alt={'arrow'}
              ></img>
            </div>

            <ul className="list" style={{ display: "none" }} ref={kidsRef}>
              {kids}
            </ul>
          </div>
        </div>
      </div>
      {/* { showPopUp && <SizeChartAddedPopUp/>} */}
    </div>
  );


}
