import React, { useState, useContext, useEffect } from 'react';
import "./StoreProduct.scss";
import "./StoreProductList.scss";
import StoreProductItem from "./StoreProductItem";
import { CircularProgress } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiService from '../../services/ApiService';
import { SelectedSizeChartContext } from '../../context/SelectedSizeChartContext';
import { TipsContext } from '../../context/TipsContext';
import {ProductFilterArrContext} from '../../context/ProductFilterArrContext';
import CommandQueue from '../../utils/CommandQueue';
import ProductFilter from '../../components/ProductFilter/ProductFilter';
import PairedProductAlert from '../../components/PairedProductAlert/PairedProductAlert';
import deleteFilter from '../../assets/images/delete-filter.svg';
import ProgresBarDialog from '../../components/ProgresBarDialog/ProgresBarDialog';
import infoIcon from '../../assets/images/plan-alert-icon.svg';
import closeIcon from '../../assets/images/x-close-button.svg';
import downArrowIcon from '../../assets/images/tips-down-arrow.svg';



function StoreProductList({
  storeProductsList,
  setStoreProductsList,
  productReceiveFromBe,
  fetchShopProducts,
  nextPage,
}) {
  const [productFilterArr,setProductFilterArr] = useContext(ProductFilterArrContext);
  const [tipsState,tipsHandler] = useContext(TipsContext);

  const [productsToPair, setProductsToPair] = useState([])
  const [pairedProduct,setPairedProduct] = useState(0);
  const [selectedSizeChart] = useContext(SelectedSizeChartContext);
  const [selectAll, setSelectAll] = useState(false);
  const [expandMode, setExpandMode] = useState(false);
  const [expandedList, setExpandedList] = useState(Array(storeProductsList.length).fill(false))
  const [showPairedAlert, setShowPairedAlert] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterPos,setFilterPos] = useState({});
  const [filterToEdit, setFilterToEdit]= useState({});
  const [showProgresBar,setShowProgresBar] = useState(false);


  

  useEffect(() => {
   if((parseInt(pairedProduct)/parseInt(productsToPair.length)) * 100 === 100){
    setShowProgresBar(false);
    setSelectAll(false);
    setProductsToPair([]);
   }
   // eslint-disable-next-line
  },[pairedProduct])



  useEffect(() => {
    let len = 0;
    if(storeProductsList.length - expandedList.length < 0) {
      len = storeProductsList.length;
    }else {
      len = storeProductsList.length - expandedList.length;
    }
    const newItems = Array(len).fill(expandMode)
    const newExpandedList = [...expandedList, ...newItems ];
    setExpandedList(newExpandedList);
    // eslint-disable-next-line
  },[storeProductsList, expandMode])


  const loadingIdicator = (
    <div className="loading-indicator">
      <CircularProgress style={{ color: '#F8686E' }} />
    </div>
  );

  const updateShopProductList = (commandType, extermalProductID, selectedChart = null) => {
    let newList = [...storeProductsList];
    
    let i = 0
    let selectedItem = null
    
    
    newList.forEach((product, index) => {
      
      if (product.product_id === extermalProductID) {
        selectedItem = product
        i = index
      }
    });

    
    if (commandType === 'delete') {
      
      selectedItem.size_chart = null
    }
    else {
      selectedItem.size_chart = selectedChart
      
    }

    
    newList[i] = selectedItem;
    

    setStoreProductsList(newList);
  }

  const expandCollapseByIndexHandler = (index) => {
    let newExpandedList = [...expandedList];
    newExpandedList[index] = !newExpandedList[index];
    setExpandedList(newExpandedList);
  }

  
  
  let productList =  (
    storeProductsList.map((product, index) => {
      return (
        <StoreProductItem
          key={product.product_id}
          title={product.product_title}
          name={product.name}
          id={product.product_id}
          photo={product.product_photo}
          productUrl={product.product_url}
          sizeChart={product.size_chart}
          productsToPair={productsToPair}
          setProductsToPair={setProductsToPair}
          updateShopProductList={updateShopProductList}
          selectAll={selectAll} 
          expanded={expandedList[index]}
          properties={storeProductsList[index].properties}
          index={index}
          expandCollapseByIndexHandler={expandCollapseByIndexHandler}
        />
      );
    }))

    

    const expandCollapseItemHandler = ( mode ) => {
      let newExpandedList = [...expandedList];
      newExpandedList.forEach((expanded, index) => {
        newExpandedList[index] = mode;
      })
      setExpandedList(newExpandedList);
      setExpandMode(mode);
    }

    

  const infinitScrollProductList = (
    <div id="scrollableDiv" className="infinit-scroll-wrapper">
      <InfiniteScroll
        dataLength={productList.length} //This is important field to render the next data
        next={() => {
          // await setTimeout(async () => {await fetchShopProducts("next", nextPage)}, 1000)
          fetchShopProducts("next", nextPage);
        }}
        hasMore={nextPage ? true : false}
        // height={"calc(70vh + 94px)"}
        loader={loadingIdicator}
        scrollableTarget="scrollableDiv"
        >
        {productList}
      </InfiniteScroll> 
    </div>)

  const handleClick = (event) => {
      if (event.target.checked){
       
       
        setProductsToPair(storeProductsList.map(prod => {  
          return {
            productId: prod.product_id, 
            productName: prod.product_title, 
            productUrl: prod.product_url,
            sizeChartName: prod.size_chart?.name,
            sizeChartId: prod.size_chart?.id,
          }
        
        }));
        setSelectAll(true);
      }
      else{
        setSelectAll(false);
        setProductsToPair([]);
      }
    }

  const pairSelectedProducts = async () => {
    tipsState.pair_size_chart && tipsHandler("pair_size_chart")
    
    setPairedProduct(0);
    // Check if size chart selected before starting pairing
    if(!selectedSizeChart.id){
      return;
    }

    

    let queue = new CommandQueue();
    


    let havePairedProducts = false;

    productsToPair.forEach(prod => {
      if(prod.sizeChartId){
        havePairedProducts = true;
      }
    })

    

    if(havePairedProducts){
      // alert();
      setShowPairedAlert(true);
      return;
    }
  
    setShowProgresBar(true);
    await productsToPair.forEach((product, i) => {
      
      let func = () => {
        setShowProgresBar(true);
        new ApiService().connectSizeChartFromProduct(product.productId, { size_chart_id: selectedSizeChart.id, product_url: product.productUrl  });
        setPairedProduct(i + 1)
        const newProductToPair = [...productsToPair];
        newProductToPair[i].sizeChartId = selectedSizeChart.id ;
        newProductToPair[i].sizeChartName = selectedSizeChart.name ;
        setProductsToPair(newProductToPair);

        updateShopProductList('add', product.productId, selectedSizeChart);
      }
      queue.enqueue(func);
      
    });
    queue.executeAll();
  }

  const renderFilterOptions = () =>{
    
    return(
    <div className='product-filter-items-wrapper'>
      <div style={{margin: '0 7px 4px 0'}} onClick={(event) =>{setFilterPosition(event)}} className='filter-img-section'></div>
      {productFilterArr.map((filterItem,index) =>{
          return <div onClick={() => {isFilterOpen ? setIsFilterOpen(false) : filterToEdit.filterId ? setFilterToEdit({}) : setFilterToEdit(filterItem)}} key={6456464 + index} className='filter-item-list'><img onClick={(event) =>{deleteFromFilter(event,filterItem.filterId) }} className='delete-filter-img' src={deleteFilter} alt='delete filter' />{filterItem.displayName} {filterItem.values.map((op,opIndex) => `${op} ${opIndex + 1 !== filterItem.values.length ? `${filterItem.searchType === 'OR' ? '/ ' : '+ ' }` : '' }`)}</div>
      })}
      
    </div>)
  }

  

  const deleteFromFilter = (event,id) => {
    event.stopPropagation();
    let tempArr = [...productFilterArr];
    setProductFilterArr(tempArr.filter(op => op.filterId !== id));
    setFilterToEdit({});
    setIsFilterOpen(false);
  }

  const setFilterPosition = (event) =>{
    
    event.stopPropagation();
    if(filterToEdit.filterId){
      setFilterToEdit({});  
      return;
    }
    
    let element = document.querySelector('.filter-img-section');
    var rect = element.getBoundingClientRect();
    setFilterPos({left: rect.left, top: rect.top })
    setIsFilterOpen(!isFilterOpen);
    
  }


  return (
    // <div className="store-product-list">
    <React.Fragment>
        {(isFilterOpen || filterToEdit.filterId) && <div onClick={() => {setIsFilterOpen(false); setFilterToEdit({})}} className='modal-product' ></div>}
        {productFilterArr.length > 0 && renderFilterOptions()}
        {showPairedAlert && <PairedProductAlert 
          setShowProgresBar={setShowProgresBar}
          setPairedProduct={setPairedProduct}
          renderedFrom={"StoreProductList"} 
          setShowPairedAlert={setShowPairedAlert} 
          productsToPair={productsToPair} 
          setProductsToPair={setProductsToPair} 
          selectedSizeChart={selectedSizeChart} 
          updateShopProductList={updateShopProductList}
        />
        }
        {productReceiveFromBe === false ? (loadingIdicator) :
        
        <ProductFilterArrContext.Provider value={[productFilterArr,setProductFilterArr]}>
        <div className="product-list-header-wrapper" >
          <div className="product-list-header-right" >
            <span className="expand-collapse expand" onClick={() => expandCollapseItemHandler(true)}>Expand all</span>&nbsp;
            <span>|</span>&nbsp;
            <span className="expand-collapse collapse" onClick={() => expandCollapseItemHandler(false)}>Collapse all</span>
          </div>
          <div className="product-list-header-left">
            <label className="control control-checkbox">
                        <input checked={selectAll} type='checkbox' onChange={(event) => handleClick(event)} />
                        <div className="control_indicator" style={{top: '-3px',left: '9px'}}></div>
                    </label> 
            { selectedSizeChart.id && productsToPair.length > 0 && <div className="pair-selected" onClick={() => { pairSelectedProducts() }}>Pair Selected</div>}
            {productFilterArr.length === 0 && <div onClick={(event) =>{setFilterPosition(event)}} className='filter-img-section'></div>}
            {(isFilterOpen || filterToEdit.filterId) && <ProductFilter setIsFilterOpen={setIsFilterOpen} filterPos={filterPos} filterToEdit={filterToEdit} setFilterToEdit={setFilterToEdit} />}
          </div>

        </div>
        {tipsState.pair_size_chart &&  
          
          <div className="products-tip">
            <img className="products-tip__close" src={closeIcon} onClick={() => tipsHandler("pair_size_chart")}/>
            <img className="products-tip__info" src={infoIcon}/>
            <p className="products-tip__text">Now that a size chart is selected, “Pair” all the relevant products</p>
            <img className="products-tip__down-arrow" src={downArrowIcon}/>

          </div>
        
        }
        {storeProductsList.length === 0 && productReceiveFromBe ? <p className='no-product-p' style={{ textAlign: 'center' }}>You have no products</p> : ''}
        
        
        {infinitScrollProductList}

        
        </ProductFilterArrContext.Provider> }
        { showProgresBar && <ProgresBarDialog header='Processing...' description='This may take a few minutes' completed={(parseInt(pairedProduct)/parseInt(productsToPair.length)) * 100} ></ProgresBarDialog>}
        
      </React.Fragment>
      // </div>
  );
}

export default StoreProductList;
