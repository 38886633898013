import React from "react";
import "./WidgetLanguage.scss";
import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    select: {
        borderRadius: "100px",
        width: "172px",
        height: "33px",
        color: "#898989",
        outlineStyle: "none"
    }
  });
  
export default function WidgetLanguage({ onLanguageChange, language }) {
    const classes = useStyles();
  return (
    <div className="widget-language">
        <label className="widget-language__label" htmlFor="demo-simple-select">Language</label>
        
      <Select
        className={classes.select}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={language}
        onChange={(e) => onLanguageChange(e.target.value)}
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"he"}>Hebrew</MenuItem>
        <MenuItem value={"ru"}>Russian</MenuItem>
        <MenuItem value={"it"}>Italian</MenuItem>
        <MenuItem value={"es"}>Spanish</MenuItem>
        <MenuItem value={"fr"}>French</MenuItem>
        <MenuItem value={"de"}>German</MenuItem>
        <MenuItem value={"nl"}>Dutch</MenuItem>
        <MenuItem value={"tr"}>Turkish</MenuItem>
        <MenuItem value={"po"}>Portuguese</MenuItem>
        <MenuItem value={"pl"}>Polish</MenuItem>
      </Select>
    </div>
  );
}
