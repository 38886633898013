import React from "react";
import "./WidgetPopupPreview.scss";
import widgetLogo from "../../assets/images/widget-logo.svg";
import closeIcon from "../../assets/images/x-close-button.svg";
import facebookIcon from '../../assets/images/facebook-icon.svg';
import appleIcon from '../../assets/images/apple-icon.svg';
import googleIcon from '../../assets/images/google-icon.svg';
import emailIcon from '../../assets/images/email-icon.svg';
import resetIcon from "../../assets/images/reset_to_default_buttton_image.svg";

export default function AdvancedWidgetPreview({hideGender, resetToDefaultSettings, unisexSizeCharts}) {
  return (
    <div className="advanced-widget-preview-frame">
      <div className="advanced-widget-preview">
        <div className="widget">
          <div className="widget__header">
            <img className="widget__logo" src={widgetLogo} alt="logo" />
            <div className="widget__title">
              Create a profile to get your size, quick & easy
            </div>
            <img className="widget__close" src={closeIcon} alt="close " />
          </div>
          <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
          {!hideGender && <div className="widget__gender">
              <div className={`widget__gender-item${unisexSizeCharts==='female'?' widget__gender-item--active':''}`}>
                Woman
                <div className="widget__gender-header">Gender</div>
              </div>
              <div className={`widget__gender-item${unisexSizeCharts==='male'?' widget__gender-item--active':''}`}>
                Man
              </div>
            </div>}
            <div className="widget__body">
              <div className="widget__body-item">
                <div className="widget__body-item-title">Height</div>
                <div className="widget__body-item-number">180</div>
                <div className="widget__body-item-system">
                  <span>cm</span> in
                </div>
              </div>
              <div className="widget__body-item">
                <div className="widget__body-item-title">Weight</div>
                <div className="widget__body-item-number">75</div>
                <div className="widget__body-item-system">
                  <span>kg</span> lb
                </div>
              </div>
            </div>
            <div className="widget__show-size">SHOW MW MYSIZE</div>
            <div className="widget__have">Alredy have an account? Sign in</div>
            <div className="widget__social">
              <div className="widget__social-item"><img src={appleIcon} alt="apple icon"/></div>
              <div className="widget__social-item"><img src={facebookIcon} alt="facebook icon"/></div>
              <div className="widget__social-item"><img src={googleIcon} alt="google icon"/></div>
              <div className="widget__social-item"><img src={emailIcon} alt="email icon"/></div>
            </div>
            <div className="widget__footer">By processing, you agree to MySizeID's <span>TOS</span> and <span>Privacy Policy</span></div>
          </div>
        </div>
      </div>
      <div className="advanced-widget-preview__reset-button" onClick={() => resetToDefaultSettings()}>
        <img src={resetIcon} alt="reset icon" />
        <p>Reset to default</p>
      </div>
    </div>
  );
}
