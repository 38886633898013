import React from 'react'
import Color from "../Color/Color.js"

export default function ColorPalette({fillColor, setFillColor, borderColor, setBorderColor, textColor, setTextColor}) {
    return (
        <div className="color-palette-wrapper">
            <Color text="Fill Color" color={`#${fillColor}`} setColor={setFillColor}/>
            <Color text="Text Color" color={`#${textColor}`} setColor={setTextColor}/>
            <Color text="Border Color" color={`#${borderColor}`} setColor={setBorderColor}/>
        </div>
    )
}