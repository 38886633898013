import React, { useEffect, useRef } from "react";
import ProductPair from "../../components/ProductPair/ProductPair";
import { Modal } from "../../components/UI/Modal/Modal";
import iconCheckMarkGreen from '../../assets/images/Icon-check-circle.svg';
import "./Main.scss";




export default function Main({ txErrorRef = null }) {
  return (
    // <div style={{ width: "1039px", margin: "auto", paddingTop: "10px", height: "90vh", maxHeight: "90vh" }}>
    <>
      <div style={{ width: "100%", margin: "auto", height: "calc(100vh - 60px - 10vh)", maxHeight: "90vh" }}>
        <ProductPair />
      </div>
      {txErrorRef.current.type==='ok' && <Modal 
      msg={
        {
          title:<img src={iconCheckMarkGreen} alt="Green check mark"/>, 
          body: "Your changes have been\nsuccessfully saved!"
        }
      } 
      timer={3000} 
      showCloseBtn={false} 
      showX={false} 
      closeCB={()=>{txErrorRef.current={type:'',error:''}}} 
      bgcScreen='rgba(255,255,255,0.7)' 
      bgcMsg='#fff' 
      bodyClr='#1f1b45' />}
    </>
  );
}
