const PREFIX = 'MYSZ_TMP_';

const checkLocalStoragePlan = (activePlanId) => {
    const mysizePlan = get('MYSIZE_plan')
    if (mysizePlan) {
        const { createdAt, currentPlanId, targetPlanId } = mysizePlan
        if ((new Date() >= _addMinutes(new Date(createdAt), 30)) || currentPlanId === activePlanId) {
            remove('MYSIZE_plan')
        } else {
            remove('MYSIZE_plan')
            const urlParams = new URLSearchParams(window.location.search);
            const params = urlParams.get('transaction_type')
            if (!params) {
                // window.history.replaceState(null, null, `${window.location.origin}?transaction_status=${currentPlanId === targetPlanId ? 'failed' : 'ok'}`)
                // return
                urlParams.append('transaction_type', activePlanId === targetPlanId ? 'ok' : 'cancel');
                window.location.href = `${window.location.origin}?${urlParams.toString()}`;
            } else window.history.replaceState(null, null, window.location.pathname)
        }
    }

}

const _addMinutes = (dateObj, minutes) => {
    dateObj.setMinutes(dateObj.getMinutes() + minutes);
    return dateObj
}

const get = (key, forceFromLocalstorage = false) => {
    var res;
    try {
        const value = window.localStorage.getItem(`${PREFIX}${key}`) || null
        res = typeof value === 'object' ? JSON.parse(value) : value
    } catch (e) {
        if (!forceFromLocalstorage) {
            res = window[`${PREFIX}${key}`]
        }
    } finally {
        return res
    }
}

const set = (key, value, forceToLocalstorageOnly = false) => {
    var res;
    try {
        window.localStorage.setItem(`${PREFIX}${key}`, typeof value === 'object' ? JSON.stringify(value) : value);
        res = 0
    } catch (e) {
        if (!forceToLocalstorageOnly) window[`${PREFIX}${key}`] = value
        res = -1
    } finally {
        return res;
    }
}

const clear = () => {
    var res;
    try {
        window.localStorage.clear()
        res = 0
    } catch (e) {
        res = -1
    } finally {
        return res;
    }
}

const remove = (key, forceFromLocalstorage = false) => {
    var res;
    try {
        window.localStorage.removeItem(`${PREFIX}${key}`)
        res = 0
    } catch (e) {
        if (!forceFromLocalstorage) window[`${PREFIX}${key}`] = null;
        res = -1
    } finally {
        return res;
    }
}

export const LocalStorageService = {
    checkLocalStoragePlan,
    get, set, clear, remove
}