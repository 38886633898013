import React from "react";
import "./ThankYou.scss";
import upDottedBorder from "../../assets/images/up-dotted-border.svg";
import bottomDottedBorder from "../../assets/images/bottom-dotted-border.svg";
import checkIcon from "../../assets/images/check-icon.svg";
import { Link } from "react-router-dom";
import { page_location } from "../../utils/page-state";


export default function ThankYou({setCreatorState}) {
  return (
    <div className="thank-you-wrapper">
      <div className="thank-you">
        <img className="up-dotted-border" src={upDottedBorder} alt="up left dotted border" />
        <div className="content">
          <img src={checkIcon} alt="check icon" />
          <h1>Thank You!</h1>
          <p>
            Our team will review your size charts and contact<br/>you within 3 days via the email
            you provided.
          </p>

          <Link onClick={setCreatorState ? () =>  setCreatorState(false) : null}
            to={{
              pathname: "/main",
              state: { from: page_location.THANK_YOU_PAGE },
            }}
          >
            <div className="done-btn">Done</div>
          </Link>
        </div>
        <img className="bottom-dotted-border" src={bottomDottedBorder} alt="bottom right dotted border" />
      </div>
    </div>
  );
}
