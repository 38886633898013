import React from "react";
import "./SizeMatrix.scss";
import check from "../../../assets/images/check.png";
import unCheck from "../../../assets/images/no-check.png";

export default function SizeMatrix({
  show,
  widthSizesNames,
  lengthSizesNames,
  sizeChartMatrix,
  setSizeChartMatrix,
  vInMatrixChecked
}) {    
  
  const truncateString = (text) => {
    if(text.length > 10){  
      var myTruncatedString = text.substring(0,7) + "...";
      return myTruncatedString
    }
    return text;
  }
  const onCheckHandler = (i, j) => {
    const updatedMatrix = [...sizeChartMatrix];
    updatedMatrix[i][j] = !updatedMatrix[i][j];
    setSizeChartMatrix(updatedMatrix);
    vInMatrixChecked(i,j,updatedMatrix[i][j]);
  };
  const widthRow = (
    <ul className="width-row">
      {widthSizesNames.map((width) => {
        return width==='' ? '' : <li className="width-row-li">{width && truncateString(width)}</li>;
      })}
    </ul>
  );
  const heigthColumn = (
    <ul className="heigth-column">
      {lengthSizesNames.map((heigth) => {
        return heigth==='' ? '' : <li className="heigth-column-li">{heigth && truncateString(heigth)}</li>;
      })}
    </ul>
  );

  const countGoodWidthSizes=()=>{
    let ret = 0;
    for(let i=0;i<widthSizesNames.length;i++)
      if(widthSizesNames[i] !== '')
        ret++;
    return ret;
  }
  const matrixDisplay = (
    <div
      className="matrix-grid"
      style={{ gridTemplateColumns: `repeat(${countGoodWidthSizes()},70px)` }}
    >
      {sizeChartMatrix.map((cols, i) =>
        cols.map((rows, j) => (
          ((widthSizesNames[j] === '' || lengthSizesNames[i] === '')) ? '': (
          <div
            className={`checker ${!rows && "disabled"}`}
            onClick={() => onCheckHandler(i, j)}
          >
            {rows ? (
              <img src={check} className="check-icon" alt="check"></img>
            ) : (
              <img src={unCheck} className="check-icon" alt="uncheck"></img>
            )}
          </div>
           ) 
        ))
      )}
    </div>
  );

  return (
    show && (
      <div className={"size-matrix"}>
        <div className="w-l w">W</div>
        <div className="slant-line"/>
        <div className="w-l l">L</div>
        <div className="width-title">{widthRow}</div>

        {heigthColumn}
        {matrixDisplay}
      </div>
    )
  );
}
