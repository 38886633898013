import React, { useEffect, useState } from "react";
import info from "../types.json";
import "./SelectMeasurementTypes.scss";
import backArrow from "../../../assets/images/back-arrow.svg";
import { chartWizardPageNames } from "../../../utils/page-state";
import measurementTypeImages from "../measurement-types-images";


const passiveMeasurementTypes = {
  tops: "chestc",
  dresses: "chestc",
  dress_shirt: "chestc",
  bras: "chestc",
  overalls: "chestc",
  bottoms: "hipc",
};

export default function SelectMeasurementTypes({
  setChartPageState,
  sizeChartType,
  sizingType,
  measurementTypes,
  setMeasurementTypes,
}) {
  const [index, setIndex] = useState(0);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    //  for hats & socks catch
    if (sizingType === "") {
      setMeasurementTypes([info.requiredTypes[sizeChartType]["passiveTypes"]]);
      setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
    }
    if(sizeChartType === "bras" && sizingType === "alphaNumeric"){
      setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
    }
    // for bras => alphaNumeric catch
    // else if (info.requiredTypes[sizeChartType][sizingType].length === 0) {
    //   setMeasurementTypes([info.requiredTypes[sizeChartType]["passiveTypes"]]);
    //   setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
    // }

  });

  const nextClickHandler = () => {

    // users last next click & add passive measurement types
    if (index + 1 === info.requiredTypes[sizeChartType][sizingType].length) {
      addPassiveMeasurementTypes();
      setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
      // setCreatingSizeChart(true)
    }
    //   check if meas type was chosen before next clicking
    if (!measurementTypes[index]) {
      return;
    }
    if (info.requiredTypes[sizeChartType][sizingType].length + 1 > index) {
      setIndex((prev) => prev + 1);
    }
    setFlag(false);
  };

  // on type click handler
  const chooseTypeHandler = (type) => {
    // check if type already chosen
    if (!measurementTypes[index]) {
      setMeasurementTypes((prev) => [...measurementTypes, type]);
    }
    // if type already chosen => change type to new type
    else if (measurementTypes[index] !== type) {
      let newChoseType = [...measurementTypes];
      newChoseType[index] = type;
      setMeasurementTypes(newChoseType);
    }
  };

  const goBackHandler = () => {
    if (index === 0) {
      // if user go back on first meas type
      setChartPageState(chartWizardPageNames.CHART_WIZARD_SELECT_SIZING_TYPE);
    } else {
      setIndex((prev) => prev - 1);
    }
  };

  // helper function for adding passive measurement
  // types to measurementTypes prop on last
  // 'Next' click
  const addPassiveMeasurementTypes = () => {
    const passiveType = passiveMeasurementTypes[sizeChartType];
    setMeasurementTypes((prev) => [...prev, passiveType]);
  };

  const arrayOfTypes = info.requiredTypes[sizeChartType][sizingType];

  // check for next type existence of array
  //   and prevent crushing for hats/bras/socks
  if (sizingType === "" || !arrayOfTypes[index]) {
    return null;
  }

  let measurementTitle = null;
  const typesForChoose = arrayOfTypes[index].map((type,indexg) => {
    const typeName = info["measurement_types"];

    for (let i = 0; i < typeName.length; i++) {

      
      if (typeName[i].name === type) {
          if(indexg === 0 && flag === false){
            let newChoseType = [...measurementTypes];
            newChoseType[index] = type;
            setMeasurementTypes(newChoseType);
            setFlag(true);
          }
        const image = measurementTypeImages[typeName[i].name];
        const description = typeName[i]["tooltip_text"];
        measurementTitle = typeName[i]["category"];

        return (
          <li className="measure-type" key={type}>
            <img
              className={measurementTypes.includes(type) ? "active" : null}
              onClick={() => chooseTypeHandler(type)}
              src={image}
              alt={measurementTitle}
            ></img>
            <p>{description}</p>
          </li>
        );
      }
    }
    return null;
  });


  return (
    <div className="select-measurement-types">
      <img
        src={backArrow}
        className="back-arrow"
        onClick={goBackHandler}
        alt="go back arrow"
      ></img>
      <h1>Select {measurementTitle} Measure Type</h1>
      <ul className="measurement-types-wrapper">{typesForChoose}</ul>
      <div
        onClick={nextClickHandler}
        className={`next-button ${!measurementTypes[index] && "unable"}`}
      >
        Next
      </div>
    </div>
  );
}
