import React, { useState } from 'react'
import "./Color.scss"
import { SketchPicker } from 'react-color'

export default function Color({text, color, setColor}) {

    const [colorPickerShown, setColorPickerShown] = useState(false)

    return (
        <div className="color-wrapper">
            <p className="color-text">{text}</p> 
            <div className="color-input" onClick={() => setColorPickerShown(!colorPickerShown)} style={{backgroundColor: color}}></div> 
            {colorPickerShown && 
                <SketchPicker 
                        className="color-picker" 
                        color={`#${color}`}
                        disableAlpha={true}
                        onChange={e => setColor(e?.hex.substring(1))} /> }
            <div className="color-desc" onClick={() => setColorPickerShown(!colorPickerShown)}>{color}</div>
            {colorPickerShown && <div className="color-modal" onClick={() => setColorPickerShown(false)}></div>}
        </div>
    )
}