import React from "react";
import "./SizeChartCategory.scss";
import { chartWizardPageNames } from "../../../utils/page-state";

import { ReactComponent as Dress } from "../../../assets/images/dress.svg";
import { ReactComponent as Socks } from "../../../assets/images/socks.svg";
import { ReactComponent as Shirt } from "../../../assets/images/shirt.svg";
import { ReactComponent as Overall } from "../../../assets/images/overall.svg";
import { ReactComponent as Jeans } from "../../../assets/images/jeans.svg";
import { ReactComponent as DressShirt } from "../../../assets/images/dress-shirt.svg";
import { ReactComponent as Bra } from "../../../assets/images/bra.svg";
import { ReactComponent as Hat } from "../../../assets/images/hat.svg";
import { ReactComponent as Shoe } from "../../../assets/images/clothes-categories/shoes.svg";
import { ReactComponent as Onesie } from "../../../assets/images/clothes-categories/onesie-small.svg";


const types = {
  TOPS: "tops",
  BOTTOMS: "bottoms",
  DRESSES: "dresses",
  DRESSSHIRT: "dress_shirt",
  OVERALLS: "overalls",
  BRAS: "bras",
  SOCKS: "socks",
  HATS: "hats",
  SHOES:"shoes",
  DRESSES_ONESIES: "dresses_onesies",

};

const unActiveCategories = {
  male: ["bras", "dresses"],
  female: [],
  unisex: [],
};

const ageCategoriesWithNoSizingtypes = ["kids"];


const categoryWithNoAdvanced = ["hats", "shoes", "socks", null];
const ageWithNoAdvanced = ["kids"];


export default function SizeChartCategory({
  setChartPageState,
  sizeChartType,
  setSizeChartType,
  gender,
  ageCategory,
}) {

  
  const categodyClickHandler = (category) => {
    // check on click category relevance to gender
    if (!unActiveCategories[gender].includes(category)) {
      setSizeChartType(category);
    }
  };

  const nextClickHandler = () => {
    // check for category choose existence before NEXT click
    if (!sizeChartType) {
      return;
    }

    // check for skipping SelectSizingType component be rendered
    if(ageCategoriesWithNoSizingtypes.includes(ageCategory)) {
      setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
      return;
    }

    setChartPageState(chartWizardPageNames.CHART_WIZARD_SELECT_SIZING_TYPE);
  };

  let categoriesShown = (
    <React.Fragment>
      <div
        className={`button ${sizeChartType === types.TOPS && "active"} ${
          unActiveCategories[gender].includes(types.TOPS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.TOPS)}
      >
        {" "}
        <Shirt
          className={`category-icon ${
            sizeChartType === types.TOPS && "active-icon"
          }`}
        />
        Tops
      </div>

      <div
        className={`button ${sizeChartType === types.BOTTOMS && "active"} ${
          unActiveCategories[gender].includes(types.BOTTOMS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.BOTTOMS)}
      >
        <Jeans
          className={`category-icon ${
            sizeChartType === types.BOTTOMS && "active-icon "
          }`}
        />{" "}
        Bottoms
      </div>

      <div
        className={`button ${sizeChartType === types.DRESSES && "active"} ${
          unActiveCategories[gender].includes(types.DRESSES) && "unactive unactive-icon"
        }`}
        onClick={() => categodyClickHandler(types.DRESSES)}
      >
        <Dress
          className={`category-icon ${
            sizeChartType === types.DRESSES && "active-icon "
          }${unActiveCategories[gender].includes(types.DRESSES) && "unactive unactive-icon"}
          `}
        />
        Dresses
      </div>


      <div
        className={`button ${sizeChartType === types.DRESSSHIRT && "active"} ${
          unActiveCategories[gender].includes(types.DRESSSHIRT) && "unactive "
        }`}
        onClick={() => categodyClickHandler(types.DRESSSHIRT)}
      >
        <DressShirt
          className={`category-icon ${
            sizeChartType === types.DRESSSHIRT && "active-icon"
          }`}
        />
        Dress Shirt
      </div>


      <div
        className={`button ${sizeChartType === types.SHOES && "active"} ${
          unActiveCategories[gender].includes(types.SHOES) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.SHOES)}
      >
        <Shoe
          className={`category-icon ${
            sizeChartType === types.SHOES && "active-icon"
          }`}
        />
        Shoes
      </div>

        

      <div
        className={`button ${sizeChartType === types.OVERALLS && "active"} ${
          unActiveCategories[gender].includes(types.OVERALLS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.OVERALLS)}
      >
        <Overall
          className={`category-icon ${
            sizeChartType === types.OVERALLS && "active-icon"
          }`}
        />
        Overalls
      </div>
      <div
        className={`button ${sizeChartType === types.BRAS && "active"} ${
          unActiveCategories[gender].includes(types.BRAS) && "unactive unactive-icon"
        }`}
        onClick={() => categodyClickHandler(types.BRAS)}
      >
        <Bra
          className={`category-icon ${
            sizeChartType === types.BRAS && "active-icon "
          }
          ${unActiveCategories[gender].includes(types.BRAS) && "unactive-icon"}
          `}
        />{" "}
        Bras
      </div>
      <div
        className={`button ${sizeChartType === types.SOCKS && "active"} ${
          unActiveCategories[gender].includes(types.SOCKS) && "unactive "
        }`}
        onClick={() => categodyClickHandler(types.SOCKS)}
      >
        <Socks
          className={`category-icon ${
            sizeChartType === types.SOCKS && "active-icon"
          }`}
        />
        Socks
      </div>
      <div
        className={`button ${sizeChartType === types.HATS && "active"} ${
          unActiveCategories[gender].includes(types.HATS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.HATS)}
      >
        <Hat
          className={`category-icon ${
            sizeChartType === types.HATS && "active-icon"
          }`}
        />
        Hats
      </div>
    </React.Fragment>
  );

  // ////////////////
  // Kids  catedories
  // ////////////////
  if (ageCategoriesWithNoSizingtypes.includes(ageCategory)) {
  
    categoriesShown = <React.Fragment>
      <div
        className={`button ${sizeChartType === types.TOPS && "active"} ${
          unActiveCategories[gender].includes(types.TOPS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.TOPS)}
      >
        {" "}
        <Shirt
          className={`category-icon ${
            sizeChartType === types.TOPS && "active-icon"
          }`}
        />
        Tops
      </div>
      <div
        className={`button ${sizeChartType === types.BOTTOMS && "active"} ${
          unActiveCategories[gender].includes(types.BOTTOMS) && "unactive"
        }`}
        onClick={() => categodyClickHandler(types.BOTTOMS)}
      >
        <Jeans
          className={`category-icon ${
            sizeChartType === types.BOTTOMS && "active-icon"
          }`}
        />{" "}
        Bottoms
      </div>

      { gender === "female" ?
                <div
                className={`button ${
                  sizeChartType === types.DRESSES && "active"
                } ${
                  unActiveCategories[gender].includes(types.DRESSES) &&
                  "unactive"
                }`}
                onClick={() => categodyClickHandler(types.DRESSES)}
              > 
                <Dress
                  className={`category-icon ${
                    sizeChartType === types.DRESSES && "active-icon"
                  }${
                    unActiveCategories[gender].includes(types.DRESSES) &&
                    "unactive"
                  }`}
                />
                <div style={{width: "min-content"}}>Dresses/ Overalls</div>
              </div>

              :
              
              <div
              className={`button ${sizeChartType === types.OVERALLS && "active"} ${
                unActiveCategories[gender].includes(types.OVERALLS) && "unactive"
              }`}
              onClick={() => categodyClickHandler(types.OVERALLS)}
            >
              <Onesie
                className={`category-icon ${
                  sizeChartType === types.OVERALL && "active-icon"
                }`}
              />
              Overalls
            </div>
      }
    </React.Fragment>;
  }

  return (
    <div className="size-chart-category">
      <h1>Select Chart Category</h1>
      <div className={`buttons-wrapper ${ageCategory === "kids" || ageCategory === "toddlers" ? "three-buttons-wrapper" : null}`}>{categoriesShown}</div>
      <div
        className={`next-button ${!sizeChartType && "unable"}`}
        onClick={() => setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE)}
      >
        Done
      </div>
      {(!categoryWithNoAdvanced.includes(sizeChartType) && !ageWithNoAdvanced.includes(ageCategory)) && 
      <div style={{display: 'none'}} className="advanced-sizeing-settings" onClick={nextClickHandler}>Advanced sizing settings</div>
      }
 
    </div>
  );
}
