import "./StartingFlow.scss";
// import SizeChartListWrapper from "../../components/SizeChartListWrapperNeedToDelete/SizeChartListWrapper";
import React, { useState,useContext } from "react";
import GettingStarted from "../../components/GettingStarted/GettingStarted";
import SizeChartUploading from "../../components/SizeChartUploading/SizeChartUploading";
import ThankYou from "../../components/ThankYou/ThankYou";
import {RetailerProfileContext} from '../../context/RetailerProfileContext';
import { useLocation } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";

import {page_location} from "../../utils/page-state";
import Welcome from "../../components/Welcome/Welcome";


function StartingFlow() {
  const [uploadButnClicked, setUploadButnClicked] = useState(page_location.WELCOME_PAGE);
  const [retailerProfile] = useContext(RetailerProfileContext);
  const location = useLocation();
  const renderedFrom = location.state ? location.state.from : null;

  let form = (
    // <GettingStarted
    //   setUploadButnClicked={setUploadButnClicked}
    //   uploadButnClicked={uploadButnClicked}
    // />
    <Welcome/>
  );
  if (uploadButnClicked === page_location.HAVE_SIZE_CHART_PAGE) {
    form = <SizeChartUploading setUploadButnClicked={setUploadButnClicked} />;
  } else if (page_location.THANK_YOU_PAGE === uploadButnClicked) {
    form = <ThankYou />;
  }


  const isRetailerHaveSizeChard = location.state && location.state.haveSizeCharts;
  
  return (
    <div className="getting-started-wrapper">
      {/*<h1 className='main-header'>MySizeID <span className='main-header bold'>RECOMMENDER SET-UP</span></h1>*/}
      {/*<div style={{display: 'flex', justifyContent: 'space-evenly'}}>*/}
      {/*    <SelectedSizeChartContext.Provider value={[selectedSizeChart, setSelectedSizeChart]}>*/}
      {/*        <SizeChartListWrapper/>*/}
      {/*        <StoreProduct/>*/}
      {/*    </SelectedSizeChartContext.Provider>*/}
      {/*</div>*/}

      {(isRetailerHaveSizeChard || RetailerProfileContext.size_chart_count > 0) ? <TopBar/> : null}
      { renderedFrom !== "add_more_size_charts" && retailerProfile.size_chart_count > 0 ? <meta http-equiv="refresh" content="0; /main" />  :form}
    </div>
  );
}
export default StartingFlow;
