import React from 'react'
import './WidgetPlacementSettings.scss'
import WidgetPlacementSiblingBefore from '../../assets/images/widget-placement-sibling-before.svg'
import WidgetPlacementSiblingAfter from '../../assets/images/widget-placement-sibling-after.svg'
import WidgetPlacementChildFirst from '../../assets/images/widget-placement-child-first.svg'
import WidgetPlacementChildLast from '../../assets/images/widget-placement-child-last.svg'
import WidgetPlacementNextOption from '../../assets/images/arrow-right.svg'
import WidgetPlacementPreviousOption from '../../assets/images/arrow-left.svg'
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import * as AnchorTypes from '../../utils/widget-placement-settings-anchor-type'
import resetToDefaultButtonImage from '../../assets/images/reset_to_default_buttton_image.svg'


export default function WidgetPlacementSettings({
    anchorElementSelector,
    setAnchorElementSelector,
    anchorElementType,
    setAnchorElementType,
    isWidgetSettingsChanged,
    resetToDefaultSettings,
    saveLoader,
    onSaveHandler
}) {

    const showNextPlacementImage = (anchorElementType) => {
        switch(anchorElementType) {
            case AnchorTypes.SIBLING_BEFORE: setAnchorElementType(AnchorTypes.SIBLING_AFTER); break;
            case AnchorTypes.SIBLING_AFTER: setAnchorElementType(AnchorTypes.CHILD_FIRST); break;
            case AnchorTypes.CHILD_FIRST: setAnchorElementType(AnchorTypes.CHILD_LAST); break;
            case AnchorTypes.CHILD_LAST: setAnchorElementType(AnchorTypes.SIBLING_BEFORE); break;
        }
    }
    
    const showPreviousPlacementImage = (anchorElementType) => {
        switch(anchorElementType) {
            case AnchorTypes.SIBLING_BEFORE: setAnchorElementType(AnchorTypes.CHILD_LAST); break;
            case AnchorTypes.SIBLING_AFTER: setAnchorElementType(AnchorTypes.SIBLING_BEFORE); break;
            case AnchorTypes.CHILD_FIRST: setAnchorElementType(AnchorTypes.SIBLING_AFTER); break;
            case AnchorTypes.CHILD_LAST: setAnchorElementType(AnchorTypes.CHILD_FIRST); break;
        }
    }

    const getPlacementImage = (anchorElementType) => {
        switch(anchorElementType) {
            case AnchorTypes.SIBLING_BEFORE: return WidgetPlacementSiblingBefore
            case AnchorTypes.SIBLING_AFTER: return WidgetPlacementSiblingAfter
            case AnchorTypes.CHILD_FIRST: return WidgetPlacementChildFirst
            case AnchorTypes.CHILD_LAST: return WidgetPlacementChildLast
        }
    }

    const getPlacementTitle = (anchorElementType) => {
        switch(anchorElementType) {
            case AnchorTypes.SIBLING_BEFORE: return 'Above Anchor Element'
            case AnchorTypes.SIBLING_AFTER: return 'Below Anchor Element'
            case AnchorTypes.CHILD_FIRST: return 'First element inside the anchor element'
            case AnchorTypes.CHILD_LAST: return 'Last element inside the anchor element'
        }
    }

    return (
        <div className='placement-settings'>
            <div className='placement-settings-anchor'>
                Anchor element selector
                <input 
                    type="text" 
                    value={anchorElementSelector} 
                    className='placement-settings-anchor-input'
                    onChange={(e) => setAnchorElementSelector(e.target.value)} />
            </div>
            <div className='placement-settings-type'>
                <p className="placement-settings-type__title">Select placement type:</p>
                <div className='placement-settings-type-selection-preview'>
                    <div className='placement-settings-type-selection-preview-frame'>
                        <p className='placement-settings-type-selection-preview-frame-title'>{getPlacementTitle(anchorElementType)}</p>
                        <div className='placement-settings-type-selection-preview-frame-content'>
                            <img id='placement-image-arrow' className='placement-settings-type-selection-preview-frame-content-left-arrow' 
                                src={WidgetPlacementPreviousOption}
                                onClick={() => showPreviousPlacementImage(anchorElementType)} />
                            <img className={'placement-settings-type-selection-preview-frame-content-image'}
                                src={getPlacementImage(anchorElementType)} />
                            <img id='placement-image-arrow' className='placement-settings-type-selection-preview-frame-content-right-arrow' 
                                src={WidgetPlacementNextOption}
                                onClick={() => showNextPlacementImage(anchorElementType)} />
                        </div>
                    </div>
                </div>
                <div className='placement-settings-buttons-section'>
                    <div className="placement-settings-reset-to-default-button" onClick={() => resetToDefaultSettings()}>
                        <img src={resetToDefaultButtonImage} />
                        <div className="reset-to-default-button">Reset to default</div>
                    </div>
                    <div className={`placement-settings-save-button ${!isWidgetSettingsChanged() ? "placement-settings-save-button--disabled" : ""}`} onClick={() => onSaveHandler()}>{saveLoader ? <CircularIndeterminate color="white" size="30px"/> : "Save"}</div>
                </div>
            </div>
        </div>
    )
}