export const config = {

    REACT_APP_USER_GUIDE_LINK: "https://get.mysz.io/s_user_guide.pdf",
    REACT_APP_TERMS_AND_CONDITIONS_LINK: "http://privacy.mysz.io/terms-mysizeid.html",
    REACT_APP_FAQ_LINK: "https://help.mysz.io/en/collections/3075554-faq",
    REACT_APP_GTM_TAG: "GTM-PZ65M5C",
    REACT_APP_HELP_LINK_SHOPIFY: "https://help.mysz.io/hc/en-us/sections/4405380899601-Shopify",
    REACT_APP_HELP_LINK_WIX: "https://help.mysz.io/hc/en-us/sections/4406114467857-Wix",
    REACT_APP_HELP_LINK_WOOCOMMERCE: "https://help.mysz.io/hc/en-us/sections/4405385589137-WooCommerce",
    REACT_APP_HELP_LINK_LIGHTSPEED: "https://help.mysz.io/hc/en-us/sections/4405392020753-LightSpeed",
    REACT_APP_HELP_LINK_PRESTASHOP: "https://help.mysz.io/hc/en-us/sections/4410640337297-PrestaShop",
    REACT_APP_HELP_LINK_EXTERNAL: "https://help.mysz.io/hc/en-us/sections/4405380902417-Other-Platforms",
    REACT_APP_HELP_LINK_BITRIX: "https://help.mysz.io/hc/en-us/sections/4415488712209-Bitrix",
    REACT_APP_PRIVACY_POLICY_LINK: "https://help.mysz.io/hc/en-us/articles/4405815732625-Privacy-Policy",
    REACT_APP_TOS_LINK: "https://help.mysz.io/hc/en-us/articles/4405816039569-Terms-Conditions",

    staging: {
        REACT_APP_CALENDLY_LINK: "https://calendly.com/mysizeid-staging?text_color=211c51&primary_color=f8686e",
        REACT_APP_API_BASE_URL: "https://api-staging.mysz.io"
    },
    production: {
        REACT_APP_CALENDLY_LINK: "https://calendly.com/aviveldan/intro",
        REACT_APP_API_BASE_URL: "https://api.mysz.io",
        REACT_APP_MEDIUM_PLAN_ID: 17,
        REACT_APP_PETITE_PLAN_ID: 15,
        REACT_APP_FREE_PLAN_ID: 7
    }
}


