import './PairedProductAlert.scss';
import ApiService from '../../services/ApiService';
import warningIcon from '../../assets/images/warning-icon.svg';
import CommandQueue from '../../utils/CommandQueue';
import {RetailerProfileContext} from '../../context/RetailerProfileContext';
import {useContext} from 'react';
import { LocalStorageService } from '../../services/LocalStorageService';


export default function PairedProductAlert({
    setShowPairedAlert, 
    selectedSizeChart,
    id,
    title,
    updateShopProductList,
    setIsDialogOpen,
    sizeChart,
    productsToPair,
    setProductsToPair,
    renderedFrom,
    setShowProgresBar,
    setPairedProduct,
    productUrl

}) {

  const [retailerProfile] = useContext(RetailerProfileContext)  


    
    const onConfirmHandler = async() => {
        if(productsToPair.length > 1 && setShowProgresBar)
            setShowProgresBar(true)
        

        if(renderedFrom === "StoreProductItem"){

            let firstPair = LocalStorageService.get("first_pair")
            const newProductToPair = [...productsToPair];
    
            productsToPair.forEach((prod, index) => {
                if(prod.productId === id) {
                    newProductToPair[index].sizeChartId = selectedSizeChart.id ;
                    newProductToPair[index].sizeChartName = selectedSizeChart.name ;
                }
            })
    
            setProductsToPair(newProductToPair);
            
            new ApiService().connectSizeChartFromProduct(id, { size_chart_id: selectedSizeChart.id, product_url: productUrl });
            let newProductToPairr = [...productsToPair];
                  setProductsToPair(newProductToPairr.filter(productPair => productPair.productId !== id ));
            updateShopProductList('add', id, selectedSizeChart)
            if (firstPair == null) {
                LocalStorageService.set("first_pair", false)
                setIsDialogOpen(true)
            }
            setShowPairedAlert(false)
        }
        else if(renderedFrom === "StoreProductList"){
            let queue = new CommandQueue();

    
            await productsToPair.forEach((product, i) => {
                setShowPairedAlert(false);
                
                let func = () => {
                    setPairedProduct(i + 1)
                  new ApiService().connectSizeChartFromProduct(product.productId, { size_chart_id: selectedSizeChart.id, product_url: product.productUrl });
                  updateShopProductList('add', product.productId, selectedSizeChart);
                }
                queue.enqueue(func);
              });
              queue.executeAll();
        }
    }



    const textHelper = []

    productsToPair.forEach(prod => {

        if(prod.sizeChartId && prod.sizeChartId  !== selectedSizeChart.id) {
            textHelper.push({product_name: prod.productName, current_chart_name: prod.sizeChartName}) 
        }
    });

    
    

    const outputText = (
        <div>
            <p>These products already have paired charts.</p>

            <p className="pair-list">

                {textHelper.map(obj => <div> <span>“{obj.product_name}”</span> is paired with <span>“{obj.current_chart_name}”</span> </div>)}
            </p>

            <p className={"replaced-chart-text"}>Do you want to replace them with “{selectedSizeChart.name}”?</p>
            
               
        </div>


    );

    

    return (
        <div className="paired-product-alert-container" >
            <div className="paired-product-alert-modal" onClick={() => setShowPairedAlert(false)}>

            </div>
            <div style={{top: retailerProfile.source === 'presta' && '5%', transform: retailerProfile.source === 'presta' && 'translateX(-50%)' }} className="paired-product-alert">
                <img src={warningIcon} alt="warning icons"></img>
                {renderedFrom === "StoreProductItem" ?
                    <div>
                        <p>
                            "{title}" is already paired with "{sizeChart.name}" size chart.
                        </p>
                        <p className="question">
                            Do you want to replace it with “{selectedSizeChart.name}”?
                        </p>
                    </div>
                    :
                    outputText
                }

                <div className="buttons-wrapper">
                    <div onClick={() => setShowPairedAlert(false)}> 
                        Cancel
                    </div>
                    <div onClick={onConfirmHandler}> 
                        Replace
                    </div>
                </div>
            </div>
        </div>
    )
}
