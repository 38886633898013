export const convertForApi = (sizeChart) => {
    if(sizeChart.gender === 'male'){
        sizeChart.gender = 0;
    } else if(sizeChart.gender === 'female'){
        sizeChart.gender = 1;
    }else{
        sizeChart.gender = 2;
    }

    if(sizeChart.measurement_system === "metric"){
        sizeChart.measurement_system = 0
    }else{
        sizeChart.measurement_system = 1
    }


    const sizesArr = sizeChart.sizes

    for (let i = 0; i < sizesArr.length; i++) {
        const ranges  = sizesArr[i].ranges;
        for (let j = 0; j < ranges.length; j++) {
            const element = ranges[j];
            element.measurement_type_name = element.measurement_type.name;            
        }   
    }
    return sizeChart
}