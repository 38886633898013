import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import XcloseButton from '../../assets/images/x-close-button.svg'
import "./AlertDialogLock.scss";
import iconLock from '../../assets/images/icon-awesome-lock.svg';

export default function AlertDialogLock({ setIsDialogOpen, header, description, okButton, cancelButton,closeImg }) {

    const handleClose = (type) => {
        if (type === true) {
            setIsDialogOpen(true)
        } else {
            setIsDialogOpen(false)
        }
    };

    const closePopUp = () => {
        setIsDialogOpen(false)
    }

    
    return (
        <div className='mysize-dialog'>
            <Dialog
                className="dialog-lock"
                open={true}
                onClose={() => handleClose(false)}
            >
                <div className='lock-container'>
                    <img className='x-button' src={XcloseButton} alt="Close" onClick={handleClose} />
                    <DialogContent>
                        <div className='dialog-content-lock'>
                            <img src={iconLock} alt="This size chart is maintained by MySize" />
                            <span>This size chart is maintained by MySize</span>
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
}

