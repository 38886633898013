import React, { useState } from 'react';
import './SizeChartListMap.scss';
import SizeChartItem from "./SizeChartItem";
import backImg from '../../assets/images/back.svg'
import { LocalStorageService } from '../../services/LocalStorageService';
import { config } from '../../config';



function SizeChartListMap({ elRefIntegrationCode, sizeCharts, setGenericChartListUpdater, chartReceieveFromBe }) {
    const [redirect, setRedirect] = useState(LocalStorageService.get('redirect'))

    const ReturnToBE = () => {
        window.parent.postMessage({ 'redirect': `${redirect}` }, config[process.env.REACT_APP_ENV].REACT_APP_API_BASE_URL)
        LocalStorageService.remove('redirect')
    }


    return (
        //******Need to add an edge case protection that returns 400 from the server**********
        chartReceieveFromBe === false ? <div>Loading...</div> :
            sizeCharts.length === 0 ? <p className='not-have-size-chart-p'>No size charts</p> :
                <div className="size-charts-list-scroll">

                    <div className='exist-size-chart-list-map'>
                        {redirect ? <img onClick={() => { ReturnToBE() }} src={backImg} style={{ width: '30px', height: '30px', position: "absolute", top: '23px', left: '0', cursor: 'pointer' }} /> : null}
                        {sizeCharts.map((chart, index) => {
                            if (index === 0) {
                                console.log(chart.name);
                                return <SizeChartItem isLockedByMysize={chart.is_locked_by_mysize} elRefIntegrationCode={elRefIntegrationCode} key={chart.id} name={chart.name} gender={chart.gender} ageCategory={chart.age_category} id={chart.id} sizeChartType={chart.size_chart_type?.name} measurementSystem={chart.measurement_system} setGenericChartListUpdater={setGenericChartListUpdater} chartStatus={chart.approval_status} integration_code={chart.integration_code} />

                            } else {

                                return <SizeChartItem isLockedByMysize={chart.is_locked_by_mysize} key={chart.id} name={chart.name} gender={chart.gender} ageCategory={chart.age_category} id={chart.id} sizeChartType={chart.size_chart_type?.name} measurementSystem={chart.measurement_system} setGenericChartListUpdater={setGenericChartListUpdater} chartStatus={chart.approval_status} integration_code={chart.integration_code} />
                            }
                        })}
                    </div>
                </div>
    );




}




export default SizeChartListMap;
