export const translateByLocale = (locale) => {
    switch (locale) {
        case "de":
            return 'Wie ist meine Größe?'
        
        case "en":
            return 'What\'s my size?'
        
        case "es":
            return ' ¿Cuál es mi talla?'
        
        case "fr":
            return 'Quelle est ma taille?'
        
        case "he":
            return 'מה המידה שלי?'
        
        case "it":
            return 'Qual è la mia taglia?'
        
        case "nl":
            return 'Wat is mijn maat?'
        
        case "po":
            return 'Qual o meu tamanho?'
        
        case "ru": 
            return 'Узнать размер'
 
        case "tr": 
            return 'Bedenimi göster?'

        case "pl": 
            return 'Jaki jest mój rozmiar?'

        default: 
            return 'What\'s my size?'
    }   
}