import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import arrowDown from '../../assets/images/arrow-down.svg';
import './SimpleMenu.scss';
import topImg from '../../assets/images/clothes-categories/top.svg';
import bottomImg from '../../assets/images/clothes-categories/bottom.svg';
import dressImg from '../../assets/images/clothes-categories/dress.svg';
import dressShirtImg from '../../assets/images/clothes-categories/dress_shirt.svg';
import braImg from '../../assets/images/clothes-categories/bra.svg';
import hatImg from '../../assets/images/clothes-categories/hat.svg';
import sockImg from '../../assets/images/clothes-categories/sock.svg';
import overallImg from '../../assets/images/clothes-categories/overall.svg';
import shoeImage from '../../assets/images/clothes-categories/big-shoe.svg';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";





export default function SimpleMenu({ menuName, options, includePhoto, setValue, freeText, value,type }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [retailerProfile] = useContext(
        RetailerProfileContext
      );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const getTypeImg = (sizeChartType) => {
        sizeChartType = sizeChartType.replace('_', ' ');
        // if (sizeChartType === undefined) return hangerImg;
        if (sizeChartType.includes('top')) return topImg;
        else if (sizeChartType.includes('bottom')) return bottomImg;
        else if (sizeChartType.includes('overall')) return overallImg;
        else if (sizeChartType.includes('bra')) return braImg;
        else if (sizeChartType.includes('dress shirt')) return dressShirtImg;
        else if (sizeChartType.includes('sock')) return sockImg;
        else if (sizeChartType.includes('hat')) return hatImg;
        else if (sizeChartType.includes('dress')) return dressImg;
        else if (sizeChartType.includes('shoe')) return shoeImage;
        // else if (sizeChartType.includes('undefined')) return hangerImg;
        // else return hangerImg

    }

    const isSourceWithOutProductsPair = () =>{
        return  retailerProfile.source === "external" || retailerProfile.source === "woocommerce" || retailerProfile.source === 'bitrix' || retailerProfile.source === 'plugin' || retailerProfile.source === 'lightspeed'
    }
    
    
    return (
        <div>
            <Button style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',width:'30px',display: 'unset', position: 'relative' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {menuName.replace('_',' ')}
                <img alt='size chart type icon' style={{ right :'7px',top: '16px',position: 'absolute' , transform: anchorEl ? 'rotate(180deg)' : '', transition: 'all 0.2s linear'  }} src={arrowDown} />
            </Button>
            <Menu
            
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} 
                style={isSourceWithOutProductsPair() &&  {top: "5px"}}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
               >   
                {!freeText ? options.map((option, index) => {
                    return <MenuItem key={option + index} onClick={() => { setValue(option,type); handleClose() }}>{includePhoto && option !== 'All' ? <img style={{ width: '40px' }} src={getTypeImg(option.toLowerCase())} alt='icon' /> : null} {option.replace("_"," ")}</MenuItem>
                }) :(
                <div className='input-wrapper'>
                    <input type='search'  value={value} onChange={(event) => {setValue(event.target.value,type)}}  
                        onKeyPress= {(e) => {
                        if (e.key === 'Enter') {
                            handleClose();
                        }}}
                    />
                </div>)
                }
            </Menu>
        </div>
    );
}
