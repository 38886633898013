import React, { useState } from "react";
import "./WidgetPopupSettings.scss";
import ShowGender from "../ShowGender/ShowGender";
import UnisexSizeCharts from "../UnisexSizeCharts/UnisexSizeCharts";
import WidgetFontSettings from "../WidgetFontSettings/WidgetFontSettings";
import WidgetPopupPreview from "../WidgetPopupPreview/WidgetPopupPreview";
import CircularIndeterminate from "../UI/CircularIndeterminate/CircularIndeterminate";

export default function WidgetPopupSettings({
  hideGender,
  setHideGender,
  unisexSizeCharts,
  setUnisexSizeCharts,
  fontName,
  setFontName,
  cssLink,
  setCssLink,
  resetToDefaultSettings,
  onSaveHandler,
  isWidgetSettingsChanged,
  saveLoader
}) {
  const defaultSettings = {
    showFontInfoBubble: false,
    showGenderInfoBobble: false,
  };

  const [showFontInfoBubble, setShowFontInfoBubble] = useState(defaultSettings.showFontInfoBubble);
  const [showGenderInfoBubble, setShowGenderInfoBubble] = useState(defaultSettings.showGenderInfoBubble);

  return (
    <div className="advanced-widget-settings">
      <div className="advanced-widget-settings-preferences">
        <ShowGender
          hideGender={hideGender}
          setHideGender={setHideGender}
          showGenderInfoBubble={showGenderInfoBubble}
          setShowGenderInfoBubble={setShowGenderInfoBubble}
        />

        <UnisexSizeCharts
          unisexSizeCharts={unisexSizeCharts}
          setUnisexSizeCharts={setUnisexSizeCharts}
        />

        <WidgetFontSettings
          fontName={fontName}
          setFontName={setFontName}
          cssLink={cssLink}
          setCssLink={setCssLink}
          showFontInfoBubble={showFontInfoBubble}
          setShowFontInfoBubble={setShowFontInfoBubble}
        />
        <div className={`advanced-widget-settings-save-button ${!isWidgetSettingsChanged() ? "advanced-widget-settings-save-button--disabled" : ""}`} onClick={() => onSaveHandler()}>{saveLoader ? <CircularIndeterminate color="white" size="30px"/> : "Save"}</div>
      </div>
      <div className="advanced-widget-settings__preview">
        <div className="advanced-widget-settings__title">Widget Preview</div>
        <div>
          <WidgetPopupPreview
            hideGender={hideGender}
            resetToDefaultSettings={resetToDefaultSettings} 
            unisexSizeCharts={unisexSizeCharts} />
        </div>
      </div>
    </div>
  );
}
