import React, { useState, useContext } from 'react';
import './AddGenericChartItem.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import ApiService from '../../../services/ApiService';
import SizeChartAddedPopUp from '../../UI/SizeChartAddedPopUp/SizeChartAddedPopUp';
import {TipsContext} from '../../../context/TipsContext';

export default function AddGenericChart({img, category, title, template, setTipsRendered, setGenericChartListUpdater}) {

    const [isLoading, setIsLoading] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);

    const [tipsState, tipsHandler] = useContext(
        TipsContext
      );


    const addGenericHandler = async() => {
        const body = {
            name: template,
          };
        await new ApiService().addGenericSizeChartToRetailer(body);
        showpopUpHandler();
        setIsLoading(false);
        setTipsRendered(prev => !prev)
        setGenericChartListUpdater((prev) => prev + "1");
        
        if(tipsState.create_size_chart){
            tipsHandler("create_size_chart");
        }
    }

     const showpopUpHandler = () => {
    setShowPopUp(true);
    window.setTimeout(() => {
      setShowPopUp(false)
    },3000)
  }

    return (
        <React.Fragment>
            <div className="add-generc-chart-item">  
                <div className="left-wrapper">
                    <img src={img} alt='chart type'  /> 
                    <div className="seperator"></div>
                    <div className="title">{title}</div>
                </div>
                <div className="right-wrapper">
                    <div  onClick={() => {setIsLoading(true); addGenericHandler(); }}>{ isLoading ? <CircularProgress style={{ color: '#F8686E', marginRight: "31px" }}/> : <div className="add-button">Add</div>}</div>
                </div>
            </div>
            {showPopUp && <SizeChartAddedPopUp/>}
        </React.Fragment>
    )
}
