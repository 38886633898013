import React, { useEffect, useState } from "react";
import "./BookMeeting.scss";
import blueArrowRight from "../../assets/images/blue-right-arrow.svg";
import { Link } from "react-router-dom";
import CircularIndeterminate from "../UI/CircularIndeterminate/CircularIndeterminate";
import { config } from "../../config";

export default function BookMeeting() {
  const [eventScheduled, setEventScheduled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);

    window.addEventListener("message", isCalendlyEvent);
    return () => {
      window.removeEventListener("scroll", isCalendlyEvent);
    };
  }, []);

  const isCalendlyEvent = (e) => {
    console.log(e);
    if (e.data.event && e.data.event.indexOf("calendly") === 0) {
      if (e.data.event === "calendly.event_scheduled") {
        setEventScheduled(true);
      } else if (e.data.event === "calendly.event_type_viewed" || e.data.event === "calendly.profile_page_viewed") {
        setLoading(false);
      }
    }
  };

  return (
    <div className="book-meeting">
      <div className="book-meeting__title">
        {eventScheduled ? "We’re all set!" : "Book a meeting"}
        {!eventScheduled && (
          <Link to="/welcome">
            <img
              className="book-meeting__arrow-left"
              src={blueArrowRight}
              alt="blue arrow left" />
          </Link>
          // <Link to="/getting-started">
          //   <img
          //     className="book-meeting__arrow-left"
          //     src={blueArrowRight}
          //     alt="blue arrow left" />
          // </Link>
        )}
      </div>
      <div className="book-meeting__description">
        {eventScheduled
          ? "We’re looking forward to meeting you. You can now close this browser tab or go back to MySizeID settings."
          : "Pick a time that fits you best for us to set up your widget with you"}
      </div>
      <div
        className="calendly-inline-widget"
        data-url={config[process.env.REACT_APP_ENV].REACT_APP_CALENDLY_LINK}
        style={{ minWidth: "320px", height: "650px", width: "100%" }}
      >
        {loading && (
          <div className="book-meeting__loader">
            <CircularIndeterminate />{" "}
          </div>
        )}
      </div>
      {eventScheduled && (
        <Link to="/" className="all-set__button">
          Take me to MySizeID
        </Link>
      )}
    </div>
  );
}
