import armLength from '../assets/images/tooltips/arm_length.webp';
import chesctcBra from '../assets/images/tooltips/chestc_bra.webp';
import chestc from '../assets/images/tooltips/chestc.webp';
import footLength from '../assets/images/tooltips/foot_length.webp';
import headc from '../assets/images/tooltips/headc.webp';
import hipc from '../assets/images/tooltips/hipc.webp';
import inseam from '../assets/images/tooltips/inseam.webp';
import InseamBone from '../assets/images/tooltips/InseamBone.webp';
import neckBase from '../assets/images/tooltips/neck_base.webp';
import neckc from '../assets/images/tooltips/neckc.webp';
import overBust from '../assets/images/tooltips/over_bust.webp';
import sleevecm from '../assets/images/tooltips/sleevecm.webp';
import underBust from '../assets/images/tooltips/under_bust.webp';
import waistNavel from '../assets/images/tooltips/waist_navel.webp';
import waistPants from '../assets/images/tooltips/waist_pants.webp';
import waistc from '../assets/images/tooltips/waistc.webp';



const measurementNames =  {
    "waist_navel": waistNavel,
    "waistc": waistc,
    "waist_pants": waistPants,
    "hipc": hipc,
    "neckc": neckc, 
    "neck_base":neckBase,
    "sleevecm": sleevecm,
    "arm_length": armLength,
    "inseam": inseam,
    "InseamBone": InseamBone,
    "weight": null,
    "over_bust": overBust,
    "under_bust": underBust,
    "shoes_0": null,
    chestc: chestc,
    "foot_length": footLength,
    headc: headc,
    "chestc_bra": chesctcBra,


    // KIDS
    "waist_navel_kids": waistNavel,
    "waistc_kids": waistc,
    "waist_pants_kids": waistPants,
    "hipc_kids": hipc,
    "neckc_kids": neckc, 
    "neck_base_kids":neckBase,
    "sleevecm_kids": sleevecm,
    "arm_length_kids": armLength,
    "Inseam_kids": inseam,
    "InseamBone_kids": InseamBone,
    "weight_kids": null,
    "over_bust_kids": overBust,
    "under_bust_kids": underBust,
    "shoes_0_kids": null,
    "chestc_kids": chestc,
    "foot_length_kids": footLength,
    "headc_kids": headc,
    "chestc_bra_kids": chesctcBra
}


const getImage = (name) => {
    return measurementNames[name];
}
export default getImage;