import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./ProgresBarDialog.scss";
import ProgressBar from '../ProgressBar/ProgressBar';

export default function ProgresBarDialog({ setIsDialogOpen, header, description,completed }) {
    
    return (
        <div className='mysize-dialog'>
            <Dialog
                className="dialog"
                open={true}
                onClose={() => {return}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ fontSize: '25px' }} id="alert-dialog-title" className="alert-title-bar" >{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{margin: 0}}> 
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="alert-buttons-wrapper">
                </DialogActions>
                <p style={{margin: 0,fontSize: '14px',fontWeight: 'bold',color: '#f8686e',paddingBottom: '10px'}}>{parseInt(completed)}%</p>
                <ProgressBar bgcolor={"#f8686e"} completed={completed}/>        
            </Dialog>
        </div >
    );
}

