import React from "react";
import "./SizeChartUnit.scss";
import { chartWizardPageNames } from "../../../utils/page-state";

const units = {
  CM: "metric",
  IN: "imperial"
};

export default function SizeChartUnit({
  setChartPageState,
  measurementSystem,
  setChartMeasurementSystem,
  fromEdit,
  setEditRenderer

}) {
    // SizeChartUnit rendered from editor
    if(fromEdit) {
        return (
            <div className="size-chart-unit">
                <h1>Units Of Measure</h1>
                <div className="buttons-wrapper">
                    <div
                        className={`button ${measurementSystem === units.CM && "active"}`}
                        onClick={() => setChartMeasurementSystem(units.CM)}
                    >
                        CM
                    </div>
                    <div
                        className={`button ${measurementSystem === units.IN && "active"}`}
                        onClick={() => setChartMeasurementSystem(units.IN)}
                    >
                        IN
                    </div>
                </div>
                <div className={`next-button ${!measurementSystem && "unable"}` } onClick={() => setEditRenderer(null)}>

                    Next
                </div>
            </div>
        );
    }

    const nextClickHandler = () => {
        // check for input
        if (!measurementSystem) {
            return;
        }
        setChartPageState(chartWizardPageNames.CHART_WIZARD_AGE);
    };

  return (
    <div className="size-chart-unit">
      <h1>Units Of Measure</h1>
      <div className="buttons-wrapper">
        <div
          className={`button ${measurementSystem === units.CM && "active"}`}
          onClick={() => setChartMeasurementSystem(units.CM)}
        >
          CM
        </div>
        <div
          className={`button ${measurementSystem === units.IN && "active"}`}
          onClick={() => setChartMeasurementSystem(units.IN)}
        >
          IN
        </div>
      </div>
      <div
        className={`next-button ${!measurementSystem && "unable"}`}
        onClick={nextClickHandler}
      >
        Next
      </div>
    </div>
  );
}
