import React, { useEffect } from "react";
import "./SelectSizingType.scss";
import { chartWizardPageNames } from "../../../utils/page-state";

const sizingTypes = {
  WIDTH_LENGTH: "widthLength",
  ALPHA_NUMERIC: "alphaNumeric",
  CUP_BAND: "cupBand",
};

const ignoredSizeChartType = ["hats", "socks"];


 // categories that have width/length and no measurement types 
const widthLengthNoMeasTypes = ["shoes"];

export default function SelectSizingType({
  setChartPageState,
  sizingType,
  setSizingType,
  sizeChartType,
}) {
  useEffect(() => {
    // skip the component be rendering on ignoredSizeChartType
    if (ignoredSizeChartType.includes(sizeChartType)) {
      setChartPageState(chartWizardPageNames.CHART_WIZARD_SELECT_MEASUREMENT_TYPES);
    }


    // check for categories that have width/length and no measurement types 
    if (widthLengthNoMeasTypes.includes(sizeChartType)) {
      setSizingType(sizingTypes.WIDTH_LENGTH);
      setChartPageState(chartWizardPageNames.CHART_WIZARD_DONE);
    }
    
  }, [sizeChartType, setChartPageState,setSizingType]);

  const nextClickHandler = () => {
    if (!sizingType) {
      return;
    }
    // else if (sizingType === sizingTypes.WIDTH_LENGTH && sizeChartType === 'bras'){
    //   setChartPageState()
    // }
    setChartPageState(
      chartWizardPageNames.CHART_WIZARD_SELECT_MEASUREMENT_TYPES
    );

    
  };

  if (ignoredSizeChartType.includes(sizeChartType)) {
    return null;
  }

  return (
    <div className="select-sizing-type">
      <h1>Select sizing type:</h1>
      <div className="buttons-wrapper">
        <div
          className={`button ${
            sizingType === sizingTypes.ALPHA_NUMERIC && "active"
          }`}
          onClick={() => setSizingType(sizingTypes.ALPHA_NUMERIC)}
        >
          <strong>Alpha/Numeric</strong>
          <span >(XS-XXL / 32-48 or 0-12)</span>
        </div>

        {sizeChartType === "bras" ? (
          <div
            className={`button ${
              sizingType === sizingTypes.CUP_BAND && "active"
            }`}
            onClick={() => setSizingType(sizingTypes.CUP_BAND)}
          >
            <strong>Cup/Band</strong>
            <span>(eg.34B)</span>
          </div>
        ) : (
          <div
            className={`button ${
              sizingType === sizingTypes.WIDTH_LENGTH && "active"
            }`}
            onClick={() => setSizingType(sizingTypes.WIDTH_LENGTH)}
          >
            <strong>Width/Length</strong><span>(XS-XXL / Short / Regular / Tall)</span>
          </div>
        )}
      </div>
      <div
        className={`next-button ${!sizingType && "unable"}`}
        onClick={nextClickHandler}
      >
        Next
      </div>
    </div>
  );
}
