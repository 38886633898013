import React,{useState, useContext} from 'react';
import { ReactComponent as MySizeIcon } from "../../assets/images/mysize-icon.svg";
import { ReactComponent as OpenEyeIcon } from "../../assets/images/open-eye-icon.svg";
import { ReactComponent as CloseEyeIcon } from "../../assets/images/close-eye-icon.svg";
import "./SignUp.scss";
import registrationGirl from '../../assets/images/registration-girl.webp'
import ApiService from '../../services/ApiService';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import {useNavigate, Link} from "react-router-dom";
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import { emailSchema, signUpValidation } from '../../validations/input-validations';
import Footer from '../Footer/Footer';
import { LocalStorageService } from '../../services/LocalStorageService';


export default function SignUp() {

    const [brandName, setBrandName] = useState("");
    const [brandWebsite, setBrandWebsite] = useState("");
    const [email, setEmail] = useState("");
    const [isMarketingAccepted, setIsMarketingAccepted] = useState(false);
    const [measurementSystem, setMeasurementSystem] = useState(0);
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isPassVisible, setIsPassVisible] = useState(false);

    const [retailerProfile, setRetailerProfile] = useContext(RetailerProfileContext);
    const history = useNavigate();




    const submit = async() => {

       const errors = await signUpValidation({email,password, brandName, brandWebsite })

        if(errors){
            setErrors(errors);
            return
        }
        setErrors({})
        setIsLoading(true);
        const api = new ApiService();
        const params = {
            brand_name: brandName,
            email,
            is_marketing_accepted: isMarketingAccepted,
            measurement_system: measurementSystem,
            password,
            website: brandWebsite
        }
       
    
        const response = await api.signupRetailer(params);
        
        // server error response
        if(response.code === 401){
            setErrors({email: response.message});
            setIsLoading(false);
            return;
        }

        if(response.code === 500){
            setErrors({email: "Unvalid email"});
            setIsLoading(false);
            return;
        }
        
        if(response.code){
            setIsLoading(false);
            let errorCodes = [{field: "password", errDescCode: "WEAK_PASSWORD"}]
            errorCodes.forEach(errCode => {
                if (response.message.includes(errCode.errDescCode)) setErrors({...errors, [errCode.field]: response.message});
            })
            return;
        }
        
        const retailer_token = response.account.token;

        LocalStorageService.set("retailer_token", retailer_token);
        const retailerProfile = await new ApiService().getRetailerProfile();
        const retailerMeta = await new ApiService().getRetailerMeta();
        retailerProfile.meta = retailerMeta;
        setRetailerProfile(retailerProfile);
        setIsLoading(false);
        history("/prepare-account", {replace: true})
    }

    return (
        <div className="registraion-container">
           {isLoading && <div className="auth-modal">
                <CircularIndeterminate/>
            </div>}
            <div className="left-side">
                <div><MySizeIcon/></div>
                <h3>Create an account for your brand</h3>
                <form>
                    {/* BRAND NAME */}
                    <div className="input-wrapper-auth">
                        <input id="brand-name" className={`${errors.brandName  && "input-error"}`} onChange={(e) => {setBrandName(e.target.value.trim()); ; setErrors({...errors, brandName:''})}}></input>
                        <label htmlFor="brand-name" className={brandName?"focused":""}>Brand Name</label>
                        {errors.brandName && <div className="error-text">{ errors.brandName === "required" &&  "Please fill out this field" }</div>}
                    </div>

                    {/* BRAND WEBSITE */}
                    <div className="input-wrapper-auth">
                        <input id="brand-website" className={`${errors.brandWebsite === "required" && "input-error"}`} onChange={(e) => {setBrandWebsite(e.target.value.trim()); setErrors({...errors, brandWebsite:''})}}></input>
                        <label htmlFor="brand-website" className={brandWebsite?"focused":""}>Brand Website</label>
                        {errors.brandWebsite && <div className="error-text">{errors.brandWebsite === "required" &&  "Please fill out this field" }</div>}
                    </div>

                    {/* EMAIL */}
                    <div className="input-wrapper-auth">
                        <input id="email" className={`${errors.email && "input-error"}`} onChange={(e) => {setEmail(e.target.value.trim()); setErrors({...errors, email:''})} }></input>
                        <label htmlFor="email" className={email?"focused":""}>Email</label>
                        {errors.email && <div className="error-text">{errors.email === "required" ?  "Please fill out this field" : errors.email === "EMAIL_EXISTS" ? "This email already exist" : "Email is not valid"}</div>}
                   </div>

                   {/* PASSWORD */}
                   <div className="input-wrapper-auth">
                        <input id="password" className={`${errors.password && "input-error"}`} type={`${isPassVisible?"text":"password"}`} onChange={(e) => {setPassword(e.target.value.trim()); setErrors({...errors, password:''})}} value={password}></input>
                        <label htmlFor="password" className={(isPassVisible&&password)||password?"focused":""}>Password</label>
                        {isPassVisible?<OpenEyeIcon className="eye-icon open-eye-icon" onClick={()=>setIsPassVisible(!isPassVisible)} /> : <CloseEyeIcon className="eye-icon" onClick={()=>setIsPassVisible(!isPassVisible)} />}
                        {errors.password && <div className="error-text">{(errors.password === "required"||!password.length) ?  "Please fill out this field" : (`Invalid password (${errors.password==="min"?"Password should be at least 6 characters" : errors.password.substring(errors.password.indexOf(' : ')+3)})`) }</div>}
                   </div>
                    <div className="sign-in-measurement-unit">
                        <p>Set your default measurement unit:</p>
                        <div className="switcher-container">
                            <div className={`${measurementSystem === 0 && "active"}`} onClick={() => setMeasurementSystem(0)}>cm</div>
                            <div className={`${measurementSystem === 1 && "active"}`} onClick={() => setMeasurementSystem(1)}>in</div>
                        </div>
                    </div>
                    <div className="update-container">
                        <input type="checkbox" checked={isMarketingAccepted} onChange={() => setIsMarketingAccepted(!isMarketingAccepted)}></input>
                        <p>Keep me updated on the latest news and exclusive offers</p>
                    </div>
                    <div className="signup-button" onClick={submit}>SIGN UP</div>
                    <div className="signin-inner-container">
                        <p className="signin-text">Already have an account? <Link to="/signin">Sign in</Link></p>
                    </div>
                    <Footer/>
                </form>

            </div>
            <div className="right-side">
                <img src={registrationGirl} width="457px" alt="registration girl"></img>

            </div>
            
        </div>
    )
}
