import * as yup from "yup";

export const emailSchema = yup.object().shape({
  email: yup.string().email().required(),
});


export const signUpValidation = async(inputs) => {
  let schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    brandName: yup.string().required(),
    brandWebsite: yup.string().required(),
  });
  try{

    await schema.validateSync(inputs, {abortEarly: false});
    return false;

  }catch(error){
    const errors = {}
    for(const field of error.inner){
      errors[field.path] = field.type
    }
    return errors;
  }

 
}
export const signInValidation = async(inputs) => {
  let schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
  try{
    await schema.validateSync(inputs, {abortEarly: false});
    return false;
  }catch(error){
    const errors = {}
    for(const field of error.inner){
      errors[field.path] = field.type
    }
    return errors;
  }

 
}