import React from 'react';
import circleChecked from '../../../assets/images/circle-checked.svg'
import "./SizeChartAddedPopUp.scss"


export default function SizeChartAddedPopUp() {

    // const [show, setShow] = useState(true)

    // useEffect(() => {
    //     window.setTimeout(() => {
    //         setShow(false)
    //     }, 3000)
    // }, [])

    // if(!show){
    //     return null;
    // }

    return (
        <div className="size-chart-added-successfully">
            <img className="circle-checked" src={circleChecked} alt="pop up message"></img>
            <div>Size Chart added!</div>
        </div>
    )
}

