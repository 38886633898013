export const page_location = {
  WELCOME_PAGE: "welcome_page",
  HAVE_SIZE_CHART_PAGE: "have_size_chart_page",
  ENTER_OR_UPLOAD_PAGE: "enter_or_upload_page",
  THANK_YOU_PAGE: "thank_you_page",
};

export const chartWizardPageNames = {
  CHART_WIZARD_NAME: "chart_wizard_name",
  CHART_WIZARD_GENDER: "chart_wizard_gender",
  CHART_WIZARD_UNIT: "chart_wizard_unit",
  CHART_WIZARD_AGE: "chart_wizard_age",
  CHART_WIZARD_CATEGORY: "chart_wizard_category",
  CHART_WIZARD_SELECT_SIZING_TYPE: "chart_wizard_select_sizing_type",
  CHART_WIZARD_SELECT_MEASUREMENT_TYPES:
    "chart_wizard_select_measurement_types",
  CHART_WIZARD_DONE: "chart_wizard_done",
};

export const clickedButton = {
  I_DONT_HAVE_A_SIZE_CHART: "i_dont_have_a_size_chart",
  I_HAVE_A_SIZE_CHART_ENTER: "i_have_a_size_chart_enter",
  ADD_MORE_SIZE_CHARTS: "add_more_size_charts",
};

export const mainPages = {
  PRODUCT_PAIR: "product_pair",
  FAQ: "faq",
  WIDGET_SETTINGS: "widget_settings",
  ACCOUNT: "account"
};

export const editPages = {
  NAME: "name",
  GENDER: "gender",
  AGE: "age",
  TYPE: "type"
}