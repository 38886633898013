import React, { useContext, useState } from 'react'
import './HomeNewPage.scss'
import { Link } from "react-router-dom";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";

import MySizeIDLogo from "../../assets/images/mysize-icon.svg";
import registrationGirl from '../../assets/images/registration-girl.webp';
import arrowUp from '../../assets/images/arrow-up.svg';
import packageIcon from '../../assets/images/Icon-package.svg';
import trendingUp from '../../assets/images/Icon-trending-up.svg';
import stats from '../../assets/images/Icon-ionic-md-stats.svg';
import body from '../../assets/images/Icon-ionic-ios-body.svg';

export default function HomeNewPage() {

  const [retailerProfile, setRetailerProfile] = useContext(
    RetailerProfileContext
  );

  const [isOpen, setIsOpen] = useState(false);



  return (
    <div className="welcome-page">
      <div className="content">
        <img src={MySizeIDLogo} alt="logo" className="mysizeid-logo" />
        <header className="welcome-title">Power Your<br />Business</header>
        <h2 className="subtitle">With MySizeID Data Sizing Solution.</h2>
        <div className="buttons-container">
          {retailerProfile?.source !== 'shopify' && retailerProfile?.source !== 'woocommerce' && <Link to="/signin" className="button signin-button">Sign In</Link>}
          <Link to="/book-meeting" className="button book-meeting-button">Book a Demo</Link>
        </div>
        <section className="details">
          <div className="reduce">
            <img src={packageIcon} alt="package" />
            <label>Reduce<br />returns</label>
          </div>
          <div className="spacer"></div>
          <div className="collect">
            <img src={stats} alt="stats" />
            <label>Collect data<br />using algorithms & AI</label>
          </div>
          <div className="increase">
            <img src={trendingUp} alt="trending up" />
            <label>Increase<br />conversion</label>
          </div>
          <div className="spacer"></div>
          <div className="user-friendly">
            <img src={body} alt="body" />
            <label>User friendly for<br />end consumers</label>
          </div>
        </section>
        <button className={`more ${isOpen ? 'up' : 'down'}`} onClick={()=>setIsOpen(!isOpen)}>{isOpen ? 'Less ' : 'More '}<img src={arrowUp} alt="arrow" /></button>

        {isOpen && <div className="list-container">
          <ul className="solution-steps">
            <li>Hyper-personalized size recommendations for each garment, eliminating sizing problems; manufacture less & drive sustainability</li>
            <li>Body measurement data: Deep learning computer algorithms & artificial intelligence (AI) deliver the perfect size recommendation</li>
            <li>Customer profile: Quick and easy user registration; minimal data requirements ensure privacy</li>
            <li>Fast and easy JavaScript integration</li>
            <li>API Integrations: Seamless MySizeID widget integration to any website or mobile app</li>
            <li>FashTech specialist size charts review</li>
            <li>Option to customize MySizeID button appearance</li>
            <li>MySizeID intelligence dashboard: Easy to use, dedicated client dashboard, and size recommendation data visibility</li>
            <li>Widget-usage analytics reports for sales funnel conversion and return rate benchmarking; in cooperation with dedicated account manage</li>
          </ul>
        </div>}
      </div>

      <div className="image">
        <img src={registrationGirl} alt="girl" className="middle-page-upper-right-woman-image" />
      </div>
    </div>
  )
}
