import React, { useState, useEffect, useContext } from 'react';
import { getMeasurementSystemNumber, getGenderNumber, getAgeNumber } from '../../utils/sizeChartFunction';
import './BraSizeChartCreator.scss';
import jsonData from '../types.json';
import { SelectedOpenSizeChartContext } from '../../context/SelectedOpenSizeChartContext';
import SizeChartCreatorHeader from '../SizeChartCreatorHeader/SizeChartCreatorHeader';
import SizeChartTable from '../SizeChartTable/SizeChartTable';
import ApiService from '../../services/ApiService';
import closeSizeChartCreatorImg from '../../assets/images/x-close-button.svg';
import { validate } from '../../validations/body-measurements/main';
import SizeChartCreatorError from '../SizeChartCreatorError/SizeChartCreatorError';
import AlertDialog from "../AlertDialog/AlertDialog";
import { isSizeChartChangedContext } from '../../context/isSizeChartChangedContext';
import {RetailerProfileContext} from '../../context/RetailerProfileContext';


function BraSizeChartCreator({ name, gender, measurementSystem, ageCategory, sizeChartType, setSelectedTypeCategory, selectedTypeCategory, setProductPairRender, approvalStatus, setIsChartSaveDialogIsOpen }) {
    const [chartName, setChartName] = useState(name);
    const [chartGender, setChartGender] = useState(gender);
    const [chartMeasurementSystem, setChartMeasurementSystem] = useState(measurementSystem);
    const [chartsizeChartType] = useState(sizeChartType);
    const [primaryMeasurementTypes, setPrimaryMeasurementTypes] = useState([]);
    const [openSelectedSizeChart, setOpenSelectedSizeChart] = useContext(SelectedOpenSizeChartContext);
    const [sizeMatrix, setSizeMatrix] = useState([]);
    const [chartAgeCategory, setChartAgeCategory] = useState(ageCategory);
    const [isDuplicateNameDialogOpen, setIsDuplicateNameDialogOpen] = useState(false);
    const [haveEmptyNamesDialog,setHaveEmptyNamesDialog] = useState(false);
    const [retailerProfile] = useContext(RetailerProfileContext);

    const [bandNames, setBandNames] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [currentErrorObj, setCurrentErrorObj] = useState({});
    const [canBeSubmited, setCanBeSubmited] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isChartChanged, setIsChartChanged] = useContext(isSizeChartChangedContext);


    useEffect(() => {
        setSizeChartMatrix(openSelectedSizeChart.sizes);
        setIsChartChanged(false);
   // eslint-disable-next-line
    },[]);


    const createMeasurementTypeArray = (sizes) => {
        let bandNames = [];
        let cupNames = [];
        sizes.forEach(size => {
            let tempArr;
            tempArr = splitSizeName(size.name);
            bandNames.push(tempArr[0]);
            cupNames.push(tempArr[1]);
        });
        bandNames = bandNames.filter(onlyUnique);
        cupNames = cupNames.filter(onlyUnique);
        setBandNames(bandNames);
        return { bandNames: bandNames, cupNames: cupNames }

    }

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const splitSizeName = (name) => {
        const DELIMITER = '/';
        return name.split(DELIMITER);
    }

    const buildMeasurementTypeArr = (cupNames, primaryMt) => {
        let arr = [];
        primaryMt.forEach(mt => {
            if (mt.name === 'under_bust' || mt.name === 'over_bust') {
                arr.unshift({ name: mt.name, enabled: true })
            }
            else {
                cupNames.forEach(cupName => {
                    arr.push({ name: mt.name, enabled: true, subName: cupName });
                });
            }
        });
        return arr;
    }

    const setSizeChartMatrix = (sizes) => {
        let bandNames, cupNames
        let primaryMeasurementTypes = "";
        let chartCategory = "";
        let shouldCreateDefaultData=false;
        let defaultRowsNum=3; //number of default rows to create
        let defaultBandsNum=3; //number of default bands to create
        jsonData.size_chart_type.forEach(type => {
            if (type.name === chartsizeChartType) {
                primaryMeasurementTypes = type.primary;
                chartCategory = type.category;
            }
        });

        setSelectedTypeCategory(chartCategory);
        let primaryMt = primaryMeasurementTypes.split(',').map(measurementTypeName => {
            return { name: measurementTypeName, enabled: true }
        });

        ({ bandNames, cupNames } = createMeasurementTypeArray(sizes));
        if(bandNames.length===0 && cupNames.length===0) shouldCreateDefaultData=true;
        if(shouldCreateDefaultData){
            bandNames=Array(defaultRowsNum).fill(""); //all band names are empty
            cupNames=Array(defaultBandsNum).fill(0).map((_, i) => String.fromCharCode(97+i) ); //"a","b","c"...
            setBandNames(bandNames);
        }        

        primaryMt = buildMeasurementTypeArr(cupNames, primaryMt);        
        setPrimaryMeasurementTypes(primaryMt);

        let reorderdMeasurementTypes = null
        let typeNames = primaryMt;
        if (sizes[0] !== undefined) {
            reorderdMeasurementTypes = primaryMt
        }
        else {
            reorderdMeasurementTypes = typeNames.map(typeName => {
                return typeName.name
            });
            setPrimaryMeasurementTypes(typeNames.map(x => {
                return { name: x.name, enabled: true }
            }));
        }
        var newArray = []
        for (let i = 0; i < sizes.length; i++) {
            for (let j = 0; j < reorderdMeasurementTypes.length; j++) {
                let sizeRowName = splitSizeName(sizes[i].name)[0];
                let sizeSubName = splitSizeName(sizes[i].name)[1];
                //handle the case of index=-1
                let typeNames = sizes[i].ranges.map(range => {
                    return range.measurement_type.name
                });
                let sizeRowIndex = bandNames.indexOf(sizeRowName);
                let measurementTypeIndex = typeNames.indexOf(reorderdMeasurementTypes[j].name);
                if (newArray[sizeRowIndex] === undefined)
                    newArray[sizeRowIndex] = [];
                if (reorderdMeasurementTypes[j].subName === sizeSubName || reorderdMeasurementTypes[j].subName === undefined) {
                    newArray[sizeRowIndex][j] = {
                        from: sizes[i].ranges[measurementTypeIndex]?.min_value,
                        to: sizes[i].ranges[measurementTypeIndex]?.max_value,
                        enabled: true,
                        measurementTypeName: reorderdMeasurementTypes[j].name,
                        sizeName: sizes[i].name
                    }
                }
            }
        }        
        if(shouldCreateDefaultData){
            newArray=[]
            const DELIMITER = '\u200B/';            
            for(let i=0;i<defaultRowsNum;i++){
                newArray[i]=[]
                let sNamePrefix = i + DELIMITER
                //doesn't matter what the under_bust size is
                newArray[i][0]={measurementTypeName: 'under_bust', sizeName: "", from: "", to: "", enabled: true}                
                for (let j=1;j<defaultBandsNum+1;j++)                    
                    newArray[i][j]={measurementTypeName: 'chestc', sizeName:  sNamePrefix+String.fromCharCode(97+j), from: "", to: "", enabled: true}                                
            }
        }
        setSizeMatrix(newArray);
    }


    const tofindDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
    

    
    

    const checkForDuplicateNames = () =>{
        let haveDuplicateNames = false
        let cupNamesTempArr = []
        primaryMeasurementTypes.forEach(mts =>{
            if(mts.subName !== undefined) cupNamesTempArr.push(mts.subName);
        });
        
        if(tofindDuplicates(bandNames).length > 0 || tofindDuplicates(cupNamesTempArr).length > 0){
            haveDuplicateNames = true;
        }
        
        return haveDuplicateNames
    }

    const checkForEmptyNames = () =>{

        let cupNamesTempArr = []
        primaryMeasurementTypes.forEach(mts =>{
            if(mts.subName !== undefined) cupNamesTempArr.push(mts.subName);
        });


        let empty = false
        if(bandNames.includes("")){
            empty = true;
        }
        if( cupNamesTempArr.includes("")){
            empty = true
        }
        
        return empty
    }


    const saveSizeChart = async () => {

        if(checkForEmptyNames()){
            setHaveEmptyNamesDialog(true);
            return
        }


        if(checkForDuplicateNames()){
            setIsDuplicateNameDialogOpen(true);
            return;
        }

        let mts = primaryMeasurementTypes;
        let sizes = []
        for (let i = 0; i < bandNames.length; i++)
            for (let j = 0; j < primaryMeasurementTypes.length; j++)
                if (mts[j].subName !== undefined) {
                    let ranges = []
                    let band = sizeMatrix[i][0];//is it always 0 or should be search for a type without a subName
                    let cup = sizeMatrix[i][j];
                    ranges.push(
                        { measurement_type_name: band.measurementTypeName, min_value: band.from, max_value: band.to },
                        { measurement_type_name: cup.measurementTypeName, min_value: cup.from, max_value: cup.to },
                    )
                    const DELIMITER = '\u200B/';
                    sizes.push({ name: bandNames[i] + DELIMITER + mts[j].subName, ranges });
                }

        let body = {
            version: 2,
            id: openSelectedSizeChart.id,
            gender: getGenderNumber(chartGender),
            measurement_system: getMeasurementSystemNumber(chartMeasurementSystem),
            name: chartName,
            size_chart_type: chartsizeChartType,
            sizes: sizes,
            age_category: getAgeNumber(chartAgeCategory).toLowerCase()
        };

        let errors = validate(body, sizeMatrix, chartMeasurementSystem, 'width');
        if (errors.length > 0) {
            setErrorList(errors);
            setCurrentErrorObj(errors[0]);
            setCanBeSubmited(false);
        }
        else {
            setErrorList([]);
            setCanBeSubmited(true);
            setCurrentErrorObj({});
        }
        setIsChartChanged(false);
        await new ApiService().updateSizeChartByID(openSelectedSizeChart.id, body);
        setProductPairRender(prev => !prev);

    }

    const submitSizeChart = async () => {
        if (canBeSubmited) {
            let body = { approval_status: "submitted" };
            await new ApiService().submmitSizeChart(openSelectedSizeChart.id, body);
            setIsDialogOpen(true)
        }
    }

    const checkIfToCloseModal = () => {
        if (isChartChanged) {
            setIsChartSaveDialogIsOpen(true);
        }
        else {
            setOpenSelectedSizeChart({});
        }
    }


    return (
        primaryMeasurementTypes.length !== 0 &&
        <div style={{top: retailerProfile.source === 'presta' && '30%'}} className='standart-size-chart-creator-wrapper'>
            <img onClick={() => { checkIfToCloseModal() }} className='x-img' src={closeSizeChartCreatorImg} alt={"check"} />
            <h1 className='basic-size-table-header'>Enter or Edit Body Measurements</h1>
            <p className='basic-size-table-header-desc'>Please complete the body measurements within the size chart to make it accurate for your store.</p>
            <SizeChartCreatorHeader gender={chartGender} chartName={chartName} selectedTypeCategory={selectedTypeCategory} chartAgeCategory={chartAgeCategory} setChartAgeCategory={setChartAgeCategory} setChartGender={setChartGender} setChartName={setChartName} measurementSystem={chartMeasurementSystem} setChartMeasurementSystem={setChartMeasurementSystem} approvalStatus={approvalStatus} chartsizeChartType={chartsizeChartType} />
            {(errorList.length !== 0 || canBeSubmited) && <SizeChartCreatorError errorList={errorList} currentError={currentErrorObj} setCurrentErrorObj={setCurrentErrorObj} errorSize={errorList.length} canBeSubmited={canBeSubmited} />}
            <SizeChartTable show={true} measurementTypes={primaryMeasurementTypes} setMeasurementTypes={setPrimaryMeasurementTypes} sizeNames={bandNames} sizeMatrix={sizeMatrix} setSizeMatrix={setSizeMatrix} setParentSizesName={setBandNames} isMutallyExcluded={false} isBra={true} errorCoordiante={currentErrorObj?.coordinate} />
            <div className='chart-btn-section'>
                <button onClick={() => saveSizeChart()} className='save-button'>Save</button>
                <button onClick={() => submitSizeChart()} style={{ background: !canBeSubmited && '#b2b2b2', color: !canBeSubmited && '#fffff', cursor: !canBeSubmited && 'unset' }} className='submit-button'>Submit</button>
            </div>
            {isDialogOpen && <AlertDialog
                setIsDialogOpen={() => setOpenSelectedSizeChart({})}
                header="Your size chart was submitted for review"
                description="We’ll send you an email when it’s been approved. This usually takes up to 48 hours."
                okButton='OK'
            />}
            {isDuplicateNameDialogOpen && <AlertDialog
                setIsDialogOpen={() => setIsDuplicateNameDialogOpen(false)}
                header="This chart has duplicate size names."
                description="Please make sure to give each measurement range a unique name"
                okButton='OK'
            />}
            {haveEmptyNamesDialog && <AlertDialog
                setIsDialogOpen={() => setHaveEmptyNamesDialog(false)}
                header="The size chart names are missing"
                description="The size chart names are not defined, please make sure values are updated before saving or leaving this tab"
                okButton='OK'
            />}
        </div>
    );
}


export default BraSizeChartCreator;
