// 3

const ranges = {
  imperial: {
    chestc: { from: 23, to: 78 },
    hipc: { from: 28, to: 80 },
    waistc: { from: 21, to: 60 },
    neck_base: { from: 12, to: 20 },
    neckc: { from: 12, to: 19 },
    arm_length: { from: 22, to: 30 },
    sleevecm: { from: 29, to: 39 },
    InseamBone: { from: 23, to: 28 },
    inseam: { from: 25, to: 41 },
    height: { from: 47, to: 87 },
    under_bust: { from: 24.4, to: 55.2 },
    headc: { from: 16.7, to: 25.5 },
    feet: { from: 7, to: 14 },
    waist_navel: { from: 20.8, to: 62.9 },
    waist_pants: { from: 20.8, to: 62.9 },
    over_bust: { from: 25.5, to: 59 },
    foot_width: {from: 2.8, to: 4.7},
    foot_length: {from: 7.8, to: 14},
    waistc_kids: {from: 15.7, to: 43.3},
    hipc_kids: {from: 15.7, to: 43.3},
    height_kids: {from: 15.7, to: 78.7},
    chestc_kids: {from: 15.7, to: 43.3},
    weight: {from: 2.20, to: 440.92}
  },

  metric: {
    chestc: { from: 60, to: 198 },
    hipc: { from: 71, to: 203 },
    waistc: { from: 53, to: 150 },
    neck_base: { from: 30, to: 50 },
    neckc: { from: 30, to: 48.2 },
    arm_length: { from: 55, to: 76 },
    sleevecm: { from: 73, to: 100 },
    InseamBone: { from: 58, to: 96 },
    inseam: { from: 63.5, to: 104 },
    height: { from: 120, to: 220 },
    under_bust: { from: 62, to: 140 },
    headc: { from: 42.5, to: 65 },
    feet: { from: 18, to: 35 },
    waist_navel: { from: 53, to: 160 },
    waist_pants: { from: 53, to: 160 },
    over_bust: { from: 56, to: 150 },
    foot_width: {from: 7, to: 12},
    foot_length: {from: 20, to: 36},
    waistc_kids: {from: 40, to: 110}, 
    hipc_kids: {from: 40, to: 110},
    height_kids: {from: 40, to: 200}, 
    chestc_kids: {from: 40, to: 110}, 
    weight: {from: 1, to: 200}
  },
};


const validateRanges = (sizeBox, unit) => { 
  const userFrom = parseFloat(sizeBox.from);
  const userTo = parseFloat(sizeBox.to);
  const measType = sizeBox.measurementTypeName;
  const properFrom = ranges[unit][measType].from;
  const properTo = ranges[unit][measType].to;
  return (
    parseFloat(properFrom) <= parseFloat(userFrom) &&
    parseFloat(userFrom) <= parseFloat(properTo) &&
    parseFloat(userTo) >= parseFloat(properFrom) &&
    parseFloat(userTo) <= parseFloat(properTo)
  );
};

const getMeasurementTypeName = (mtName) => {
  let correctType = mtName
  correctType = measurement_types.measurement_types.filter(mt => {
      return mtName === mt.name;
  });

  return correctType[0].display_name

}

const validate = (arr, unit,tableType) => {
  const errors = [];

  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[0].length; j++) {
      const element = arr[i][j];
      if(element.enabled === false ) continue;
      const valid = validateRanges(element, unit);
      if (!valid) {
        const message = `Are you sure about those numbers? ${
          getMeasurementTypeName(element.measurementTypeName)
          // element.measurementTypeName
        } measurements should be between ${
          ranges[unit][element.measurementTypeName].from
        } to ${ranges[unit][element.measurementTypeName].to}  ${unit}`;
        errors.push({ coordinate: [i, j], message, tableType });
      }
    }
  }

  return errors;
};

let measurement_types = {
"measurement_types": [
  {
    "name": "weight",
    "display_name": "Weight",
    "tooltip_thumbnail": {
      "url": ""
    },
    "tooltip_text": "Weight"
  }
  ,{
    "name": "waistc_kids",
    "display_name": "Waist Kids",
    "tooltip_thumbnail": {
      "url": ""
    },
    "tooltip_text": "Waist Kids"
  }
  ,{
    "name": "hipc_kids",
    "display_name": "Hip Kids",
    "tooltip_thumbnail": {
      "url": ""
    },
    "tooltip_text": "Hip Kids"
  }
  ,{
    "name": "height_kids",
    "display_name": "Height kids",
    "tooltip_thumbnail": {
      "url": ""
    },
    "tooltip_text": "Height kids"
  }
  ,{
    "name": "foot_width",
    "display_name": "Foot Width",
    "tooltip_thumbnail": {
      "url": ""
    },
    "tooltip_text": "Foot Width"
  }
  ,{
    "name": "arm",
    "display_name": "Arm",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/9/tip-sleeve-1.png"
    },
    "tooltip_text": "The ARM is measured..."
  },
  {
    "name": "arm_length",
    "display_name": "Arm Length",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/97/tip-arm-length_3x.png"
    },
    "tooltip_text": "Sleeve is measured from  the tip of the shoulder to the wrist, with a straight hand"
  },
  {
    "name": "calfc",
    "display_name": "Calf",
    "tooltip_thumbnail": {
      "url": null
    },
    "tooltip_text": ""
  },
  {
    "name": "chestc",
    "display_name": "Chest / Bust",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/5/tip-bust_3x.png"
    },
    "tooltip_text": "Chest is measured by your users around the widest part of their chest."
  },
  {
    "name": "chestc_kids",
    "display_name": "Chest Kids",

    "tooltip_thumbnail": {
      "url": null
    },
    "tooltip_text": ""
  },
  {
    "name": "chestc_test",
    "display_name": "chestc_test",

    "tooltip_thumbnail": {
      "url": null
    },
    "tooltip_text": ""
  },
  {
    "name": "foot_length",
    "display_name": "Foot Length",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/33/tip-feet_3x.png"
    },
    "tooltip_text": "Foot length is measured from your ankle to the toe"
  },
  {
    "name": "headc",
    "display_name": "Head circ",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/30/tip-head_3x.png"
    },
    "tooltip_text": "The maximum circumference of the head above the attachment of the ears passing just above the ridges of the eyebrows and around the back of the head"
  },
  {
    "name": "height",
    "display_name": "Body Height",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/1/height_3x.png"
    },
    "tooltip_text": "Height is measured from the floor to the top of the head."
  },
  {
    "name": "hipc",
    "display_name": "Hips",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/7/tip-hips.png"
    },
    "tooltip_text": "‘Hips’ are mesured by your users around the fullest part of their hips."
  },
  {
    "name": "inseam",
    "display_name": "Inseam",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/12/tip-inseam-1.png"
    },
    "tooltip_text": "Inseam is measured in a straight line parallel to the inner leg, from the crotch to the floor"
  },
  {
    "name": "InseamBone",
    "display_name": "Inseam Bone",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/94/tip-inseam-ankle_3x.png"
    },
    "tooltip_text": "Inseam is measured in a straight line parallel to the inner leg, from the crotch to the ankle bone"
  },
  {
    "name": "neck_base",
    "display_name": "Neck Base",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/98/tip-neck-base_3x.png"
    },
    "tooltip_text": "Neck is measured around the base part of the neck (widest part of the neck)"
  },
  {
    "name": "neckc",
    "display_name": "Neck",

    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/11/tip-neck.png"
    },
    "tooltip_text": "Neck is measured around the center part of the neck (around the Adam\\'s apple) in a straight line"
  },
  {
    "name": "outseam",
    "display_name": "Outseam",

    "tooltip_thumbnail": {
      "url": null
    },
    "tooltip_text": ""
  },
  {
    "name": "over_bust",
    "display_name": "Over Bust",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/89/tip-over-bust_3x.png"
    },
    "tooltip_text": "Overbust is measured directly above the chest and below the armpit"
  },
  {
    "name": "shoulderc",
    "display_name": "Shoulder",
    "tooltip_thumbnail": {
      "url": null
    },
    "tooltip_text": ""
  },
  {
    "name": "sleeve",
    "display_name": "Sleeve",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/10/tip-inseam-1.png"
    },
    "tooltip_text": "‘Sleeve’ is measured by your users from the inside part of their arm."
  },
  {
    "name": "sleevecm",
    "display_name": "Sleeve",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/86/tip-sleeve-shoulderx3.png"
    },
    "tooltip_text": "Sleeve is measured from the center of the back to the wrist, with the hand at a 90-degree angle"
  },
  {
    "name": "under_bust",
    "display_name": "Underbust",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/32/tip-under-bust_3x.png"
    },
    "tooltip_text": "Underbust is measured around the rib cage, directly under the bust"
  },
  {
    "name": "waistc",
    "display_name": "Waist",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/15/tip-waist.png"
    },
    "tooltip_text": "Waist is measured around the narrowest part of the waist."
  },
  {
    "name": "waist_navel",
    "display_name": "Waist Navel",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/95/tip-waist-navel_3x.png"
    },
    "tooltip_text": "The horizontal circumference of the waist at the level of the center of the navel."
  },
  {
    "name": "waist_pants",
    "display_name": "Waist Pants",
    "tooltip_thumbnail": {
      "url": "https://retailers-server-staging.s3.amazonaws.com/uploads/measurement_type/tooltip_thumbnail/96/tip-waist-pants_3x.png"
    },
    "tooltip_text": "Measure around the lower waistline where the trousers waistband would normally sit."
  }
]
}

exports.validate = validate;
