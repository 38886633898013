import React, {Component} from 'react';
// import {connect} from 'react-redux';
import TopBar from '../TopBar/TopBar';

import "./FAQ.scss"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
// import Profile from "./Profile";
// import {uiActions} from "../store/ui/actions";
// import {reporter, KEY_FAQ_QUESTION_CLICK} from '../helpers/reportManger';


class FAQ extends Component {
    // constructor(props) {
    //     super(props);
    // }
    openUserProfileModal = () => {
        // this.props.dispatch(uiActions.showProfileEdit(!this.props.showProfileEdit));
    };

    render() {
        return (
            <div className="faq-wrapper">
                 <div id="container">
                {/* <Profile
                    // show={this.props.showProfileEdit}
                    confirmCallback={this.createCategory}
                    // closeConfirmPrompt={() => this.openUserProfileModal()}
                    cancelText='Cancel'
                    confirmText='Create'
                    wrapperClass='confirm-delete'
                    title='New category'
                    description=''
                /> */}
                <TopBar/>
                <h1>FAQ</h1>
                <div className="wrepper">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    What is MySizeID?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID offers customers the best online shopping experience. Our widget can be
                                    embedded
                                    into any company’s existing or white label mobile app and begin taking accurate body
                                    measurements within minutes. Using our unique technology, a retailer can empower its
                                    customers to build a fit profile of their personal body measurements that will
                                    produce
                                    size
                                    recommendations for appropriate fitting apparel. When shoppers know their RIGHT
                                    size,
                                    they
                                    can be confident that they are purchasing items that will fit them accurately. This
                                    will
                                    increase customers loyalty and on the other hand decrease customers’ returns.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Which e-commerce platforms are supported by MySizeID?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID is supported by all major e-commerce platforms. Please contact our support
                                    team
                                    at <a href="mailto:support@mysizeid.com">support@mysizeid.com</a> for more information
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Where can I find the pricing plans?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    You can find MySizeID pricing information <a href="/plans">HERE</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    What does "size recommendation" means?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    “Size recommendation” is the recommended size offered when the customer engages with
                                    the
                                    MySizeID widget. It is counted only when the customer receives a size recommendation
                                    from the MySizeID widget.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    What happens when I reach my plan usage limit?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Every size recommendation above your plan limit is charged an additional fee, in
                                    accordance with the pricing plan you have chosen.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    I have a billing question – who should I contact?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    You can find MySizeID pricing information <a
                                    href="mailto:support@mysizeid.com">support@mysizeid.com</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Where can I download MySizeID mobile app?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID mobile app is available for IOS and Android <br/>
                                    <a href="https://itunes.apple.com/us/app/mysizeid-measure-perfect-fit/id1433748661?mt=8">IOS</a>
                                    <br/>
                                    <a href="https://play.google.com/store/apps/details?id=com.mysize.mysizeid&hl=en">ANDROID</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    What is the purpose of MySizeID mobile app?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID mobile app is used by your customers to take their accurate body
                                    measurement
                                    within minutes. The size recommendations our widget provide within your shop are
                                    based
                                    on the measurements from the app.We highly recommend that you download the app and
                                    take
                                    your own measurements so you could see how our widget fully performs in your shop.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    How do I get my brand to be published on MySizeID mobile app?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Please contact our support team for more information: <a
                                    href="mailto:support@mysizeid.com">support@mysizeid.com</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Can I add a link to download MySizeID app from my store?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Of course! We encourage adding our app link to your site, so your users can enjoy
                                    the
                                    benefits MySizeID offers. Please find below the MySizeID app links:<br/>
                                    <a href="https://itunes.apple.com/us/app/mysizeid-measure-perfect-fit/id1433748661?mt=8">IOS</a><br/>
                                    <a href="https://play.google.com/store/apps/details?id=com.mysize.mysizeid&hl=en">ANDROID</a><br/>
                                    In order for your customers to receive a size recommendation, they will have to
                                    download
                                    the MySizeID app, register and complete a few simple steps.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    How do I add MySizeID widget to my website?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID is supported by all major e-commerce platforms. You can find below
                                    implementation documentation for 2 of our major partners:Shopify
                                    walkthrough:Lightspeed
                                    walkthrough: Please contact our support team at support@mysizeid.com if you are using
                                    an
                                    alternative e-commerce platform.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    How do I add MySizeID widget to my mobile app?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    MySizeID SDK, the core technology behind the Company’s MySizeID smart body
                                    measurement
                                    application, can be embedded into any company’s existing or white label mobile app
                                    and
                                    begin taking accurate body measurements within minutes.Please contact our support
                                    team
                                    at <a href="mailto:support@mysizeid.com">support@mysizeid.com</a> for our SDK
                                    integration
                                    manual
                                    (available for IOS and Android).
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Why I can’t see MySizeID widget on my web page?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    For our widget to appear on the relevant item pages, you need to make sure that our
                                    code
                                    is implemented correctly (not relevant for Shopify & lightspeed users).
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    How can I change the widget location and styling?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    By Default, our widget is added to the bottom right corner of the product page. If
                                    you
                                    would like to change its location or styling, please contact our support team at
                                    <a href="mailto:support@mysizeid.com">support@mysizeid.com</a>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Which body measurements are relevant for tops?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    The most common body measurements which are relevant for tops size charts are Chest,
                                    Waist, Neck and sleeves.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Which body measurements are relevant for bottoms?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    The most common body measurements which are relevant for bottoms size charts are
                                    Hips,
                                    Waist and Inseam.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    How can I change the plan I'm using?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    You can change your plan you are using by clicking on “change plan” at the top right
                                    corner of your MySizeID dashboard.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    What measurement units are available?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    The available measurement units are metric and imperial
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Where can I find my plan usage status?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    You can find your plan usage status in your MySizeID dashboard.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Can I use MySizeID chart codes in multiple stores?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    No. The chart codes are only eligible for the store where they were created.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading >
                                <AccordionItemButton>
                                    Why doesn't the widget show a size recommendation?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    The widget can show size recommendations only if the customer has downloaded
                                    MySizeID
                                    mobile app, registered and completed the body measurement process (which takes only
                                    a
                                    few minutes). In addition, in case your size charts include more than one body
                                    dimension, make sure that the values of the dimensions are corresponding and do not
                                    contradict each other.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            </div>

           
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         pricing_plans: state.meta.pricing_plans,
//         showProfileEdit: state.ui.showProfileEdit,
//     };
// };
export default FAQ;