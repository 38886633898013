import React from 'react'
import "./WidgetAppearancePreview.scss"
import {ReactComponent as WidgetMyszWhiteIcon} from '../../assets/images/widget-mysz-white-icon.svg' 
import {ReactComponent as WidgetMyszRedIcon} from '../../assets/images/widget-mysz-red-icon.svg' 
import {ReactComponent as WidgetMyszBlackIcon} from '../../assets/images/widget-mysz-black-icon.svg'
import resetToDefaultButtonImage from '../../assets/images/reset_to_default_buttton_image.svg'
import {translateByLocale} from '../../utils/translations'



export default function WidgetPreview({fillColor, textColor, borderColor, logoColor, size, radius, language, setToDefaults, setWidgetSettingsType}) {

    const sizeMap = {
        "small": {width: "190px", height: "40px", fontSize: "14px"},
        "medium": {width: "226px", height: "52px", fontSize: "16px"},
        "large": {width: "300px", height: "55px", fontSize: "18px"}
    }

    const isRtlLocale = (locale) => {
        return locale === "he";
    }

    if (!size) {
        return null;
    }

    return (
        <div className="widget-preview-content">
            <div className="widget-preview-content-title">Widget Preview</div>
            <div className="widget-preview-outer-box">
                <div className="widget-preview-inner-box" style={{width: sizeMap[size].width, height: sizeMap[size].height, direction: isRtlLocale(language) ? 'rtl' : 'ltr', backgroundColor: `#${fillColor}`, borderColor: `#${borderColor}`, borderRadius: `${radius}px`}}>
                    {logoColor && <div className="widget-preview-logo">{getLogo(logoColor)}</div>}
                    <p className="widget-preview-text" style={{color: `#${textColor}`, fontSize: sizeMap[size].fontSize}}>{translateByLocale(language)}</p>
                </div>
            </div>
            <div className="widget-reset-to-default-button-section" onClick={() => setToDefaults()}>
                <img src={resetToDefaultButtonImage} />
                <div className="reset-to-default-button">Reset to default</div>
            </div>
        </div>
    )
}

const getLogo = (logoState) => {
    switch (logoState) {
        case "red": return <WidgetMyszRedIcon/>
        case "white": return <WidgetMyszWhiteIcon/>
        case "black": return <WidgetMyszBlackIcon/>
        default: return <WidgetMyszRedIcon/>
    }
}