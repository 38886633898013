import bra from "../assets/images/clothes-categories/bra.svg";
import dress_shirt from "../assets/images/clothes-categories/dress_shirt.svg";
import dress from "../assets/images/clothes-categories/dress.svg";
import hat from "../assets/images/clothes-categories/hat.svg";
import bottom from "../assets/images/clothes-categories/bottom.svg";
import overall from "../assets/images/clothes-categories/overall.svg";
import top from "../assets/images/clothes-categories/top.svg";
import sock from "../assets/images/clothes-categories/sock.svg";
import big_shoe from "../assets/images/clothes-categories/big-shoe.svg";
import boy from '../assets/images/clothes-categories/boy.svg'
import girl from '../assets/images/clothes-categories/girl.svg'
import baby from '../assets/images/clothes-categories/baby.svg'

const clothesNamesToImage = {
  bras: bra,
  "dress Shirts": dress_shirt,
  dresses: dress,
  hats: hat,
  jeans: bottom,
  bottoms: bottom,
  overalls: overall,
  tops: top,
  socks: sock,
  "shoes(EU)": big_shoe,
  "shoes(US)": big_shoe,
  "dresses | Overalls": dress,
  boys: boy,
  girls: girl,
  babies: baby
};


export default clothesNamesToImage;