import React from 'react'
import WidgetLogo from "../WidgetLogo/WidgetLogo";
import ColorPalette from "../ColorPalette/ColorPalette";
import WidgetAppearancePreview from "../WidgetAppearancePreview/WidgetAppearancePreview";
import WidgetBody from "../WidgetBody/WidgetBody";
import WidgetLanguage from '../WidgetLanguage/WidgetLanguage';
import './WidgetAppearanceSettings.scss';
import CircularIndeterminate from '../UI/CircularIndeterminate/CircularIndeterminate';
import GoToPopupSettingsButtonImage from '../../assets/images/widget-settings-go-to-advanced-settings-button-image.svg';
import {WIDGET_POPUP_SETTINGS_TYPE, WIDGET_PLACEMENT_SETTINGS_TYPE} from '../../utils/widget-settings-types.js';

export default function WidgetAppearanceSettings({
    fillColor, 
    setFillColor, 
    textColor, 
    setTextColor, 
    borderColor, 
    setBorderColor, 
    showLogo, 
    setShowLogo, 
    size, 
    radius, 
    language,
    onSizeChangeHandler,
    onRadiusChangeHandler,
    onLanguageChange, 
    resetToDefaultSettings,
    setWidgetSettingsType,
    onSaveHandler, 
    isWidgetSettingsChanged,
    saveLoader={saveLoader}
}) {

    return (
        <div className="widget-settings-body">
        <div className="widget-preferences">
          <ColorPalette
            fillColor={fillColor}
            setFillColor={setFillColor}
            borderColor={borderColor}
            setBorderColor={setBorderColor}
            textColor={textColor}
            setTextColor={setTextColor}
          />

            {/* <WidgetLogo showLogo={showLogo} setShowLogo={setShowLogo} /> */}
            <WidgetBody
              className="widget-settings-component"
              size={size}
              radius={radius}
              onSizeChangeHandler={onSizeChangeHandler}
              onRadiusChangeHandler={onRadiusChangeHandler}
            />
            <WidgetLanguage
              className="widget-settings-component"
              language={language} 
              onLanguageChange={onLanguageChange}
            />

            <div className="widget-settings-buttons-section">
                <div className="widget-settings-go-to-button" onClick={() => setWidgetSettingsType(WIDGET_POPUP_SETTINGS_TYPE)}>
                    <div className="widget-settings-go-to-button-text">Customize the widget’s pop-up</div>
                    <img src={GoToPopupSettingsButtonImage} />
                </div>
                <div className="widget-settings-go-to-button" onClick={() => setWidgetSettingsType(WIDGET_PLACEMENT_SETTINGS_TYPE)}>
                    <div className="widget-settings-go-to-button-text">Customize the widget’s placement</div>
                    <img src={GoToPopupSettingsButtonImage} />
                </div>
              <div className={`widget-settings-save-button ${!isWidgetSettingsChanged() ? "widget-settings-save-button--disabled" : ""}`} onClick={() => onSaveHandler()}>{saveLoader ? <CircularIndeterminate color="white" size="30px"/> : "Save"}</div>
            </div>
        </div>

        <div className="widget-preview">
          <WidgetAppearancePreview
            fillColor={fillColor}
            textColor={textColor}
            borderColor={borderColor}
            logoColor={showLogo}
            size={size}
            radius={radius}
            language={language}
            setToDefaults={resetToDefaultSettings}
            setWidgetSettingsType={setWidgetSettingsType}
          />
        </div>
      </div>
    )
}