export const getMeasurementSystemNumber = (measurementSystem) => {
    let measurementSystemNum = 0;
    if (measurementSystem === 'metric') measurementSystemNum = 0;
    else if (measurementSystem === 'imperial') measurementSystemNum = 1;
    return measurementSystemNum
}


export const getGenderNumber = (gender) => {
    let genderNum = 1;
    if (gender === 'male') genderNum = 0;
    else if (gender === 'female') genderNum = 1;
    else if (gender === 'unisex') genderNum = 2;
    return genderNum
}



export const getAgeNumber = (chartAgeCategory) => {
    let apiAgeCategory = chartAgeCategory;
    if(chartAgeCategory === 'toddlers'){
        apiAgeCategory = 'everyone'
    }
    return apiAgeCategory;
}


