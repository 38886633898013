import React, {useContext} from 'react';
import {SizeHolderMatrixContext} from '../../context/SizeHolderMatrixCotext';
import './SizeRangeBox.scss';
import NumberFormat from 'react-number-format';
import {isSizeChartChangedContext} from '../../context/isSizeChartChangedContext';


function SizeRangeBox({rowNumber, columnNumber, shouldMarkError}) {
    const [sizeHolderMatrix, setSizeHolderMatrix] = useContext(SizeHolderMatrixContext);
    const [, setIsChartChanged] = useContext(isSizeChartChangedContext);

    const copyMatrixArray = (row = null, column = null, type = null, value = null) => {
        
        if(sizeHolderMatrix.length !== 0) {
            var newArray = [];
            for (var i = 0; i < sizeHolderMatrix.length; i++) {
                newArray[i] = []
                for (var j = 0; j < sizeHolderMatrix[i].length; j++) {
                    if (i === row && column === j) {
                        if (type === 'min') {
                            let maxVal = sizeHolderMatrix[row][column] === undefined ? undefined : sizeHolderMatrix[rowNumber][columnNumber].to
                            newArray[row][column] = {...sizeHolderMatrix[row][column],from: value, to: maxVal}
                        } else {
                            let minVal = sizeHolderMatrix[row][column] === undefined ? undefined : sizeHolderMatrix[rowNumber][columnNumber].from
                            newArray[row][column] = {...sizeHolderMatrix[row][column],from: minVal, to: value}
                        }
                    } else {
                        newArray[i][j] = {...sizeHolderMatrix[i][j]}
                    }
                }
            }
            setSizeHolderMatrix(newArray);
        }
        setIsChartChanged(true);
    }


    const changeMinValue = (event) => {
        copyMatrixArray(rowNumber, columnNumber, 'min', event.target.value)
    }
    const changeMaxValue = (event) => {
        copyMatrixArray(rowNumber, columnNumber, 'max', event.target.value);
    }
    const MAX_VAL = 999.99;
    const withValueLimit = ({ floatValue }) => {return (floatValue <= MAX_VAL && floatValue > 0) || (floatValue === undefined) };
    return (

        <div className='size-range-box' row-data={rowNumber} column-data={columnNumber} style={{border: shouldMarkError ? 'solid 2px #f26167' : '' }} >
            <NumberFormat type='text' isAllowed={withValueLimit} onChange={(event) => changeMinValue(event)} disabled={!sizeHolderMatrix[rowNumber][columnNumber]?.enabled} placeholder='From'
                   value={sizeHolderMatrix[rowNumber][columnNumber]?.from}
                   className='size-range-min'/>
            <NumberFormat type='text' isAllowed={withValueLimit} onChange={(event) => changeMaxValue(event)} disabled={!sizeHolderMatrix[rowNumber][columnNumber]?.enabled} placeholder='To'
                   value={sizeHolderMatrix[rowNumber][columnNumber]?.to}
                   className='size-range-max'/>
        </div>
    );
}

export default SizeRangeBox;
