import React from 'react'
import infoImage from '../../assets/images/info_image.svg'
import './ShowGender.scss'

export default function ShowGender({hideGender, setHideGender, showGenderInfoBubble, setShowGenderInfoBubble}) {
    return (
        <div className="widget-gender-settings-header">
            <p className="widget-gender-settings-header-title">
                Show Gender?
                <img className="widget-gender-settings-header-title-info-image" 
                    src={infoImage} 
                    onMouseEnter={() => setShowGenderInfoBubble(true)}
                    onMouseLeave={() => setShowGenderInfoBubble(false)}/>
                {showGenderInfoBubble && <div className="widget-gender-settings-info-bubble">
                    We recommend showing the gender field if you sell both men's and women's clothing.
                </div>}
            </p>

            <label className="switch">
                <input type="checkbox" checked={!hideGender} onChange={()=> setHideGender(!hideGender)}/>
                <span className="slider round"></span>
            </label>
        </div>
    )
}
