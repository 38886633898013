import "./App.scss";
import Main from "../Main/Main";
import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import StartingFlow from "../StartingFlow/StartingFlow";
import { RetailerProfileContext } from '../../context/RetailerProfileContext';
import ApiService from '../../services/ApiService';
import Plans from "../../components/Plans/Plans";
import Account from "../../components/Account/Account";
import WidgetSettings from "../../components/WidgetSettings/WidgetSettings";
import FAQ from "../../components/FAQ/FAQ";
import WhatsNew from '../../components/WhatsNew/WhatsNew';
import SignUp from "../../components/SignUp/SignUp";
import SignIn from "../../components/SignIn/SignIn";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import Uninstall from '../../components/Uninstall/Uninstall';
import Welcome from "../../components/Welcome/Welcome";
import Mobile from "../../components/Mobile/Mobile";
import { TipsContext } from '../../context/TipsContext';
import GettingStarted from "../../components/GettingStarted/GettingStarted";
import BookMeeting from '../../components/BookMeeting/BookMeeting';
import SelectPlan from '../../components/SelectPlan/SelectPlan';
import loader from '../../assets/loader.svg';
import { LocalStorageService } from "../../services/LocalStorageService";
import { ErrorBoundary } from 'react-error-boundary';
import { config } from '../../config'
import HomeNewPage from "../../components/HomeNewPage/HomeNewPage";
import PreparingYourProfilePage from "../../components/PreparingYourProfilePage/PreparingYourProfilePage";
import RedirectionPage from '../../components/RedirectionPage/RedirectionPage';
import Uninstalled from "../../components/Uninstalled/Uninstalled";


function App() {

  const [firstRender, setFirstRender] = useState(false);
  const [retailerProfile, setRetailerProfile] = useState();
  const [tipsState, setTipsState] = useState({
    create_size_chart: false,
    select_size_chart: false,
    pair_size_chart: false,

  });

  // const firstUpdate = useRef(true);

  const location = useLocation();

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const gtm_tag = config.REACT_APP_GTM_TAG;

  useEffect(() => {
    // intercom
    // renderedFrom = location.state ? location.state.from : null;

    // if (firstUpdate.current) {
    // firstUpdate.current = false;
    // }
    setFirstRender(true);
    window.intercomSettings = {
      app_id: "thmu3cd0",
    };

    // check for first app visit for managing StartingFlow rendering state
    // if(!localStorage.getItem("getting_started")){
    //   localStorage.setItem("getting_started", true)
    // }


    fetchRetailerFromParams();
    // eslint-disable-next-line
  }, []);

  const fetchRetailerFromParams = async () => {
    let retailer_token = new URLSearchParams(location.search).get("token");

    let redirectURL = new URLSearchParams(location.search).get("redirect_url");
    if (redirectURL) {
      LocalStorageService.set('redirect', redirectURL);
    } else {
      LocalStorageService.remove('redirect');
    }

    if (retailer_token != null) {
      LocalStorageService.set('retailer_token', retailer_token);
    }

    let retailerData = null;
    await new ApiService().getRetailerProfile().then(data => {
      retailerData = data || {};
    });

    // if (retailerData) {
    await new ApiService().getRetailerMeta(LocalStorageService.get('retailer_token')).then(retailerMeta => {
      retailerData.meta = retailerMeta;
    })
    
    await new ApiService().getWidgetStyle(retailerData.shop_id).then(retailerWidget => {
      retailerData.widget = retailerWidget;
    })
    // }

    setRetailerProfile(retailerData);
    window.account = retailerData;

    if (retailerData?.size_chart_count === 0 && !LocalStorageService.get("added_size_chart")) {
      setTipsState(prevState => ({ ...prevState, create_size_chart: true }));
    }
  }

  const tipsHandler = (tipDropped) => {
    let newTipsState
    switch (tipDropped) {

      case "start_have_size_chart":
        newTipsState = { ...tipsState };
        newTipsState.select_size_chart = true;
        newTipsState.pair_size_chart = false;
        newTipsState.create_size_chart = false;
        setTipsState(newTipsState);
        break;
      case "start_no_size_chart":
        newTipsState = { ...tipsState };
        newTipsState.select_size_chart = false;
        newTipsState.pair_size_chart = false;
        newTipsState.create_size_chart = true;
        setTipsState(newTipsState);
        break;
      case "create_size_chart":
        newTipsState = { ...tipsState };
        newTipsState.select_size_chart = true;
        newTipsState.create_size_chart = false;
        setTipsState(newTipsState);
        break;
      case "select_size_chart":
        newTipsState = { ...tipsState };
        newTipsState.select_size_chart = false;
        newTipsState.pair_size_chart = true;
        setTipsState(newTipsState);
        break;
      case "pair_size_chart":
        setTipsState(prev => prev.pair_size_chart = false);
        break;
      default:
        break;
    }

  }



  const embedGTM = () => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', `${gtm_tag}`);
  }

  const versionNumber = LocalStorageService.get("whats_new_version")




  let disabledRoutes = [];
  switch (retailerProfile?.account_status) {
    case "uninstalled":
      disabledRoutes = ["account", "home", "main", "uninstall", "widget-settings", "signin", "signup", "forgot-password", "root"]
      break;

    default:
      break;
  }

  // const isMobile = window.matchMedia('screen and (max-width: 700px)').matches;

  //  if(localStorage.getItem("retailer_token") && retailerProfile // 👈 null and undefined check
  //  && Object.keys(retailerProfile).length === 0
  //  && Object.getPrototypeOf(retailerProfile) === Object.prototype){
  //   return null
  //  }

  const txErrorRef = useRef()
  // const rootRedirectTo = useRef()
  // const [rootRedirectTo, setRootRedirectTo] = useState()

  useEffect(() => {
    txErrorRef.current = {
      type: new URL(window.location.href).searchParams.get('transaction_type'),
      error: new URL(window.location.href).searchParams.get('transaction_error')
    }

    // window.history.replaceState(null, null, window.location.pathname)
  }, [])

  // useEffect(() => {
  //   if (!retailerProfile) return
  //   setRootRedirectTo(rootRedirect())
  // }, [retailerProfile])

  const rootRedirect = (hasPricingPlan = true) => {
    LocalStorageService.checkLocalStoragePlan(retailerProfile.pricing_plan?.id)
    // if (isMobile) return '/mobile'
    if (LocalStorageService.get("retailer_token")) {
      if (retailerProfile?.account_status === "uninstalled") return "/prepare-account"
    }

    if (!retailerProfile?.pricing_plan && (["external", "woocommerce", "shopify"].includes(retailerProfile?.source))) return "/prepare-account";

    if (Object.keys(retailerProfile).length !== 0 && retailerProfile.code === undefined) {
      if (retailerProfile?.account_status === "uninstalled") return "/prepare-account"
      if (txErrorRef.current.type === 'error' || txErrorRef.current.type === 'cancel') return "/account"
    }
    if (retailerProfile.code !== 401 && hasPricingPlan)
      return '/main';
    return '/welcome';
  }


  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  const myErrorHandler = (error, info) => {
    // Do something with the error
    // E.g. log to an error logging client here
  }

  const mainElement = (props) => {
    return Object.keys(retailerProfile).length !== 0 && retailerProfile.code === undefined
      ?
      <RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
        {/* {versionNumber !== "2.0" && retailerProfile.size_chart_count > 0 && !isSafari && <WhatsNew />} */}
        <Main txErrorRef={txErrorRef} />
      </RetailerProfileContext.Provider>
      : <Navigate to='/welcome' replace />
    // : <Navigate to='/' replace />
  }

  const accountElement = (props) => {
    return retailerProfile.code !== 401 ?
      <RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
        <Account txErrorRef={txErrorRef} />
      </RetailerProfileContext.Provider>
      : <Navigate to='/' replace />
  }

  return retailerProfile ? (
    <div style={{ background: "#F7F7F7", minHeight: "93vh", maxHeight: "100vh", overflow: "auto", marginTop: ['shopify', 'presta', 'wix', 'magento'].includes(retailerProfile?.source) ? '10px' : "45px" }}>
      {/* START OF TAG MANAGER */}
      <script>
        {embedGTM()}
      </script>
      <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${gtm_tag}`} title="google tag manager" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
      {/* END OF TAG MANAGER */}
      {/* ROUTS  */}


      {/* {isMobile &&
        <Router>
          <Route path="/mobile">
            <Mobile />
          </Route>
          <Redirect to="/mobile" />
        </Router>

      } */}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          alert('reset')
        }}
      >

        {(retailerProfile?.id && retailerProfile?.pricing_plan) ?
          <TipsContext.Provider value={[tipsState, tipsHandler]}>
            <Routes>

              <Route path="/" exact element={<Navigate to={rootRedirect()} />} />

              <Route path="*" element={<Navigate to="/" replace />} />

              <Route path="/account" exact element={accountElement()}>
              </Route>

              <Route path="/widget-settings" exact element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <WidgetSettings />
              </RetailerProfileContext.Provider>} />

              <Route path="/faq" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <FAQ />
              </RetailerProfileContext.Provider>} />

              <Route path="/credit-card" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <RedirectionPage />
              </RetailerProfileContext.Provider>} />

              <Route path="/main" exact element={mainElement()} />
            </Routes>
          </TipsContext.Provider>

          :

          <TipsContext.Provider value={[tipsState, tipsHandler]}>
            <Routes>
              <Route path="/welcome" exact element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <HomeNewPage />
              </RetailerProfileContext.Provider>} />

              <Route path="/book-meeting" exact element={<BookMeeting />} />
              <Route path="/prepare-account" exact element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <PreparingYourProfilePage />
              </RetailerProfileContext.Provider>} />
              <Route path="/signup" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <SignUp />
              </RetailerProfileContext.Provider>} />

              <Route path="/signin" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <SignIn />
              </RetailerProfileContext.Provider>} />

              {!LocalStorageService.get("retailer_token") && <Route path="/forgot-password" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <ForgotPassword />
              </RetailerProfileContext.Provider>} />}

              <Route path="/uninstall" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <Uninstall />
              </RetailerProfileContext.Provider>} />

              <Route path="/uninstalled" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <Uninstalled />
              </RetailerProfileContext.Provider>} />

              <Route path="/credit-card" element={<RetailerProfileContext.Provider value={[retailerProfile, setRetailerProfile]}>
                <RedirectionPage />
              </RetailerProfileContext.Provider>} />

              <Route path="/" exact element={<Navigate to={rootRedirect(false)} />} />

              {/* All routes except the above navigates to path='/' */}
              <Route path="/main" element={<Navigate to="/" replace />} />
              <Route path="/faq" element={<Navigate to="/" replace />} />
              <Route path="/widget-settings" element={<Navigate to="/" replace />} />
              <Route path="/account" element={<Navigate to="/" replace />} />

            </Routes>
          </TipsContext.Provider>

        }
      </ErrorBoundary>
    </div>

  ) : <div className="loader"><img src={loader} alt="loader" /></div>;
}


const DebugRouter = ({ children }) => {
  const { location } = useNavigate()
  console.log(
    `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
  )

  return children
}


export default App;