import React, { useState, useContext } from "react";
import "./SizeChartListWrapper.scss";
import editIcon from "../../assets/images/edit-icon.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
// import loader from "../../assets/loader.svg";
import { CircularProgress } from "@material-ui/core";
import { SelectedSizeChartContext } from "../../context/SelectedSizeChartContext";
import ApiService from "../../services/ApiService";
import AlertDialog from "../AlertDialog/AlertDialog";
import AlertDialogLock from "../AlertDialogLock/AlertDialogLock";
import { SelectedOpenSizeChartContext } from '../../context/SelectedOpenSizeChartContext';
import jsonData from '../types';

import topImg from '../../assets/images/clothes-categories/sc-top.svg';
import bottomImg from '../../assets/images/clothes-categories/sc-bottom.svg';
import dressImg from '../../assets/images/clothes-categories/sc-dress.svg';
import dressShirtImg from '../../assets/images/clothes-categories/sc-dress-shirt.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/copy-icon.svg';
import braImg from '../../assets/images/clothes-categories/sc-bra.svg';
import hatImg from '../../assets/images/clothes-categories/sc-hat.svg';
import sockImg from '../../assets/images/clothes-categories/sc-sock.svg';
import overallImg from '../../assets/images/clothes-categories/sc-overall.svg';
import shoeImage from '../../assets/images/clothes-categories/sc-shoe.svg';
import hangerImg from '../../assets/images/clothes-categories/sc-undefind.svg';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import "./SizeChartItem.scss";
import { TipsContext } from "../../context/TipsContext";





function SizeChartItem({ isLockedByMysize, elRefIntegrationCode, id, name, gender, ageCategory, sizeChartType, measurementSystem, setGenericChartListUpdater, integration_code }) {
    const [selectedSizeChart, setSelectedSizeChart] = useContext(SelectedSizeChartContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isScreenFreeze, setIsScreenFreeze] = useState(false);
    const [sizeChartIdToDelete, setSizeChartIdToDelete] = useState(null);
    const [, setOpenSelectedSizeChart] = useContext(SelectedOpenSizeChartContext);
    const [tipsState, tipsHandler] = useContext(TipsContext);


    const [retailerProfile] = useContext(
        RetailerProfileContext
    );

    const capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const setSelectedChart = (id, name) => {
        if (checkIfObjEmpty(selectedSizeChart))
            setSelectedSizeChart({ id: id, name: name });
        else if (selectedSizeChart.id === id) setSelectedSizeChart({});
        else setSelectedSizeChart({ id: id, name: name });
        tipsState.select_size_chart && !selectedSizeChart.id && tipsHandler("select_size_chart")
    };

    const checkIfObjEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const openDeleteSizeChartDialog = (id) => {
        setIsDialogOpen(true);
        setSizeChartIdToDelete(id);
    };

    const handleSizeChartDelete = async (isAgree) => {
        setIsDialogOpen(false);
        if (isAgree) {
            setIsScreenFreeze(true);
            await new ApiService().deleteSelectedSizeChart(sizeChartIdToDelete);
            // update list after deleting
            setGenericChartListUpdater(prev => prev + "1");
            setTimeout(() => {
                setIsScreenFreeze(false);
            },600)
        }
        setSizeChartIdToDelete(null);
    };

    const GoToEditSizeChart = (id) => {
        new ApiService().getSizeChartByID(id).then((data) => {
            setOpenSelectedSizeChart(data);
        });
    }

    const TypeCategoryPicker = (type_name) => {
        let cateogry = ""
        jsonData.size_chart_type.forEach(type => {
            if (type_name === type.name) cateogry = type.category
        });
        return cateogry;
    }

    const getTypeImg = () => {
        if (sizeChartType === undefined) return hangerImg;
        if (sizeChartType.includes('top')) return topImg;
        else if (sizeChartType.includes('bottom')) return bottomImg;
        else if (sizeChartType.includes('overall')) return overallImg;
        else if (sizeChartType.includes('bra')) return braImg;
        else if (sizeChartType.includes('dress_shirt')) return dressShirtImg;
        else if (sizeChartType.includes('sock')) return sockImg;
        else if (sizeChartType.includes('hat')) return hatImg;
        else if (sizeChartType.includes('dress')) return dressImg;
        else if (sizeChartType.includes('shoe')) return shoeImage;
        else if (sizeChartType.includes('undefined')) return hangerImg;
        else return hangerImg

    }



    const ageGenderCategory = () => {

        switch (true) {
            case (ageCategory === "adults" && gender === "male"):
                return "Men"
            case (ageCategory === "adults" && gender === "female"):
                return "Women"
            case (ageCategory === "kids" && gender === "male"):
                return "Boys"
            case (ageCategory === "kids" && gender === "female"):
                return "Girls"
            case (gender === "unisex"):
                return "Unisex"
            default:
                return "Undefined"

        }
    }

    const isSourceWithOutProductsPair = () => {
        return retailerProfile.source === "external" || retailerProfile.source === "woocommerce" || retailerProfile.source === 'bitrix' || retailerProfile.source === 'plugin' || retailerProfile.source === 'lightspeed'
    }



    return (
        <div>
            <div
                className={`size-chart-item size-chart-item-card ${isSourceWithOutProductsPair() ? "size-chart-item-external" : ""}`}
                style={{
                    border: selectedSizeChart.id === id ? "2px solid #f8686e" : "",
                }}
                onClick={()=>isSourceWithOutProductsPair() ? GoToEditSizeChart(id) : setSelectedChart(id, name)}
            >
                <img className="type-image" src={getTypeImg()} alt="type img" />



                <div className="seperator"></div>


                <div className={`chart-info-section ${isSourceWithOutProductsPair() && "reverse"}`}>


                    <p>{`${ageGenderCategory()} / ${capitalize(measurementSystem)} / ${capitalize(TypeCategoryPicker(sizeChartType))}`}</p>
                    <h1 style={{ fontSize: '15px', textAlign: "left", marginTop: '4px' }}>{capitalize(name)}</h1>
                </div>



                {
                    !isSourceWithOutProductsPair() &&
                    <img
                        onClick={() => GoToEditSizeChart(id)}
                        className="edit-icon"
                        src={editIcon}
                        alt="edit icon"
                    />
                }

                {isSourceWithOutProductsPair() &&

                    <div className="integration-code-container" onClick={(e) => e.stopPropagation()}>
                        {/* <CopyIcon /> */}

                        <div className="integration-code-wrapper" ref={elRefIntegrationCode}>
                            {integration_code}
                        </div>
                    </div>
                }

                <img
                    onClick={(e) => {
                        e.stopPropagation();
                        openDeleteSizeChartDialog(id);
                    }}
                    className="delete-icon"
                    src={deleteIcon}
                    alt="delete icon"
                />



            </div>
            {isDialogOpen ? (
                isLockedByMysize
                    ?
                    <AlertDialogLock
                        setIsDialogOpen={handleSizeChartDelete}
                    />
                    :
                    <AlertDialog
                        setIsDialogOpen={handleSizeChartDelete}
                        header="Delete Size Chart?"
                        description="Are you sure that you want the delete the selected size chart?"
                        okButton='Yes'
                        cancelButton='No'
                        style={{ padding: "15px" }}
                    />
            ) : null}
            {isScreenFreeze && <div className="screen">
                    {/* <img src={loader} alt='Loader'/> */}
                    <CircularProgress style={{ color: '#F8686E' }} />
                </div>}
        </div>
    );
}

export default SizeChartItem;
