export function uploadS3(url, file, progressCB){
    return new Promise((resolve,reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState===4) {
                if (xhr.status===200) {
                    resolve(xhr);
                } else {
                    reject(xhr);
                }
            }
        };

        if (progressCB) {
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const precentComplete = ((e.loaded/file.size)*100).toFixed(0);
                    progressCB(precentComplete)
                }
            }
        }

        xhr.open("PUT", url);
        xhr.send(file);
        
    });
}