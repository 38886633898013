import React, { useState, useEffect, useContext} from 'react';
import { getGenderNumber, getMeasurementSystemNumber, getAgeNumber } from '../../utils/sizeChartFunction';
import './JeansSizeChartCreator.scss';
import { SelectedOpenSizeChartContext } from '../../context/SelectedOpenSizeChartContext';
import jsonData from '../types.json';
import ApiService from '../../services/ApiService';
import SizeChartCreatorHeader from '../SizeChartCreatorHeader/SizeChartCreatorHeader';
import SizeChartTable from '../SizeChartTable/SizeChartTable';
import SizeMatrix from './SizeMatrix/SizeMatrix';
import closeSizeChartCreatorImg from '../../assets/images/x-close-button.svg';
import { validate } from '../../validations/body-measurements/main';
import SizeChartCreatorError from '../SizeChartCreatorError/SizeChartCreatorError';
import AlertDialog from "../AlertDialog/AlertDialog";
import { isSizeChartChangedContext } from '../../context/isSizeChartChangedContext';
import {RetailerProfileContext} from '../../context/RetailerProfileContext';


function JeansSizeChartCreator({ name, gender, measurementSystem, ageCategory, sizeChartType, setSelectedTypeCategory, selectedTypeCategory, setProductPairRender, approvalStatus, setIsChartSaveDialogIsOpen }) {
    const [enabledArray, setEnabledArray] = useState({});
    const [openTab, setOpenTab] = useState('width')
    const [chartName, setChartName] = useState(name);
    const [chartGender, setChartGender] = useState(gender);
    const [chartMeasurementSystem, setChartMeasurementSystem] = useState(measurementSystem);
    const [chartsizeChartType] = useState(sizeChartType);
    const [primaryMeasurementTypes, setPrimaryMeasurementTypes] = useState([]);
    const [secondaryMeasurementTypes, setSecondaryMeasurementTypes] = useState([]);
    const [openSelectedSizeChart, setOpenSelectedSizeChart] = useContext(SelectedOpenSizeChartContext);
    const [chartAgeCategory, setChartAgeCategory] = useState(ageCategory);
    const EMPTY_CHAR = '\u200B';
    const DELIMITER = '/';


    const [errorList, setErrorList] = useState([]);
    const [currentErrorObj, setCurrentErrorObj] = useState({});
    const [canBeSubmited, setCanBeSubmited] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isChartChanged, setIsChartChanged] = useContext(isSizeChartChangedContext);
    const [isDuplicateNameDialogOpen, setIsDuplicateNameDialogOpen] = useState(false);
    const [haveEmptyNamesDialog,setHaveEmptyNamesDialog] = useState(false);
    const [retailerProfile] = useContext(RetailerProfileContext);



    const [widthSizesNames, setWidthSizesNames] = useState([]);
    const [lengthSizesNames, setLengthSizesNames] = useState([]);

    const [widthSizeMatrix, setWidthSizeMatrix] = useState([]);
    const [lengthSizeMatrix, setLengthSizeMatrix] = useState([]);
    const [sizeChartMatrix, setSizeChartMatrix] = useState([]);

    useEffect(() => {
        debugMatrix();
        // eslint-disable-next-line
    }, [sizeChartMatrix]);

    useEffect(() => {

        // Build enabled-matrix from hash        
        let matrixArray = [];
        for (let i = 0; i < lengthSizesNames.length; i++) {
            matrixArray[i] = []
            for (let j = 0; j < widthSizesNames.length; j++) {
                matrixArray[i][j] = enabledArray[sizeName(widthSizesNames[j], lengthSizesNames[i])];                
            }
        }

        setSizeChartMatrix(matrixArray);

        // eslint-disable-next-line
    }, [enabledArray]);//[widthSizesNames, lengthSizesNames]);

    useEffect(() => {
        if (widthSizesNames.length === 0)
            return;
        // Build enabled - hash from sizechart        
        let temp = { ...enabledArray };
        //run this only once!
        if (Object.keys(temp).length === 0) {
            for (let i = 0; i < widthSizesNames.length; i++) {
                for (let j = 0; j < lengthSizesNames.length; j++) {
                    let index = j * widthSizesNames.length + i;
                    if (temp[sizeName(widthSizesNames[i], lengthSizesNames[j])] === undefined) {
                        let allMinus = openSelectedSizeChart.sizes[index]===undefined || Math.max(...openSelectedSizeChart.sizes[index].ranges.map(x => x.min_value)) < 0;
                        temp[sizeName(widthSizesNames[i], lengthSizesNames[j])] = !allMinus;
                    }
                }
            }
        }
        else {            
            for (let i = 0; i < widthSizesNames.length; i++) {
                for (let j = 0; j < lengthSizesNames.length; j++) {
                    if (temp[sizeName(widthSizesNames[i], lengthSizesNames[j])] === undefined) {
                        temp[sizeName(widthSizesNames[i], lengthSizesNames[j])] = true;              
                    }
                }
            }
        }        
        setEnabledArray({ ...temp })

        // eslint-disable-next-line
    }, [widthSizesNames, lengthSizesNames]);

    useEffect(() => {
        let primary, secondary, typeCategory, widthNames, lengthNames;
        ({ primary, secondary, typeCategory } = checkMeasurementTypeBySizeChartType(sizeChartType));
        ({ widthNames, lengthNames } = getSizesNames());


        let widthArr, lengthArr;
 
        widthArr = buildSizesMatrix(widthNames, primary, 0)
        lengthArr = buildSizesMatrix(lengthNames, secondary, 1);

        //push empty values if the sizechart is empty
        if(widthArr.length===0 && lengthArr.length===0){                   
            const emptyRows = 3; //number of empty rows to add.
            for(let i=0;i<emptyRows;i++){
                widthArr[i] = [];
                lengthArr[i] =[];
                widthNames.push("");
                lengthNames.push("");
                for(let j=0;j<primary.length;j++)
                    widthArr[i][j]={measurementTypeName: primary[j].name, sizeName: "", from: "", to: "", enabled: true}
                    for(let j=0;j<secondary.length;j++) //enable only the first value
                    lengthArr[i][j]={measurementTypeName: secondary[j].name, sizeName: "", from: "", to: "", enabled: j === 0}                    
            }            
        }
        primary = disableMeasurementTypes(primary, widthArr);
        secondary = disableMeasurementTypes(secondary, lengthArr, true);

        primary = reorderMeasurementTypes(null, null, primary);
        secondary = reorderMeasurementTypes(null, null, secondary);
        widthArr = syncSizeMatrixToMeasurementTypes(primary, widthArr)
        lengthArr = syncSizeMatrixToMeasurementTypes(secondary, lengthArr)

        setWidthSizeMatrix(widthArr);
        setLengthSizeMatrix(lengthArr);

        setPrimaryMeasurementTypes(primary);
        setSecondaryMeasurementTypes(secondary);
        setSelectedTypeCategory(typeCategory);        
        setWidthSizesNames(widthNames);
        setLengthSizesNames(lengthNames);
        setIsChartChanged(false);

        // setMatrix(widthNames,lengthNames);
        // eslint-disable-next-line
    }, []);

    const sizeName = (width, length) => {
        return `${width}${EMPTY_CHAR}${DELIMITER}${length}`
    }
    //////////STUFF IN ROW HEADER - MOVE THIS TO EXTERNAL MODULE - DUPLICATES STUFF IN SIZE ROW HEADER
    const reorderMeasurementTypes = (index = null, checked = null, mtArr) => {
        let disabled = [], enabled = [];
        for (let j = 0; j < mtArr.length; j++) {
            if (j !== index) {
                if (mtArr[j].enabled === undefined || !mtArr[j].enabled)
                    disabled.push({ ...mtArr[j] })
                else
                    enabled.push({ ...mtArr[j] })
            }
        }
        if (checked !== null) {
            if (checked)
                enabled.push({ ...mtArr[index], enabled: checked })
            else
                disabled.push({ ...mtArr[index], enabled: checked })
        }

        let newColumnHeaders = []
        for (let j = 0; j < enabled.length; j++)
            newColumnHeaders.push(enabled[j]);
        for (let j = 0; j < disabled.length; j++)
            newColumnHeaders.push(disabled[j]);

        return newColumnHeaders;
    }
    const syncSizeMatrixToMeasurementTypes = (newColumnHeaders, oldSizeMatrix) => {
        let newSizeMatrix = [];
        for (let i = 0; i < oldSizeMatrix.length; i++) {
            newSizeMatrix[i] = []
            for (let j = 0; j < oldSizeMatrix[i].length; j++) {
                let measurementTypesNames = newColumnHeaders.map(x => x.name)
                const newIndex = measurementTypesNames.indexOf(oldSizeMatrix[i][j].measurementTypeName);
                if (newIndex > -1) {
                    newSizeMatrix[i][newIndex] = { ...oldSizeMatrix[i][j], enabled: newColumnHeaders[newIndex].enabled }
                }
            }
        }
        return newSizeMatrix;
    }
    //////////


    const buildSizesMatrix = (sizeArr, mtArr, splitIdex) => {        
        mtArr = mtArr.map(mt => {
            return mt.name
        });
        let resultArr = [];
        for (let i = 0; i < openSelectedSizeChart.sizes.length; i++) {
            let size = openSelectedSizeChart.sizes[i];
            let sizeName = size.name.split(DELIMITER)[splitIdex];
            let row = sizeArr.indexOf(sizeName);
            if (resultArr[row] === undefined)
                resultArr[row] = []
            let ranges = size.ranges;
            for (let j = 0; j < ranges.length; j++) {
                let col = mtArr.indexOf(ranges[j].measurement_type.name);
                if (col > -1) {
                    resultArr[row][col] = {
                        measurementTypeName: ranges[j].measurement_type.name,
                        sizeName: sizeName, from: Math.abs(ranges[j].min_value).toString(), to: Math.abs(ranges[j].max_value).toString(), enabled: true//(ranges[j].min_value>=0 || ranges[j].max_value>=0)
                    }
                    for (let k = 0; k < mtArr.length; k++) {
                        // move this to the begining of the function
                        if (resultArr[row][k] === undefined) {
                            resultArr[row][k] = {
                                measurementTypeName: mtArr[k],
                                sizeName: sizeName, from: '', to: '', enabled: false
                            }
                        }

                    }
                }
            }
        }                                
        return resultArr;
    }

    const disableMeasurementTypes = (measurementTypes, sizeMatrix, isLengthSize = false) => {
        if (isLengthSize) {
            let disabledArr = measurementTypes.filter(mt => {
                return mt.enabled === false
            });
            if (disabledArr.length === 0) {
                measurementTypes[0].enabled = false
            }
        }

        for (let i = 0; i < measurementTypes.length; i++) {
            var found = false
            if (sizeMatrix.length === 0) {
                return measurementTypes
            }
            for (let j = 0; j < sizeMatrix[0].length; j++) {
                if (sizeMatrix[0][j].measurementTypeName === measurementTypes[i].name && sizeMatrix[0][j].enabled)
                    found = true;
            }
            measurementTypes[i].enabled = found;
        }
        return measurementTypes
    }


    const getSizesNames = () => {
        let widthSizeNames = [];
        let lengthSizeNames = [];
        openSelectedSizeChart.sizes.forEach(size => {
            let arrName = size.name.split(DELIMITER);
            widthSizeNames.push(arrName[0]);
            lengthSizeNames.push(arrName[1]);
        });

        var widthUnique = widthSizeNames.filter(onlyUnique);
        var lengthUnique = lengthSizeNames.filter(onlyUnique);
        return { widthNames: widthUnique, lengthNames: lengthUnique }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const tofindDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    const checkForDuplicateNames = () =>{
        let width,length = false
        if(tofindDuplicates(widthSizesNames).length > 0 || widthSizesNames.includes("")){
               width = true;
        }
        if(tofindDuplicates(lengthSizesNames).length > 0 || lengthSizesNames.includes("")){
            length = true
        }
        
        if(width && length) setOpenTab('width')
        else if(width) setOpenTab('width')
        else if(length) setOpenTab('length')
        
        return width || length
        
        // isDuplicateNameDialogOpen
    }

    const checkForEmptyNames = () =>{
        let width,length = false
        if(widthSizesNames.includes("")){
               width = true;
        }
        if(lengthSizesNames.includes("")){
            length = true
        }
        
        if(width && length) setOpenTab('width')
        else if(width) setOpenTab('width')
        else if(length) setOpenTab('length')
        
        return width || length
    }

    

    const saveSizeChart = async () => {
        if(checkForEmptyNames()){
            setHaveEmptyNamesDialog(true);
            return
        }
        if(checkForDuplicateNames()){
            setIsDuplicateNameDialogOpen(true);
            return;
        }

        let body = {
            version: 2,
            id: openSelectedSizeChart.id,
            gender: getGenderNumber(chartGender),
            measurement_system: getMeasurementSystemNumber(chartMeasurementSystem),
            name: chartName,
            size_chart_type: chartsizeChartType,
            sizes: buildSizesObject(),
            age_category: getAgeNumber(chartAgeCategory).toLowerCase()
        };

        let errors = [...validate(body, widthSizeMatrix, chartMeasurementSystem, 'width'), ...validate(body, lengthSizeMatrix, chartMeasurementSystem, 'length')]
        if (errors.length > 0) {
            setOpenTab(errors[0].tableType)
            setErrorList(errors);
            setCurrentErrorObj(errors[0]);
            setCanBeSubmited(false);
        }
        else {
            setErrorList([]);
            setCanBeSubmited(true);
            setCurrentErrorObj({});
        }

        setIsChartChanged(false)
        await new ApiService().updateSizeChartByID(openSelectedSizeChart.id, body);
        setProductPairRender(prev => !prev);
    }

    const buildSizesObject = () => {
        let sizesArray = []
        lengthSizesNames.forEach((lengtName, lengthIndex) => {
            widthSizesNames.forEach((widthName, widthIndex) => {
                sizesArray.push({ name: sizeName(widthName, lengtName), ranges: getRanges(lengthIndex, widthIndex) });
            });
        });
        return sizesArray;


    }

    const getRanges = (lengthIndex, widthIndex) => {
        let shouldDisableSize = false;
        if (!sizeChartMatrix[lengthIndex][widthIndex]) shouldDisableSize = true;
        let rangesArray = [];
        primaryMeasurementTypes.forEach((mt, index) => {
            if (mt.enabled) {
                rangesArray.push({
                    measurement_type_name: mt.name,
                    min_value: shouldDisableSize ? addMinus(widthSizeMatrix[widthIndex][index].from) : Math.abs(widthSizeMatrix[widthIndex][index].from),
                    max_value: shouldDisableSize ? addMinus(widthSizeMatrix[widthIndex][index].to) : Math.abs(widthSizeMatrix[widthIndex][index].to)
                });
            }
        });
        secondaryMeasurementTypes.forEach((mt, index) => {
            if (mt.enabled) {
                rangesArray.push({
                    measurement_type_name: mt.name,
                    min_value: shouldDisableSize ? addMinus(lengthSizeMatrix[lengthIndex][index].from) : Math.abs(lengthSizeMatrix[lengthIndex][index].from),
                    max_value: shouldDisableSize ? addMinus(lengthSizeMatrix[lengthIndex][index].to) : Math.abs(lengthSizeMatrix[lengthIndex][index].to)
                });
            }
        });

        return rangesArray;
    }


    const addMinus = (number) => {
        if (number === "" || number === 0) return 0;
        else if (number.includes("-")) return number;
        else return number * -1
    }

    const checkMeasurementTypeBySizeChartType = (chartsizeChartType) => {
        let primaryMeasurementTypes = "";
        let secondaryMeasurementTypes = "";
        let chartCategory = "";
        jsonData.size_chart_type.forEach(type => {
            if (type.name === chartsizeChartType) {
                primaryMeasurementTypes = type.primary;
                secondaryMeasurementTypes = type.secondary;
                chartCategory = type.category;
            }
        });


        let primarayTypes = primaryMeasurementTypes.split(',').map(measurementTypeName => {
            return { name: measurementTypeName, enabled: true }
        })

        let secondaryTypes = secondaryMeasurementTypes.split(',').map(measurementTypeName => {
            return { name: measurementTypeName, enabled: true }
        })
        return { primary: primarayTypes, secondary: secondaryTypes, typeCategory: chartCategory };

    }

    const createSizeChartTable = () => {
        return (
            <>
                <SizeChartTable show={openTab === 'width'} measurementTypes={primaryMeasurementTypes} setMeasurementTypes={setPrimaryMeasurementTypes} sizeNames={widthSizesNames} sizeMatrix={widthSizeMatrix} setSizeMatrix={setWidthSizeMatrix} setParentSizesName={setWidthSizesNames} isMutallyExcluded={false} isBra={false} errorCoordiante={currentErrorObj?.tableType === 'width' ? currentErrorObj?.coordinate : undefined} />
                <SizeChartTable show={openTab === 'length'} measurementTypes={secondaryMeasurementTypes} setMeasurementTypes={setSecondaryMeasurementTypes} sizeNames={lengthSizesNames} sizeMatrix={lengthSizeMatrix} setSizeMatrix={setLengthSizeMatrix} setParentSizesName={setLengthSizesNames} isMutallyExcluded={true} isBra={false} errorCoordiante={currentErrorObj?.tableType === 'length' ? currentErrorObj?.coordinate : undefined} />
            </>
        )
    }
    const tabClick = (tab) => {
        const vlWidth = document.querySelector(".vl-width")
        const vlWidthLength = document.querySelector(".vl-width-length")
        const vlLengthWidth = document.querySelector(".vl-length-matrix") 
        const vlMatrix = document.querySelector(".vl-matrix") 
        switch (tab) {
            case "width":
                setOpenTab('width')
                vlWidth.style.display = "none";
                vlWidthLength.style.display = "none";
                vlLengthWidth.style.display = 'block';
                vlMatrix.style.display = 'block';
                break;
            case "length":
                setOpenTab('length')
                vlWidth.style.display = "block"
                vlWidthLength.style.display = "none"
                vlLengthWidth.style.display = "none"
                vlMatrix.style.display = 'block';
                break;
            case "matrix":
                setOpenTab('matrix')
                vlWidth.style.display = "block"
                vlWidthLength.style.display = "block"
                vlLengthWidth.style.display = "none"
                vlMatrix.style.display = 'none';
                break;
                default:
        }
    }
    const createTabs = () => {
        return (<div className='tabs-container'>
            <div className="vl-width"></div>
            <div onClick={() => { tabClick('width') }} className={`item ${openTab === 'width' ? 'active' : ''}`} id='width'>Width</div>
            <div className="vl-width-length"></div>
            <div onClick={() => { tabClick('length') }} className={`item ${openTab === 'length' ? 'active' : ''}`} id='length'>Length</div>
            <div className="vl-length-matrix"></div>
            <div onClick={() => { tabClick('matrix') }} className={`item ${openTab === 'matrix' ? 'active' : ''}`} id='matrix'>Size Matrix</div>
            <div className="vl-matrix"></div>
        </div>);
    }
    const debugMatrix = () => {
        
        for (let i = 0; i < lengthSizesNames.length; i++) {
            let s = "";
            for (let j = 0; j < widthSizesNames.length; j++) {
                if (sizeChartMatrix !== undefined && sizeChartMatrix[i] !== undefined)
                    s = s + (sizeChartMatrix[i][j] ? 1 : 0) + " ";
            }
            
        }

    }
    const vInMatrixChecked = (col, row, checked) => {
        //direct writing prevents useEffect(,[enabledArray)        
        enabledArray[sizeName(widthSizesNames[row], lengthSizesNames[col])] = checked;
        let matrixArray = [];
        for (let i = 0; i < lengthSizesNames.length; i++) {
            matrixArray[i] = []
            for (let j = 0; j < widthSizesNames.length; j++) {
                matrixArray[i][j] = sizeChartMatrix[i][j];
                if (row === j && col === i)
                    matrixArray[i][j] = checked;
            }
        }

        setSizeChartMatrix(matrixArray);
    }

    const submitSizeChart = async () => {
        if (canBeSubmited) {
            let body = { approval_status: "submitted" };
            await new ApiService().submmitSizeChart(openSelectedSizeChart.id, body);
            setIsDialogOpen(true)
        }
    }

    const checkIfToCloseModal = () => {
        if (isChartChanged) {
            setIsChartSaveDialogIsOpen(true);
        }
        else {
            setOpenSelectedSizeChart({});
        }
    }


    return (
        primaryMeasurementTypes.length !== 0 &&
        <div style={{top: retailerProfile.source === 'presta' && '30%'}}  className='standart-size-chart-creator-wrapper'>
            <img alt='close button' onClick={() => { checkIfToCloseModal() }} className='x-img' src={closeSizeChartCreatorImg} />
            <h1 className='basic-size-table-header'>Enter or Edit Body Measurements</h1>
            <p className='basic-size-table-header-desc'>Please complete the body measurements within the size chart to make it accurate for your store.</p>
            <SizeChartCreatorHeader gender={chartGender} chartName={chartName} selectedTypeCategory={selectedTypeCategory} chartAgeCategory={chartAgeCategory} setChartAgeCategory={setChartAgeCategory} setChartGender={setChartGender} setChartName={setChartName} measurementSystem={chartMeasurementSystem} setChartMeasurementSystem={setChartMeasurementSystem} approvalStatus={approvalStatus} chartsizeChartType={chartsizeChartType} />
            {(errorList.length !== 0 || canBeSubmited) && <SizeChartCreatorError errorList={errorList} currentError={currentErrorObj} setCurrentErrorObj={setCurrentErrorObj} errorSize={errorList.length} setOpenTab={setOpenTab} canBeSubmited={canBeSubmited} />}
            {createTabs()}
            {createSizeChartTable()}

            <SizeMatrix show={openTab === 'matrix'} widthSizesNames={widthSizesNames} lengthSizesNames={lengthSizesNames} sizeChartMatrix={sizeChartMatrix} setSizeChartMatrix={setSizeChartMatrix} vInMatrixChecked={vInMatrixChecked} />
            <div className='chart-btn-section'>
                <button onClick={() => saveSizeChart()} className='save-button'>Save</button>
                <button onClick={() => submitSizeChart()} style={{ background: !canBeSubmited && '#b2b2b2', color: !canBeSubmited && '#fffff', cursor: !canBeSubmited && 'unset' }} className='submit-button'>Submit</button>
            </div>
            {isDialogOpen && <AlertDialog
                setIsDialogOpen={() => setOpenSelectedSizeChart({})}
                header="Your size chart was submitted for review"
                description="We’ll send you an email when it’s been approved. This usually takes up to 48 hours."
                okButton='OK'
            />}
            {isDuplicateNameDialogOpen && <AlertDialog
                setIsDialogOpen={() => setIsDuplicateNameDialogOpen(false)}
                header="This chart has duplicate size names."
                description="Please make sure to give each measurement range a unique name before saving or leaving this tab"
                okButton='OK'
            />}
            {haveEmptyNamesDialog && <AlertDialog
                setIsDialogOpen={() => setHaveEmptyNamesDialog(false)}
                header="The size chart names are missing"
                description="The size chart names are not defined, please make sure values are updated before saving or leaving this tab"
                okButton='OK'
            />}



        </div>
    );
}


export default JeansSizeChartCreator;
