

// 4


const checkCVG = (first, second, difference) => {
    
    const fFrom = parseFloat(first.from);
    const fTo = parseFloat(first.to);
    const sFrom = parseFloat(second.from);
    const sTo = parseFloat(second.to);
  return sFrom >= fFrom  && sTo - fTo >= difference
};

exports.validate = (arr, unit,tableType) => {
  if(arr.length === 0) return []
  let difference = 0;
  if (unit === "metric") {
    difference = 1;
  } else if (unit === "imperial") {
    difference = 0.39;
  }

  const errors = []
  for (let j = 0; j < arr[0].length; j++) {
    for (let i = 0; i < arr.length - 1; i++) {
      const first = arr[i][j];
      if(first.enabled === false) continue;
      const second = arr[i + 1][j];
      if (second.enabled === false) continue;
      const valid = checkCVG(first, second, difference);
      if(!valid){
          const message = `Consecutive sizes should grow proportionately. Measurement ranges for size ${first.sizeName} should be smaller than those set for ${second.sizeName}, and so on.`
          errors.push({coordinate: [i,j],message,tableType})
      }
    }
  }
  return errors;
};
