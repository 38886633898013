
// 10

const isSizeNameExist = (arr, names) => {
  if (!names.includes(arr.sizeName)) {
    names.push(arr.sizeName);
    return true;
  } else {
    return false;
  }
};

exports.validate = (arr,tableType) => {
  if(arr.length === 0 ){
    return [];
  }
  const names = [];
  const errors = [];
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i][0];
    const valid = isSizeNameExist(element, names);
    if (!valid) {
      const message =
        "This chart has duplicate size names. Please make sure to give each measurement range a unique name.";
      errors.push({ coordinate: [i, 0], message,tableType});
    }
  }

  return errors
};
