import React, { useEffect, useContext, useState } from 'react';
import "./Account.scss";
import TopBar from '../TopBar/TopBar';
import { useNavigate, Link, Navigate } from "react-router-dom";
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import Tooltip from "@material-ui/core/Tooltip";
import download from '../../assets/images/icon-download.svg'
import arrowDown from '../../assets/images/black-arrow-down.svg'
import arrowUp from '../../assets/images/black-arrow-up.svg'
import redAlert from '../../assets/images/plan-alert-icon.svg'
import logoutIcon from '../../assets/images/logout-icon.svg'
import AlertDialog from '../AlertDialog/AlertDialog';
import ApiService from '../../services/ApiService';
import BrandIdentity from '../BrandIdentity/BrandIdentity';
import { Modal } from '../UI/Modal/Modal';
import redCircle from '../../assets/images/Icon-close-circle-red.svg';
import { LocalStorageService } from '../../services/LocalStorageService';

export default function Account({ txErrorRef }) {

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isBillingSectionOpen, setIsBillingSectionOpen] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showTransactionError, setShowTransactionError] = useState(txErrorRef.current.type === 'error' ? true : false);
    const [uninstall, setUninstall] = useState(null);
    const [plan, setPlan] = useState(null);
    const [showPlanExpensionDialog, setShowPlanExpensionDialog] = useState(false);
    const [expand, setExpand] = useState(null);
    const [showPlanHistory, setShowPlanHistory] = useState(false);



    const [retailerProfile, setRetailerProfile] = useContext(
        RetailerProfileContext
    );
    const history = useNavigate();

    const IsObjectEmpty = (obj) => {
        return !(obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);

    }

    useEffect(() => {
        // txErrorRef.current=null;
        if (!IsObjectEmpty(retailerProfile)) {
            <Navigate to="/signup" />
        }
    }, [])

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.command === "REDIRECT") {
                history('/plans');


            }
            else if (event.data.command === "REDIRECT_EXTERNAL") {
                window.location.href = event.data.url;
            }
        }, false);

        return () => { window.removeEventListener("message", event => { }) };
    }, [history])


    useEffect(() => {
        if (!retailerProfile.transactions) return
        // const url = new URL(window.location.href);
        // const txError = url.searchParams.get('txError');
        window.history.replaceState(null, null, window.location.pathname);

    }, [retailerProfile])

    useEffect(() => {
        if (!uninstall) {
            setUninstall(null)
        } else if (uninstall) {
            uninstallHandler();
        }

        setShowDeleteDialog(false);
    }, [uninstall])

    useEffect(() => {

        if (!expand) {
            setExpand(null)
        } else if (expand) {
            expandHandler();
        }

        setShowPlanExpensionDialog(false);

    }, [expand])

    let is_warning = (retailerProfile?.pricing_plan?.is_free && retailerProfile?.monthly_package_usage >= retailerProfile?.plan_package_size * 0.8 && retailerProfile?.monthly_package_usage < retailerProfile?.plan_package_size) || (!retailerProfile?.pricing_plan?.is_free && retailerProfile?.pricing_plan?.is_limited && retailerProfile?.monthly_package_usage >= retailerProfile?.plan_package_size * 0.8 && retailerProfile?.monthly_package_usage < retailerProfile?.plan_package_size)
    let is_error = (retailerProfile?.pricing_plan?.is_free && retailerProfile?.monthly_package_usage >= retailerProfile?.plan_package_size) || (retailerProfile?.pricing_plan?.is_limited && (retailerProfile?.monthly_package_usage >= retailerProfile?.plan_package_size));


    const tooglePopup = (status) => {
        setIsShowPopup(status)
    }

    const expandHandler = async () => {
        const api = new ApiService();
        const response = await api.plansRedirection({ pricing_plan_id: plan.upgradedPlanID });
        window.location.href = response.url;
    }

    const accountToken = retailerProfile.account && retailerProfile.account.token;

    const editBillingCard = async () => {
        const retailersPlanId = retailerProfile.pricing_plan && retailerProfile.pricing_plan.id;

        const response = await new ApiService().plansRedirection({ pricing_plan_id: retailersPlanId });
        if (retailerProfile.source !== 'wix') {
            window.location.href = response.url;
        }
        else {
            window.top.location = response.url;
        }
    }

    const renderBillingData = () => {
        if (retailerProfile.pricing_plan) {
            return (
                <div className="billing-data">
                    <div className="col">
                        <p>Size recs beyond your plan</p>
                        <span>{retailerProfile.monthly_extra_usage}</span>
                    </div>
                    <div className="col">
                        <p>Price per size rec</p>
                        <span>${retailerProfile.plan_pay_as_you_go_price / 100}</span>
                    </div>
                    <div className="col">
                        <div className="container_dsasd">
                            <Tooltip
                                id="tooltip-right"
                                title="When switching pricing plans within the same calendar month, your extra payments will reflect the relative difference between each plan’s pricing, beyond your current plan’s standard calculations"
                                placement="left"
                                classes={{
                                    tooltip: 'action-tooltip'
                                }}>
                                <img src={""} />
                            </Tooltip>
                            <p>Extra payment</p>
                        </div>
                        <span>{retailerProfile.user && retailerProfile.user.monthly_extra_usage > 0 ? `$${this.calculateAdditionalPayment()}` : 0}</span>
                    </div>
                </div>
            );
        } else {
            return ('');
        }
    }

    const recomendationsDate = () => {
        let date = new Date();
        return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
    }

    const uninstallHandler = () => {
        const api = new ApiService();
        api.deleteRetailerProfile();
        logout(true);
        history("/uninstall", { replace: true });
    }

    const renderPlaninfoText = () => {
        let text = 'Size recommendations reached in current plan as of ' + recomendationsDate();
        if (is_warning) {
            text = 'You’re running low on size recs!'
        } else if (is_error) {
            text = 'You’ve exceeded your plan’s limit!'
        }
        return text
    }

    const renderCancelExpand = () => {
        let downgradedPlan = null;
        retailerProfile.meta.pricing_plans.forEach(plan => {
            if (plan.id === retailerProfile.pricing_plan.downgraded_plan_id) {
                downgradedPlan = plan
            }
        });

        return (<span style={{ display: 'inline-block', color: '#0d66d0', cursor: 'pointer' }}
            onClick={() => handleChoosePlan(retailerProfile.pricing_plan.downgraded_plan_id)}> | Cancel Expansion</span>)

    }

    const handleChoosePlan = async (pricing_plan_id) => {
        const api = new ApiService();
        const response = await api.plansRedirection({ pricing_plan_id });
        window.location.href = response.url;


    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const setPopupMessage = () => {
        let text = "Overall monthly usage, including previous plans";
        if (retailerProfile.pricing_plan.is_free) {
            text = "Overall free monthly usage";
        }
        return text
    }


    const renderPlanHistory = () => {
        let aggregators = retailerProfile.current_month_aggregator.filter(aggregator => aggregator.plan_history.end_date !== null).map((aggregator, index) => {
            index = index + 1;
            return (
                <div key={index + '4234432'} className='plan-history-item'
                    style={{ display: showPlanHistory ? 'flex' : 'none' }}>
                    <p className='plan-name'>{aggregator.plan_history.plan_name}</p>
                    <div className='plan-history-capacity-section'
                        style={{ width: retailerProfile.pricing_plan.downgraded_plan_id ? '583px' : '450px' }}>
                        <p className='history-capacity'>{numberWithCommas(aggregator.total_usage + aggregator.free_usage)}/{numberWithCommas(aggregator.plan_history.plan_package_size)}</p>
                        <p className='rec-reached'>Size recommendations reached</p>
                        <div className="myProgress" style={{
                            width: '100%',
                            backgroundColor: '#e8e8e8',
                            borderRadius: '25px',
                            opacity: '0.6',
                            marginTop: '5px'
                        }}>
                            <div style={{
                                width: Math.min((aggregator.total_usage + aggregator.free_usage / aggregator.plan_history.plan_package_size) * 100, 100) + "%",
                                height: '5px',
                                backgroundColor: '#f7676d',
                                borderRadius: '25px'
                            }}></div>
                        </div>
                    </div>
                </div>
            )
        });

        return aggregators

    }
    const renderPlanHistorySection = () => {
        if (retailerProfile.current_month_aggregator.length === 1 && retailerProfile.current_month_aggregator[0].plan_history.end_date == null) {
            return null
        }
        return (
            <div className='plan-history'>
                <div className="your-plan-history-header">
                    Plan History (Current Month)
                    <img style={{ width: '12px', cursor: 'pointer' }} onClick={() => setShowPlanHistory(prev => !prev)}
                        src={showPlanHistory ? arrowUp : arrowDown} alt="arrow" />
                </div>
                <div className='plan-history-section'>
                    {renderPlanHistory()}
                </div>
            </div>
        );
    }






    const renderYourPlanSection = () => {
        return (
            retailerProfile.pricing_plan && <div>
                <div className='your-plan-section'>Your Plan</div>
                <div className='plan-details-section'>
                    <div className='plan-name-section'>
                        <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{retailerProfile?.pricing_plan?.name}</p>
                        <p>Paid Monthly</p>
                    </div>
                    <div style={{ width: retailerProfile?.pricing_plan?.downgraded_plan_id ? '583px' : '450px' }} className='current-plan-info-section'>
                        <div className='reccomendation-count'>
                            {isShowPopup ? <div className='popup-window'
                                style={{ width: retailerProfile?.pricing_plan?.is_free ? "200px" : "320px" }}>
                                <p>{setPopupMessage()}</p></div> : null}
                            <p style={{
                                fontSize: '20px',
                                fontWeight: 'bold'
                            }}>
                                {numberWithCommas(retailerProfile.monthly_package_usage)} / {numberWithCommas(retailerProfile.plan_package_size)}
                            </p>
                            <img className='red-alert-img' alt="info" src={redAlert} onMouseEnter={() => tooglePopup(true)}
                                onMouseLeave={() => tooglePopup(false)} />
                        </div>
                        <p>{renderPlaninfoText()} {retailerProfile?.pricing_plan?.downgraded_plan_id ? renderCancelExpand() : ''}</p>
                        <div className="myProgress">
                            <div id="myBar-0" style={{ width: Math.min(((retailerProfile.monthly_package_usage / retailerProfile.plan_package_size) * 100), 100) + '%' }}></div>
                        </div>
                    </div>
                    {/* <div className="switch-plan-btn">
                        <Link to="/plans">Switch Plans</Link>
                    </div> */}

                </div>
                {is_error || is_warning ?
                    <div className='plan-capacity-error'
                        style={{ backgroundColor: is_warning ? '#fcf2da' : 'rgba(247, 103, 109, 0.1)' }}>
                        {retailerProfile.pricing_plan.upgraded_plan_id ? setExpandMessage() : setErrorMessage()}
                    </div> : null}
            </div>
        );
    }

    const renderBillingInformation = () => {

        return <div className="billing">
            <div className='your-billing-header'>Billing Information</div>
            <div className='your-billing-section'>
                <div className='billing-email'>
                    <p className="email bold">Email</p>
                    <p style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        // width: '200px'
                    }}>{retailerProfile?.account?.email}</p>
                </div>
                <div className='billing-card'>
                    <p className='bold' style={{ fontWeight: 700, minWidth: "200px" }}>Card Information</p>
                    <p>{retailerProfile && retailerProfile?.last_card_number ?
                        <>
                            <span>{retailerProfile?.last_card_number}</span>
                            <>
                                <span className='billing-edit-card-link' onClick={editBillingCard}>Edit</span>
                                <span className='billing-warning-message'>{getWarningMessage(retailerProfile?.last_card_expiry)}</span>
                            </>
                        </> :
                        <span>------</span>}
                    </p>
                </div>
            </div>
        </div>

    }

    const getWarningMessage = (MMYY) => {
        if (isDateLaerThenToday(MMYY, 1)) {
            return 'Your credit card is expired.'
        } else if (isDateLaerThenToday(MMYY, 95)) {
            return 'Your credit card is about to expire.'
        } else {
            return ''
        }
    }

    const isDateLaerThenToday = (MMYY, days) => {
        if (days < 0 || !MMYY) return false
        let MM = MMYY.substring(0, 2)
        let YY = MMYY.substring(2)
        return addDays(new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`), days) > new Date(`20${YY}-${MM}-01`)
    }

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const renderBillingHistory = () => {
        let transactions = [...retailerProfile?.transactions];
        let transactionArr = []
        for (const key in retailerProfile?.transactions) {
            if (Object.hasOwnProperty.call(transactions, key)) {
                const element = transactions[key];
                // console.log(element.is_invoiceable);
                // if (!element.is_invoiceable) {
                //     delete transactions[key]
                // } else {
                transactionArr.push(element);
                // }
            }
        }

        transactionArr = transactionArr.sort(function (a, b) {
            return a.id - b.id
        }).reverse();
        return <React.Fragment>
            <div className='billing-history-header' style={{ display: 'flex', justifyContent: "flex-start" }}>
                <p className='title' style={{ width: '319px' }}>Billing History</p>
                <p className='date' style={{ width: '748px' }}>Date</p>
                <img style={{ width: '12px', cursor: 'pointer' }}
                    src={isBillingSectionOpen ? arrowUp : arrowDown} onClick={() => setIsBillingSectionOpen(!isBillingSectionOpen)} alt="arrow" />
            </div>
            {isBillingSectionOpen ? <div className='billing-history-section'>
                {retailerProfile.transactions ? transactionArr.map((transaction, index) => {
                    if (transaction.is_invoiceable) {
                        return (<div key={index + 1 + '12345'} className="billing-payment-row">
                            <p >{"Invoice " + transaction.id}</p>
                            <p>{renderDate(transaction)}</p>
                            {transaction.is_invoiceable && transaction.invoice_link !== null ?
                                <div className="download-file"
                                    onClick={() => downloadReceipt(transaction.invoice_link)}>
                                    {/* <img src={download} alt="Smiley face" style={{cursor: "pointer"}}/> */}
                                    <img src={download} alt="download"></img>
                                </div> :
                                <div>-</div>}
                        </div>)
                    }
                }) : null}
            </div> : null}
        </React.Fragment>
    }




    const downloadReceipt = (link) => {
        window.location.replace(link);
    };

    const renderDate = (transaction) => {
        let data = transaction.created_at.split('T')[0];
        let year = data.split('-')[0];
        let mount = data.split('-')[1];
        let day = data.split('-')[2];
        let date = day + "." + mount + "." + year;
        return date
    };

    const logout = (skipRedirect = false) => {
        LocalStorageService.remove("retailer_token");
        LocalStorageService.remove("MYSIZE_plan");
        window.account = null;
        setRetailerProfile({});

        if (!skipRedirect)
            history("/welcome", { replace: true });
    }

    if (!IsObjectEmpty(retailerProfile)) {
        return <div></div>
    }

    const isSourceWithOutProductsPair = () => {
        return retailerProfile.source === "external" || retailerProfile.source === "woocommerce" || retailerProfile.source === 'bitrix' || retailerProfile.source === 'plugin' || retailerProfile.source === 'lightspeed'
    }



    const setExpandMessage = () => {
        let upgradedPlan = null;
        retailerProfile.meta.pricing_plans.forEach(plan => {
            if (plan.id === retailerProfile.pricing_plan.upgraded_plan_id) {
                upgradedPlan = plan
            }
        });
        return (
            <div style={{ display: 'inline' }}>Expand your plan.
                <p style={{ display: 'inline', color: "#0d66d0", cursor: "pointer" }}
                    onClick={() => handlePopupChoosePlan(retailerProfile.pricing_plan.upgraded_plan_id, upgradedPlan.package_size, upgradedPlan.base_price.value)}> Add&nbsp;
                    {numberWithCommas(upgradedPlan.package_size - retailerProfile.pricing_plan.package_size)}&nbsp;size
                    recs for only&nbsp;
                    {upgradedPlan.base_price.value - retailerProfile.pricing_plan.base_price.value}$/month</p></div>)
    }

    const handlePopupChoosePlan = (upgradedPlanID, upgradedPackageSize, upgradedBasePrice) => {
        setPlan({
            upgradedPlanID: upgradedPlanID,
            upgradedPackageSize: upgradedPackageSize,
            upgradedBasePrice: upgradedBasePrice
        });
        setShowPlanExpensionDialog(true);
    }

    const setErrorMessage = () => {
        let text = 'Looks like your expanded plan is not enough. Switch plans to add more size recs​';
        if (retailerProfile.pricing_plan.is_free) {
            text = 'Looks like your free plan is not enough. Switch plans to add more size recs';
        }
        return text;
    }



    let planMessage = null;

    if (parseInt(retailerProfile?.plan_package_size) === parseInt(retailerProfile?.monthly_package_usage)) {
        planMessage = "Looks like your free plan is not enough. Switch plans to add more size recs"
    }


    let text = "You’re about to add 2,000 size recs to your current monthly plan for $39/month.​"
    if (retailerProfile.pricing_plan?.upgraded_plan_id) {
        let upgradedPlan = null;
        retailerProfile.meta.pricing_plans.forEach(plan => {
            if (plan.id == retailerProfile.pricing_plan.upgraded_plan_id) {
                text = `You’re about to add ${plan.package_size - retailerProfile.pricing_plan.package_size} size recs to your current monthly plan for $${plan.base_price.value - retailerProfile.pricing_plan.base_price.value}/month.​`
            }
        });
    }


    if (retailerProfile.code === 401) {
        return null
    }

    return (
        <div className={`account ${isSourceWithOutProductsPair() && "external"}`}>
            <TopBar />
            {/* <div className="iframe-wrapper"><iframe height="100%" className="widget-accout" frameBorder="0" src={`${api_base_url}/mydash/account?token=${accountToken}`} title="mydash2 settings"></iframe></div> */}
            <div className="account-header">
                <div className="account__title">Account</div>
                {retailerProfile?.source === "external" && <div className="logout" onClick={() => logout()}>Log Out  &nbsp;<img src={logoutIcon} alt="logout icon"></img></div>}
            </div>
            {retailerProfile?.source === "external" &&
                <BrandIdentity />
            }
            {renderYourPlanSection()}
            {renderPlanHistorySection()}

            {showPlanExpensionDialog &&
                <AlertDialog
                    setIsDialogOpen={setExpand}
                    header="Confirm Your Plan Expansion"
                    description={text}
                    okButton='Expand Plan'
                    cancelButton='Cancel'
                    style={{ padding: "15px" }} />}
            {retailerProfile.meta.pricing_plans.length !== 0 &&
                <React.Fragment>
                    {retailerProfile?.pricing_plan?.name !== 'Free' && renderBillingInformation()}
                    {!(retailerProfile.source === "wix" || retailerProfile.source === "shopify") && renderBillingHistory()}
                </React.Fragment>}
            {retailerProfile.source === "external" && <div className="account__uninstall-button" onClick={() => setShowDeleteDialog(true)}> Uninstall MySizeID </div>}
            {showDeleteDialog && <AlertDialog
                setIsDialogOpen={setUninstall}
                header="Are you sure you want to uninstall MySizeID?"
                description="Your customer's won't receive personalized size recommendations"
                okButton='Uninstall'
                cancelButton='Cancel'
                style={{ padding: "15px" }}
            />}
            {/* {showTransactionError && window.history.replaceState(null, null, window.location.pathname)} */}
            {showTransactionError && <Modal
                msg={{ title: <img src={redCircle} alt="Red close icon" />, body: `Something went wrong.\nPlease try again later.\n${txErrorRef.current.error ? txErrorRef.current.error + '\n' : ''}${retailerProfile.transactions.slice(-1)[0].response_error_text ? retailerProfile.transactions.slice(-1)[0].response_error_text : ''}` }}
                timer={false}
                showCloseBtn={"CLOSE"}
                showX={false}
                closeCB={() => {
                    // window.history.replaceState(null, null, window.location.pathname);
                    setShowTransactionError(false);
                    txErrorRef.current = { type: null, error: null };
                }}
                bgcScreen='rgba(255,255,255,0.7)'
                bgcMsg='#fff'
                bodyClr='#1f1b45'
                btnBgc='#f8686e'
                btnText='rgb(255,255,255)' />}

        </div>
    )
}
