import './StoreProductItem.scss'
import imgIcon from '../../assets/images/open-eye.svg'
import React, { useState, useContext } from 'react';
import { SelectedSizeChartContext } from '../../context/SelectedSizeChartContext';
import { RetailerProfileContext } from '../../context/RetailerProfileContext';
import ApiService from '../../services/ApiService';
import noProductImg from '../../assets/images/no-product-image.svg';
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import pairedIcon from '../../assets/images/paired-icon.svg'
import missingPairedIcon from '../../assets/images/missing-paired-icon.svg'
import nonePairedIcon from '../../assets/images/none-paired-icon.svg'
import expandIcon from '../../assets/images/expand-icon.svg'
import PairedProductAlert from '../../components/PairedProductAlert/PairedProductAlert';
import { useNavigate } from 'react-router-dom';
import { TipsContext } from '../../context/TipsContext';
import { LocalStorageService } from '../../services/LocalStorageService';


function StoreProductItem({ title, id, photo, productUrl, sizeChart, productsToPair, setProductsToPair, updateShopProductList, expanded, properties, expandCollapseByIndexHandler, index }) {
    const [selectedSizeChart] = useContext(SelectedSizeChartContext);
    const [retailerProfile] = useContext(RetailerProfileContext);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showPairedAlert, setShowPairedAlert] = useState(false);
    const [tipsState, tipsHandler] = useContext(TipsContext);

    const history = useNavigate();

    const checkIfObjEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    }
    const handlePair = (isAgree) => {
        if (isAgree) {
            history('/widget-settings');
        } else {
            setIsDialogOpen(false);
        }

    }




    const setPairing = (selectedChart, externalProductID, connectedsizeChartToProduct, btnClicked, productUrl) => {


        tipsState.pair_size_chart && tipsHandler("pair_size_chart")

        let firstPair = LocalStorageService.get("first_pair")
        // if size chart not selected and product not connected to size chart.. return
        if (checkIfObjEmpty(selectedChart) && connectedsizeChartToProduct === null) {
            return;
        }
        // if the product already connected to size chart and btnClicked is "unpair" delete him!
        if (connectedsizeChartToProduct && btnClicked === "unpair") {
            new ApiService().disconnectSizeChartFromProduct(externalProductID);


            const newProductToPair = [...productsToPair];

            productsToPair.forEach((prod, index) => {
                if (prod.productId === externalProductID) {
                    newProductToPair[index].sizeChartId = null;
                    newProductToPair[index].sizeChartName = null;

                }
            })

            setProductsToPair(newProductToPair);
            updateShopProductList('delete', externalProductID);
        }
        // if btnClicked is "pair" pair anyway any product



        if ((btnClicked === "pair" && connectedsizeChartToProduct) && connectedsizeChartToProduct?.id) {
            // Check if size chart selected before starting pairing
            if (!selectedChart.id) {
                return;
            }
            setShowPairedAlert(true);
            return;
        }
        else if (btnClicked === "pair") {
            // Check if size chart selected before starting pairing
            if (!selectedChart.id) {
                return;
            }
            new ApiService().connectSizeChartFromProduct(externalProductID, { size_chart_id: selectedChart.id, product_url: productUrl });
            const newProductToPair = [...productsToPair];
            setProductsToPair(newProductToPair.filter(productPair => productPair.productId !== externalProductID));

            updateShopProductList('add', externalProductID, selectedChart)
            if (firstPair == null) {
                LocalStorageService.set("first_pair", false)
                setIsDialogOpen(true)
            }
        }
    }


    const handleClick = (event) => {



        if (event.target.checked) {
            const data = {
                productId: id,
                productName: title,
                productUrl: productUrl,
                sizeChartId: sizeChart?.id,
                sizeChartName: sizeChart?.name,

            }

            setProductsToPair([...productsToPair, data])
        }
        else {

            let index = -1;
            productsToPair.forEach((product, i) => {
                if (product.productId === id) {
                    index = i;
                }
            });

            if (index !== -1) {
                let arr = [...productsToPair]
                arr.splice(index, 1);
                setProductsToPair(arr);
            }
        }
    }





    const productTags = properties.tags.map((tag, index) => {
        return <li key={"tag" + tag + index}>{tag} {index < properties.tags.length - 1 ? '|' : ''} </li>
    })
    const productSkus = properties.skus.map((sku, index) => {
        return <li key={"sku" + sku + index}>{sku} {index < properties.skus.length - 1 ? '|' : ''} </li>
    })
    const productCollection = properties.collection.map((collection, index) => {
        return <li key={"collection" + collection + index}>{collection} {index < properties.collection.length - 1 ? '|' : ''} </li>
    })



    const checkForProductId = () => {
        for (let index = 0; index < productsToPair.length; index++) {
            if (productsToPair[index].productId === id) {
                return true;
            }
        }
        return false;
    }


    let pairUnpairForm = null;

    // console.log("sizeChart", sizeChart);
    // Have paired size chart
    if (sizeChart) {
        // Have selected size chart
        if (selectedSizeChart.id) {
            pairUnpairForm = <div className="pair-unpaor-wrapper">

                <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "pair", productUrl)}
                    className="pair-unpair">
                    Pair
                </span>
                <span style={{ color: "#AFAFAF" }}> | </span>
                <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "unpair", productUrl)}
                    className="pair-unpair">
                    Unpair
                </span>
            </div>

            if (sizeChart.id === selectedSizeChart.id) {
                pairUnpairForm = <div className="pair-unpaor-wrapper">
                    <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "unpair", productUrl)}
                        className="pair-unpair">
                        Unpair
                    </span>
                </div>
            }
        }
        else if (!selectedSizeChart.id) {
            pairUnpairForm = <div className="pair-unpaor-wrapper">
                <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "unpair", productUrl)}
                    className="pair-unpair">
                    Unpair
                </span>
            </div>
        }
    }
    else { // Have NOT paired size chart

        // Have selected size chart
        if (selectedSizeChart.id) {
            pairUnpairForm = <div className="pair-unpaor-wrapper">

                <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "pair", productUrl)}
                    className="pair-unpair">
                    Pair
                </span>
            </div>
        }
        // Have NOT selected size chart
        else if (!selectedSizeChart.id) {
            pairUnpairForm = <div className="pair-unpaor-wrapper">
                <span onClick={() => setPairing(selectedSizeChart, id, sizeChart, "pair", productUrl)}
                    className="pair-unpair gray-color">
                    Pair
                </span>
            </div>
        }
    }

    // console.log(productUrl);

    return (
        <React.Fragment>
            {showPairedAlert && <PairedProductAlert renderedFrom="StoreProductItem" setShowPairedAlert={setShowPairedAlert} selectedSizeChart={selectedSizeChart} id={id} updateShopProductList={updateShopProductList} setIsDialogOpen={setIsDialogOpen} title={title} sizeChart={sizeChart} productsToPair={productsToPair} setProductsToPair={setProductsToPair} productUrl={productUrl} />}
            <div className='store-product-item'>
                <div className={`store-product-item-left-section ${expanded && photo && "expanded-height-picture"}`}>
                    <label className="control control-checkbox">
                        <input checked={checkForProductId()} type='checkbox' onChange={(event) => handleClick(event)} />
                        <div className="control_indicator"></div>
                    </label>
                    {isDialogOpen ?
                        <div>
                            <AlertDialog
                                setIsDialogOpen={handlePair}
                                header="You’ve successfully paired your first size chart!"
                                description='To edit the appearance of the widget in your store,
                         click on “Widget Settings” located in the main Nav bar'
                                okButton='Open Widget Settings'
                                closeImg='close'
                            />
                        </div>
                        : ""}
                    <img className={(!expanded || !photo) && "collapsed-photo"} alt='' src={photo ? photo : noProductImg} />
                </div>

                <div className='store-product-item-middle-section'>
                    <p className="product-title">{title}</p>
                    <p className='size-chart-pair' style={{ color: sizeChart === null ? '' : '#211c51' }}>
                        <span>Paired Chart: </span>
                        {sizeChart === null ? <span><img src={nonePairedIcon} alt="none pair" ></img><span className="pair-text"> None</span></span> :
                            sizeChart.id === null ? <span><img src={missingPairedIcon} alt="missing pair"></img> <span className="pair-text" style={{ textDecoration: "line-through" }}>Missing</span></span> :
                                <span><img src={pairedIcon} height="11.895" width="11.895" alt="pair"></img> <span className="pair-text">{sizeChart.name}</span></span>
                        }
                    </p>
                    <div className="expand-section" style={!expanded ? { display: "none" } : { display: "block" }}>
                        <div className="upper-section">
                            {properties.collection.length > 0 && <ul className="expanded-list"><span>Collection:</span> {productCollection}</ul>}
                            {properties.vendor !== '' && <div><span>Vendor:</span> {properties.vendor} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.productType !== '' && <div><span> Product Type:</span> {properties.productType} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.status !== '' && <div><span>Status:</span> {properties.status} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.manufacturer_name && properties.manufacturer_name !== '' && <div><span> Manufacturer Name:</span> {properties.manufacturer_name} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.id_category_default && properties.id_category_default !== '' && <div><span>Main Category:</span> {properties.id_category_default} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.isbn && properties.isbn !== '' && <div><span> ISBN:</span> {properties.isbn} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.ean13 && properties.ean13 !== '' && <div><span> EAN13:</span> {properties.ean13} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.mpn && properties.mpn !== '' && <div><span> MPN:</span> {properties.mpn} <span style={{ fontWeight: "normal" }}> | </span> </div>}
                            {properties.upc && properties.upc !== '' && <div><span> UPC:</span> {properties.upc} <span style={{ fontWeight: "normal" }}></span> </div>}


                        </div>
                        {productTags.length > 0 && <ul className="expanded-list"><span>Tags:</span> {productTags}</ul>}
                        {productSkus.length > 0 && <ul className="expanded-list"><span>SKUs:</span> {productSkus}</ul>}

                    </div>
                </div>
                <div className='store-product-item-right-section'>
                    {/* <p className='size-chart-pair' style={{ color: sizeChart === null ? '' : '#211c51' }}>{sizeChart === null ? 'Missing Chart' : sizeChart.name == null ? "Size chart removed" : sizeChart.name}</p> */}

                    <div className="eye-expand-wrapper">
                        <div className={`view-eye-section`} onClick={() => {
                            retailerProfile.source === 'shopify' ? window.open(`http://${productUrl}`, '_blank').focus() : window.open(`${productUrl}`, '_blank').focus();
                        }}>
                            <img alt='eye' src={imgIcon} />
                            {/* {expanded && "expanded"} */}
                        </div>
                        <div className="expand-collapse-wrapper" onClick={() => expandCollapseByIndexHandler(index)}>

                            <img className={`expand-icon ${expanded && "expanded"}`} src={expandIcon} alt="expand or collapse icon"></img>
                        </div>

                    </div>
                    {pairUnpairForm}
                </div>
            </div>


        </React.Fragment>
    );
}

export default StoreProductItem;
