import React from 'react';
import "./CircularIndeterminate.scss";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function CircularIndeterminate({precentProgress=-1, color, size}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      color: color,
    },
  }));
  const classes = useStyles();

  return (
    <div className={`circular-progress ${classes.root}`}>
      <CircularProgress color={`${color ? "inherit" : "secondary"}`} size={size}  />
      {precentProgress>=0 && <label className="progress-precent" style={{display:"none"}}>{precentProgress}%</label>}
    </div>
  );
}