import React from "react";
import "./SizeChartName.scss";
import { chartWizardPageNames } from "../../../utils/page-state";
import { useState } from "react";

export default function SizeChartName({ setChartPageState, name, setName, fromEdit, onSaveHandler, sizeChartData,setEditRenderer }) {
  const [nameState, setNameState] = useState(name);

  const onNextClickHandler = () => {
    // check for any input
    if (nameState === "") {
      return;
    }
    setChartPageState(chartWizardPageNames.CHART_WIZARD_GENDER);
    setName(nameState);
  };



  const onSaveClickHandler = () => {
    // check for any input
    if (nameState === "") {
      return;
    }
    // if the component rendered from edit 
    if(fromEdit){
      let updatedSizeChartData = {...sizeChartData} ;
      updatedSizeChartData.name = nameState;
      onSaveHandler(updatedSizeChartData)
      setEditRenderer(null)
      return;
    }


  }


  let btnText = null;
  if(fromEdit){
    btnText = "Save"
  }else{
    btnText = "Next"

  }


  return (
    <div className="name" style={{width: fromEdit && '500px'}}>
      <h1>Name the Chart</h1>
      <input
        type="text"
        placeholder="Men's Polos / T-Shirts etc..."
        onChange={(event) => setNameState(event.target.value)}
        defaultValue={name}
      ></input>
      <div
        className={`name-button ${nameState === "" && "unable"}`}
        onClick={fromEdit ? onSaveClickHandler :  onNextClickHandler}
      >
        {btnText}
      </div>
    </div>
  );
}
