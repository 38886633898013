
import React from "react";
import "./ChartWizardStepper.scss";
import { Stepper } from "react-form-stepper";

export default function ChartWizardStepper({ stepNumber }) {
  return (
    <Stepper
      steps={[
        {
          label: <div className={`${stepNumber === 0 ? "active-name" : undefined} ${stepNumber > 0 && "passed"}`}>Name</div>,
        },
        {
          label: (
            <div className={`${stepNumber === 1 ? "active-name" : undefined} ${stepNumber > 1 && "passed"}`}>Gender</div>
          ),
        },
        {
          label: <div className={`${stepNumber === 2 ? "active-name" : undefined} ${stepNumber > 2 && "passed"}`}>Unit</div>,
        },
        { label: <div className={`${stepNumber === 3 ? "active-name" : undefined} ${stepNumber > 3 && "passed"}`}>Age</div> },
        {
          label: (
            <div className={`${stepNumber === 4 ? "active-name" : undefined} ${stepNumber > 4 && "passed"}`}>Category</div>
          ),
        },
      ]}
      activeStep={stepNumber}
      connectorStateColors={true}
    />
  );
}
