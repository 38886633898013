import React, { useEffect, useState, useContext } from 'react';
import './SizeChartListWrapper.scss';
import SizeChartListMap from "./SizeChartListMap";
import ApiService from '../../services/ApiService';
import HourglassIcon from "../../assets/images/hourglass-icon.svg";
import openInNewIcon from "../../assets/images/open-in-new.svg";
import plusIcon from '../../assets/images/plus-icon.svg';
import SizeChartUploading from '../SizeChartUploading/SizeChartUploading'
import SimpleMenu from '../SimpleMenu/SimpleMenu';
import { RetailerProfileContext } from "../../context/RetailerProfileContext";
import { TipsContext } from "../../context/TipsContext";
import { useNavigate } from "react-router-dom";
import infoIcon from '../../assets/images/plan-alert-icon.svg'
import closeIcon from '../../assets/images/x-close-button.svg';
import addIcon from "../../assets/images/plus-icon.svg";
import downArrowIcon from "../../assets/images/tips-down-arrow.svg";
import { LocalStorageService } from '../../services/LocalStorageService';



function SizeChartListWrapper({ setSizeChartLen, elRefIntegrationCode, genericChartListUpdater, setGenericChartListUpdater, productPairRender, setProductPairRender, setTipsRendered, setShowChartWizard, setShowAddGenericCharts }) {
    const [sizeCharts, setsizeCharts] = useState([])
    const [searchSizeCharts, setsearchSizeCharts] = useState([])
    const [chartReceieveFromBe, setChartReceieveFromBe] = useState(false);
    const [showCreateSizeChart, setShowCreateSizeChart] = useState(false)
    const [showUpload, setShowUpload] = useState(false);
    const [showHint, setShowHint] = useState(false);

    const [titleInput, setTitleInput] = useState("");
    const [genderSelect, setGenderSelect] = useState(null);
    const [typeSelect, setTypeSelect] = useState(null);

    const [typeOptions, setTypeOptions] = useState(['Top', 'Bottom', 'Dress Shirt', 'Dress', 'Bra', 'Shoes', 'Overall', 'Socks', 'Hat', 'All'])
    const [GenderOptions, setGenderOptions] = useState(['Men', 'Women', 'Boys', 'Girls', 'Unisex', 'All'])
    const [existTypes, setExistTypes] = useState([]);

    const [typeAndGenderCharts, setTypeAndGenderCharts] = useState([]);

    const [retailerProfile] = useContext(
        RetailerProfileContext
    );
    const [tipsState, tipsHandler] = useContext(
        TipsContext
    );

    const history = useNavigate();

    useEffect(() => {
        setTypeAndGenderCharts(
            sizeCharts.map(sc => {
                if (sc.size_chart_type === null || sc.size_chart_type === undefined)
                    return {};
                return { gender: sc.gender, size_chart_type: sc.size_chart_type.name }
            }))
        setSizeChartLen(sizeCharts.length)
    }, [sizeCharts])


    useEffect(() => {
        let tempGender = [];
        let tempTypes = [];
        typeAndGenderCharts.forEach(typeAndGender => {
            if (typeAndGender === undefined)
                return;
            ['Top', 'Bottom', 'Dress_Shirt', 'Dress', 'Bra', 'Shoes', 'Overall', 'Socks', 'Hat'].forEach(type => {
                if (typeAndGender.size_chart_type === undefined) return;
                if (typeAndGender.size_chart_type.includes(type.toLowerCase())) {
                    if (type !== 'Dress' || !typeAndGender.size_chart_type.includes('dress_shirt')) {
                        if (!tempTypes.includes(type))
                            tempTypes.push(type)
                    }
                }
            });
        });

        typeAndGenderCharts.forEach(typeAndGender => {
            if (typeAndGender === undefined)
                return;
            ['female', 'male', 'unisex'].forEach(gender => {
                if (typeAndGender.gender === gender) {
                    if (gender === 'unisex') {
                        if (!tempGender.includes('Unisex'))
                            tempGender.push('Unisex')
                    }
                    else if (typeAndGender.size_chart_type.includes('kid') && gender === 'male') {
                        if (!tempGender.includes('Boys'))
                            tempGender.push('Boys')
                    }
                    else if (typeAndGender.size_chart_type.includes('kid') && gender === 'female') {
                        if (!tempGender.includes('Girls'))
                            tempGender.push('Girls')
                    }
                    else {
                        if (gender === 'female') {
                            if (!tempGender.includes('Women'))
                                tempGender.push('Women')
                        }
                        else {
                            if (!tempGender.includes('Men'))
                                tempGender.push('Men')
                        }
                    }


                }

            });
        });

        tempGender.unshift('All');
        tempTypes.unshift('All');
        setGenderOptions(tempGender);
        setTypeOptions(tempTypes);
        setExistTypes(tempTypes);
        setChartFilter();

    }, [typeAndGenderCharts])


    useEffect(() => {
        const syncFetch = async () => {
            await new ApiService().getAllSizeCharts().then(data => {
                setsizeCharts(data);
                setsearchSizeCharts(data);
                setChartReceieveFromBe(true);
            })
        }
        syncFetch();
    }, [genericChartListUpdater])

    useEffect(() => {
        chartReceieveFromBe && setShowHint(sizeCharts.length > 0 ? false : true);
    }, [searchSizeCharts, chartReceieveFromBe])


    const setChartFilter = () => {
        let tempCharts = [];
        tempCharts = sizeCharts.filter(sizeChart => (sizeChart.name.toLowerCase().includes(titleInput.toLowerCase()) || titleInput === ""));
        tempCharts = tempCharts.filter(sizeChart => {
            if (genderSelect === 'Boys') {
                return ((sizeChart.gender.toLowerCase() === 'male') && (sizeChart.size_chart_type?.name?.includes('kid')))
            }
            else if (genderSelect === 'Girls') {
                return ((sizeChart.gender.toLowerCase() === 'female') && (sizeChart.size_chart_type?.name?.includes('kid')))
            }
            else if (genderSelect === 'Unisex') {
                return ((sizeChart.gender.toLowerCase() === 'unisex'))
            }
            else if (genderSelect === 'Women') {
                return ((sizeChart.gender.toLowerCase() === 'female') && (!sizeChart.size_chart_type?.name?.includes('kid')))
            }
            else if ((genderSelect === 'Men')) {
                return ((sizeChart.gender.toLowerCase() === 'male') && (!sizeChart.size_chart_type?.name?.includes('kid')))
            }
            else {
                return sizeChart
            }

        });

        if (typeSelect === 'Dress') {

            tempCharts = tempCharts.filter(sizeChart => (sizeChart.size_chart_type?.name.toLowerCase().includes('dress_0') || sizeChart.size_chart_type?.name.toLowerCase().includes('dress_1') || sizeChart.size_chart_type?.name.toLowerCase().includes('dress_2') || sizeChart.size_chart_type?.name.toLowerCase().includes('dress_grou') || typeSelect === null));
        }
        else {
            tempCharts = tempCharts.filter(sizeChart => (sizeChart.size_chart_type?.name.toLowerCase().includes(typeSelect?.toLowerCase()) || typeSelect === null));
        }
        setsearchSizeCharts(tempCharts);



        // delete bra & dress when selected gender is bot or man
        let tempExist = [...existTypes]
        if (genderSelect === "Boys" || genderSelect === "Men") {
            tempExist = remvoeItemOnce(tempExist, 'Bra');
            tempExist = remvoeItemOnce(tempExist, 'Dress');
            setTypeOptions(tempExist);
        }
        else if (genderSelect === "Girls" || genderSelect === "Women") {
            tempExist = remvoeItemOnce(tempExist, 'Dress_Shirt');
        }
        setTypeOptions(tempExist);
    }

    useEffect(() => {

        setChartFilter();

        // eslint-disable-next-line
    }, [typeSelect, genderSelect, titleInput])

    const remvoeItemOnce = (arr, value) => {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }


    useEffect(() => {
        // size charts list update listener
        const syncFetch = async () => {
            await new ApiService().getAllSizeCharts().then(data => { setsizeCharts(data); setsearchSizeCharts(data); setChartReceieveFromBe(true) })

        }
        syncFetch();


    }, [productPairRender]);


    useEffect(() => {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // Disable tips popup on "alreadyVisited" when using safari
        if (!isSafari) {


            // check for first visit on page to trigger tips
            let visited = LocalStorageService.get("alreadyVisited");
            const sizeChartsCount = sizeCharts.length;
            // console.log('size count', sizeChartsCount, 'isSourceWithOutProductsPair()', isSourceWithOutProductsPair());
            // if (!visited && sizeChartsCount >= 0 ) {
            //     //this is the first time
            //     let newUpdatedTipsArray = { ...tipsArray };
            //     if(isSourceWithOutProductsPair()){
            //         console.log('1');
            //         newUpdatedTipsArray["add_chart"] = true;
            //         newUpdatedTipsArray["select_chart"] = false;
            //     }else{
            //         console.log('2');
            //         newUpdatedTipsArray["add_chart"] = false;
            //         newUpdatedTipsArray["select_chart"] = true;
            //     }
            //     // console.log('newUpdatedTipsArray', newUpdatedTipsArray);
            //     setTipsArray(newUpdatedTipsArray);
            //     //   localStorage["alreadyVisited"] = true;

            // } 
        }
        // eslint-disable-next-line
    }, [sizeCharts])




    let createSizeChartPopup = null;
    if (showUpload) {
        createSizeChartPopup = <div>
            <div className="create-size-chart-modal" onClick={() => setShowUpload(null)}></div>
            <div className="popup-size-chart-creator">{<SizeChartUploading setCreatorState={setShowUpload} />}</div>
        </div>;

    }

    const setFilterValue = (value, type) => {
        if (type === 'gender') {
            if (value === 'All')
                setGenderSelect(null)
            else
                setGenderSelect(value)
        }
        else if (type === 'type') {
            if (value === 'All')
                setTypeSelect(null)
            else
                setTypeSelect(value)
        }
        else if (type === 'title') {
            setTitleInput(value);
        }

    }

    const isSourceWithOutProductsPair = () => {
        return retailerProfile.source === "external" || retailerProfile.source === "woocommerce" || retailerProfile.source === 'bitrix' || retailerProfile.source === 'plugin' || retailerProfile.source === 'lightspeed'
    }
    const isWoocommerceBitrixLightspeed = () => {
        return retailerProfile.source === "woocommerce" || retailerProfile.source === 'bitrix' || retailerProfile.source === 'plugin' || retailerProfile.source === 'lightspeed'
    }


    return (
        <div className='exist-size-chart-list'>
            {createSizeChartPopup}

            {!isSourceWithOutProductsPair() ?
                <h1 className='header'>
                    <div className="size-charts-title">Size Charts</div>
                    <div>
                        <img src={plusIcon} alt="add size chart icon" onClick={() => setShowCreateSizeChart(prev => !prev)}></img>
                        {showCreateSizeChart &&
                            <div>
                                <div className="create-chart-modal" onClick={() => setShowCreateSizeChart(false)}></div>
                                <div className="popping-div" onMouseLeave={() => setShowCreateSizeChart(false)}>
                                    <p>Add new chart</p>
                                    <ul>
                                        <li onClick={() => {
                                            setShowChartWizard(true);
                                            setShowCreateSizeChart(false);
                                        }}>Customized</li>
                                        <div className="seperator"></div>
                                        <li onClick={() => {
                                            setShowAddGenericCharts(true);
                                            setShowCreateSizeChart(false);
                                        }}>Generic</li>
                                        <div className="seperator"></div>
                                        <li onClick={() => { setShowUpload(true); setShowCreateSizeChart(false); }}>Upload</li>
                                        <div className="seperator"></div>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </h1> :

                <div className={`external-dashboard-title`}>
                    <p>MySizeID Retailer's Dashboard</p>
                </div>
            }


            <div>

                <div className={"size-chart-list-header"} >
                    {isSourceWithOutProductsPair() &&
                        <div>
                            <img src={plusIcon} alt="add size chart icon" onClick={() => setShowCreateSizeChart(prev => !prev)}>
                            </img>
                            {(isSourceWithOutProductsPair() && showCreateSizeChart) &&
                                <div>
                                    <div className="create-chart-modal" onClick={() => setShowCreateSizeChart(false)}></div>
                                    <div className="popping-div " onMouseLeave={() => setShowCreateSizeChart(false)}>
                                        <p>Add new chart</p>
                                        <ul>
                                            <li onClick={() => {
                                                setShowChartWizard(true);
                                                setShowCreateSizeChart(false);
                                            }}>Customized</li>
                                            <div className="seperator"></div>
                                            <li onClick={() => {
                                                setShowAddGenericCharts(true);
                                                setShowCreateSizeChart(false);
                                            }}>Generic</li>
                                            <div className="seperator"></div>
                                            <li onClick={() => { setShowUpload(true); setShowCreateSizeChart(false); }}>Upload</li>
                                            <div className="seperator"></div>
                                        </ul>
                                    </div>
                                </div>}
                        </div>
                    }



                    <SimpleMenu menuName={titleInput || 'Title'} options={[]} setValue={setFilterValue} freeText={true} value={titleInput} type={'title'} />
                    <SimpleMenu menuName={genderSelect || 'Gender'} options={GenderOptions} setValue={setFilterValue} type={'gender'} />
                    <SimpleMenu menuName={typeSelect || 'Type'} options={typeOptions} includePhoto={true} setValue={setFilterValue} type={'type'} />
                </div>

            </div>
            {/* <Link to={{pathname:"/", state:{from: clickedButton.ADD_MORE_SIZE_CHARTS, haveSizeCharts: sizeCharts.length > 0}}}><button className='size-chart-creation'>Add More Size Charts +</button></Link> */}
            {(retailerProfile.size_chart_file_upload_status === "awaiting_review"
            //  || history.location.state?.from === "thank_you_page"
             ) &&
                <div className='charts-review'>
                    <img className='hourglass-icon' src={HourglassIcon} alt="hourglass icon" />
                    <p className='charts-review-text'>You Have Size Charts {!isSourceWithOutProductsPair() && <br />} Being Reviewed</p>
                </div>
            }

            {/* TIPS SECTION */}
            {(tipsState.select_size_chart || tipsState.create_size_chart || showHint) &&
                <div className="size-chart-tip">
                    {tipsState.select_size_chart && searchSizeCharts.length>0 && <img className="size-chart-tip__close" src={closeIcon} onClick={() => tipsHandler("select_size_chart")} />}
                    <div className="size-chart-tip__body">
                        <img className="size-chart-tip__info" src={infoIcon} />
                        <p>{tipsState.select_size_chart && searchSizeCharts.length>0 ? (retailerProfile.source === "external" ? "Copy the size chart’s code\nfor implementation.":"Select the size chart you wish to pair.") :
                            <React.Fragment >
                                <span>To create size recommendations, we need to pair size charts with their corresponding products. Click the above </span>
                                <img className="size-chart-tip__add-icon" src={addIcon} />
                                <span> to start adding size charts.</span>
                            </React.Fragment>
                        }
                        </p>
                    </div>
                    {tipsState.select_size_chart && searchSizeCharts.length>0 && <div className="size-chart-tip__arrow-container">
                        <img src={downArrowIcon} />
                    </div>}
                </div>
            }



            <SizeChartListMap elRefIntegrationCode={elRefIntegrationCode} sizeCharts={searchSizeCharts} genericChartListUpdater={genericChartListUpdater} setGenericChartListUpdater={setGenericChartListUpdater} chartReceieveFromBe={chartReceieveFromBe} />
        </div>
    );
}


export default SizeChartListWrapper;
