import React, { useState, useEffect, useContext } from 'react';
import { getMeasurementSystemNumber, getGenderNumber, getAgeNumber } from '../../utils/sizeChartFunction';
import './StandartSizeChartCreator.scss';
import jsonData from '../types.json';
import { SelectedOpenSizeChartContext } from '../../context/SelectedOpenSizeChartContext';
import SizeChartCreatorHeader from '../SizeChartCreatorHeader/SizeChartCreatorHeader';
import SizeChartTable from '../SizeChartTable/SizeChartTable';
import ApiService from '../../services/ApiService';
import closeSizeChartCreatorImg from '../../assets/images/x-close-button.svg';
import iconLock from '../../assets/images/icon-awesome-lock.svg';
import { validate } from '../../validations/body-measurements/main';
import SizeChartCreatorError from '../SizeChartCreatorError/SizeChartCreatorError';
import AlertDialog from "../AlertDialog/AlertDialog";
import { isSizeChartChangedContext } from '../../context/isSizeChartChangedContext';
import {RetailerProfileContext} from '../../context/RetailerProfileContext';



function StandartSizeChartCreator({ isLockedByMysize, name, gender, measurementSystem, ageCategory, sizeChartType, setSelectedTypeCategory, selectedTypeCategory, setProductPairRender, approvalStatus, setIsChartSaveDialogIsOpen }) {
    const [chartName, setChartName] = useState(name);
    const [chartGender, setChartGender] = useState(gender);
    const [chartMeasurementSystem, setChartMeasurementSystem] = useState(measurementSystem);
    const [chartsizeChartType] = useState(sizeChartType);
    const [sizeNames, setSizeNames] = useState([]);
    const [primaryMeasurementTypes, setPrimaryMeasurementTypes] = useState([]);
    const [openSelectedSizeChart, setOpenSelectedSizeChart] = useContext(SelectedOpenSizeChartContext);
    const [sizeMatrix, setSizeMatrix] = useState([]);
    const [chartAgeCategory, setChartAgeCategory] = useState(ageCategory);
    const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false)
    const [isChartChanged, setIsChartChanged] = useContext(isSizeChartChangedContext);
    const [retailerProfile] = useContext(RetailerProfileContext)

    const [errorList, setErrorList] = useState([]);
    const [currentErrorObj, setCurrentErrorObj] = useState({});

    const [canBeSubmited, setCanBeSubmited] = useState(false);
    const [haveEmptyNamesDialog, setHaveEmptyNamesDialog] = useState(false);


    const [updateDone] = useState(true);
    useEffect(() => {
        setSizeChartMatrix(openSelectedSizeChart.sizes);
        setIsChartChanged(false);
        // eslint-disable-next-line
    }, []);


    const setSizeChartMatrix = (sizes) => {

        // checkMeasurementTypeBySizeChartType
        let primaryMeasurementTypes = "";
        let chartCategory = "";
        jsonData.size_chart_type.forEach(type => {
            if (type.name === chartsizeChartType) {
                primaryMeasurementTypes = type.primary;
                chartCategory = type.category;
            }
        });


        setSelectedTypeCategory(chartCategory);
        let primaryMt = primaryMeasurementTypes.split(',').map(measurementTypeName => {
            return { name: measurementTypeName, enabled: true }
        })
        if (primaryMt.length === 0 || (primaryMt.length === 1 && primaryMt[0].name === ""))
            primaryMt = openSelectedSizeChart.required_measurement_types.map(mt => {
                return { name: mt.name, enabled: true }
            })
        setPrimaryMeasurementTypes(primaryMt);

        // checkMeasurementTypeBySizeChartType

        // getSizesNames
        let tempSizeNames = [];
        openSelectedSizeChart.sizes.forEach(size => {
            tempSizeNames.push(size.name);
        });

        if (openSelectedSizeChart.sizes.length > 0) {
            setSizeNames(tempSizeNames);
        }
        // getSizesNames


        let reorderdMeasurementTypes = null
        let typeNames = primaryMt;
        if (sizes[0] !== undefined) {
            reorderdMeasurementTypes = getNewSizeChartTypeOrder(sizes[0].ranges, typeNames);

        }
        else {
            reorderdMeasurementTypes = typeNames.map(typeName => {
                return typeName.name
            });
            
            setPrimaryMeasurementTypes(typeNames.map(x => {
                
                if ((chartsizeChartType === 'kids_general_tops' && (x.name === 'hipc_kids' || x.name === 'weight')) || (chartsizeChartType === 'kids_general_overall' && x.name === 'weight')) {
                    return { name: x.name, enabled: false }
                } else {

                }
                return { name: x.name, enabled: true }
            }));
        }
        if (sizes.length > 0) {
            var newArray = []
            sizes.forEach(size => {
                for (let i = 0; i < sizes.length; i++) {
                    newArray[i] = [];
                    for (let j = 0; j < reorderdMeasurementTypes.length; j++) {
                        let currentIndex = null
                        sizes[i].ranges.forEach((range, index) => {
                            if (reorderdMeasurementTypes[j] === range.measurement_type.name) {
                                currentIndex = index
                            }
                        });
                        if (currentIndex == null) {
                            newArray[i][j] = { from: '', to: '', enabled: false, measurementTypeName: reorderdMeasurementTypes[j], sizeName: sizes[i].name }
                        } else {
                            newArray[i][j] = {
                                from: sizes[i].ranges[currentIndex].min_value,
                                to: sizes[i].ranges[currentIndex].max_value,
                                enabled: true,
                                measurementTypeName: reorderdMeasurementTypes[j],
                                sizeName: sizes[i].name
                            }
                        }
                    }
                }
            });
        } else {
            newArray = [];
            for (let i = 0; i < 3; i++) {
                newArray[i] = [];
                reorderdMeasurementTypes.forEach((rmt, index) => {
                    let enabled = true;
                    if ((chartsizeChartType === 'kids_general_tops' && (rmt === 'hipc_kids' || rmt === 'weight')) || (chartsizeChartType === 'kids_general_overall' && rmt === 'weight')) {
                        enabled = false;
                    }
                    newArray[i][index] = { from: '', to: '', enabled: enabled, measurementTypeName: rmt, sizeName: '' }
                });
            }
            setSizeNames(['', '', '']);

        }
        setSizeMatrix(newArray);
    }


    const getNewSizeChartTypeOrder = (ranges, allTypes) => {
        let measurementType = []
        let rangesTypes = ranges.map(range => {
            return range.measurement_type.name;
        });
        let allTypeNames = allTypes.map(type => {
            return type.name;
        });

        let included = allTypeNames.filter(type => { return rangesTypes.includes(type) });
        let notIncluded = allTypeNames.filter(type => { return !rangesTypes.includes(type) });

        included.forEach(x => {
            measurementType.push({ name: x, enabled: true })
        })
        notIncluded.forEach(x => {
            measurementType.push({ name: x, enabled: false })
        })

        setPrimaryMeasurementTypes(measurementType);

        return [...included, ...notIncluded];
    }

    const saveSizeChart = async () => {

        if (checkForEmptyNames()) {
            setHaveEmptyNamesDialog(true);
            return
        }

        let body = {
            version: 2,
            id: openSelectedSizeChart.id,
            gender: getGenderNumber(chartGender),
            measurement_system: getMeasurementSystemNumber(chartMeasurementSystem),
            name: chartName,
            size_chart_type: chartsizeChartType,
            sizes: buildSizesObject(),
            age_category: getAgeNumber(chartAgeCategory).toLowerCase()
        };

        let errors = validate(body, sizeMatrix, chartMeasurementSystem, 'width');
        if (errors.length > 0) {
            setErrorList(errors);
            setCurrentErrorObj(errors[0]);
            setCanBeSubmited(false);
        }
        else {
            setErrorList([]);
            setCanBeSubmited(true);
            setCurrentErrorObj({});
        }

        await new ApiService().updateSizeChartByID(openSelectedSizeChart.id, body);
        setProductPairRender(prev => !prev);
        setIsChartChanged(false)

    }

    const buildSizesObject = () => {
        let arr = []
        for (let i = 0; i < sizeMatrix.length; i++) {
            arr.push({ name: sizeNames[i], ranges: getRanges(i) })
        }
        return arr
    }

    const getRanges = (rowNumber) => {
        let rangeArr = []
        primaryMeasurementTypes.forEach((mt, index) => {
            if (mt.enabled) {
                rangeArr.push({
                    measurement_type_name: mt.name,
                    min_value: sizeMatrix[rowNumber][index].from,
                    max_value: sizeMatrix[rowNumber][index].to
                });
            }
        });
        return rangeArr
    }

    const submitSizeChart = async () => {
        if (canBeSubmited) {
            let body = { approval_status: "submitted" };
            await new ApiService().submmitSizeChart(openSelectedSizeChart.id, body);
            setIsSubmitDialogOpen(true)

        }
    }

    const checkForEmptyNames = () => {
        let empty = false
        if (sizeNames.includes("")) {
            empty = true;
        }


        return empty;
    }

    const checkIfToCloseModal = () => {
        if (isChartChanged) {
            setIsChartSaveDialogIsOpen(true);
        }
        else {
            setOpenSelectedSizeChart({});
        }
    }

    

    return (
        primaryMeasurementTypes.length !== 0 &&
        <div style={{top: retailerProfile.source === 'presta' && '30%'}} className='standart-size-chart-creator-wrapper'>
            <img onClick={() => checkIfToCloseModal()} className='x-img' src={closeSizeChartCreatorImg} alt="x img" />

            { isLockedByMysize
                ?
                <div className='size-chart-locked'>
                    <img src={iconLock} alt="This size chart is maintained by MySize" />
                    <h3>This size chart is maintained by MySize</h3>
                </div>
                :
                <div>
                    <h1 className='basic-size-table-header'>Enter or Edit Body Measurements</h1>
                    <p className='basic-size-table-header-desc'>Please complete the body measurements within the size chart to make it accurate for your store.</p>
                    <SizeChartCreatorHeader approvalStatus={approvalStatus} gender={chartGender} setChartName={setChartName} chartName={chartName} selectedTypeCategory={selectedTypeCategory} updateDone={updateDone} setChartGender={setChartGender} chartAgeCategory={chartAgeCategory} setChartAgeCategory={setChartAgeCategory} measurementSystem={chartMeasurementSystem} setChartMeasurementSystem={setChartMeasurementSystem} chartsizeChartType={chartsizeChartType} />
                    {(errorList.length !== 0 || canBeSubmited) && <SizeChartCreatorError errorList={errorList} currentError={currentErrorObj} setCurrentErrorObj={setCurrentErrorObj} errorSize={errorList.length} canBeSubmited={canBeSubmited} />}
                    <SizeChartTable show={true} measurementTypes={primaryMeasurementTypes} setMeasurementTypes={setPrimaryMeasurementTypes} sizeNames={sizeNames} sizeMatrix={sizeMatrix} setSizeMatrix={setSizeMatrix} setParentSizesName={setSizeNames} isMutallyExcluded={false} isBra={false} errorCoordiante={currentErrorObj?.coordinate} />
                    <div className='chart-btn-section'>
                        <button onClick={() => saveSizeChart()} className='save-button'>Save</button>
                        <button onClick={() => submitSizeChart()} style={{ background: !canBeSubmited && '#b2b2b2', color: !canBeSubmited && '#fffff', cursor: !canBeSubmited && 'not-allowed' }} className='submit-button'>Submit</button>
                    </div>
                    {isSubmitDialogOpen && <AlertDialog
                        setIsDialogOpen={() => setOpenSelectedSizeChart({})}
                        header="Your size chart was submitted for review"
                        description="We’ll send you an email when it’s been approved. This usually takes up to 48 hours."
                        okButton='OK'
                    />}
                    {haveEmptyNamesDialog && <AlertDialog
                        setIsDialogOpen={() => setHaveEmptyNamesDialog(false)}
                        header="The size chart names are missing"
                        description="The size chart names are not defined, please make sure values are updated before saving or leaving this tab"
                        okButton='OK'
                    />}
                </div>
            }
        </div>
    );
}


export default StandartSizeChartCreator;
