import React, { useRef, useState } from "react";
import { uploadS3 } from "../../services/S3Service";
import "./SizeChartUploading.scss";
import backArrow from "../../assets/images/back-arrow.svg";
import xButton from "../../assets/images/x-close-button.svg";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import { page_location } from "../../utils/page-state";
import { emailSchema } from "../../validations/input-validations";
import ApiService from "../../services/ApiService";
import SomethingWentWrong from "../UI/CircularIndeterminate/SomethingWentWrong/SomethingWentWrong";
import ThankYou from "../ThankYou/ThankYou";
import {Link} from 'react-router-dom'

export default function SizeChartUploading({ setUploadButnClicked, setCreatorState}) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [emailError, setEmailError] = useState("first-render");
  const [emailInput, setEmailInput] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [precentProgress, setPrecentProgress] = useState(0);
  const [isFileValid, setIsFileValid] = useState(false);
  const [showSomethingWentWrong, setShowSomethingWentWrong] = useState(false);
  const [showUploadedSuccess, setShowUploadedSuccess] = useState(false);
  const emailRef = useRef();
  const emailVerifyRef = useRef();
  const descriptionRef = useRef();
  const maxSize = 104857600;

  const submitHandler = async () => {

    // avoid click "submit" during upload
    if(uploading){
      return;
    }
    const userInput = {
      email: emailRef.current.value,
      description: descriptionRef.current.value,
    };

    // email input validation
    const emailIsValid = await emailSchema.isValid({
      email: emailInput,
    });

    let email = document.getElementById('email');
    let confirmEmail = document.getElementById('confirmEmail');
    let emptyEmail = confirmEmail.value.trim() === "";
    let emailIsConfirm = email.value.trim() === confirmEmail.value.trim();

    // check for email validity + uploadedFile exist
    if (!emailIsValid) {
      setEmailError("Please enter a valid email");
    }else if(emptyEmail){
      setEmailError("Please confirm your email above"); 
    }
    else if(!emailIsConfirm){
      setEmailError("Emails do not match, please correct");
    }
    
    else {
      setEmailError(null);
    }

    if (!emailIsValid || !uploadedFile || !emailIsConfirm) {
      return;
    }

    if(uploadedFile){
      if(uploadedFile[0].size > maxSize){
        setEmailError("Uploaded file exceeds the max size limit.");
        return;
      }
    }

    setUploading(true);

    await new ApiService()
      .getPresignedUrlForUploadingFile(userInput)
      .then((data) => {
        const id = data.id;
        
        // test for new approach
        // const presignedUrl = data.put_url;
        // const uploadFile = uploadedFile[0]
        
        uploadS3(data.put_url, uploadedFile[0], setPrecentProgress)
        /*
        //  PUT request to amazon s3 whth put_url
        console.log('data', data);

        const req = {
          method: "PUT",
          headers: {
            "Content-Type": uploadedFile[0].type,
          },
          body: uploadedFile[0],
        };

        fetch(data.put_url, req)*/
          .then((data) => {
            // if (data.ok) {
            if (data.status===200) {
              // on uploading success
              

              new ApiService()
                .updateSizeChartUploadFileStatus(
                  { status: "awaiting_review" },
                  id
                )
                .then((changingStatusData) => {
                  
                  if (changingStatusData) {
                    // on uploading success
                    setUploading(false);
                    setUploadButnClicked ? setUploadButnClicked(page_location.THANK_YOU_PAGE) : setShowUploadedSuccess(true);

                  } else {
                    // on uploading success
                    setUploading(false);
                    setShowSomethingWentWrong(true);
                  }
                })
                .catch((error) => {

                  
                  // on uploading failure
                  setUploading(false);
                  setShowSomethingWentWrong(true);
                });
            }
            else {

              // on uploading success
              setUploading(false);
              setShowSomethingWentWrong(true);
            }
          })
          .catch((error) => {
            // on uploading failure

            setUploading(false);
            setShowSomethingWentWrong(true);
          });
      });
  };

  const handleEmailInputChange = async (event) => {
    const email = emailRef.current.value.trim()
    const verifyEmail = emailVerifyRef.current.value.trim()

    // check if both fields are non-empty
    if (email && verifyEmail){
      // email input validation
      const emailIsValid = await emailSchema.isValid({
        email
      });
      const verifyEmailIsValid = await emailSchema.isValid({
        email: verifyEmail
      });
      const equalEmails = email === verifyEmail

      if (!emailIsValid || !verifyEmailIsValid || !equalEmails) {
        setIsValid(false);
        setEmailError("Please enter a valid email");
      } else {
        setIsValid(true);
        setEmailError(null);
      }
    }

    setEmailInput(email);

    

    // let email = document.getElementById('email');
    // let confirmEmail = document.getElementById('confirmEmail');
    // let emailIsConfirm = email.value === confirmEmail.value;

    // check for email validity + uploadedFile exist
  };

  // const handleConfirmEmailInputChange = async (event) => {

  //   // email input validation
  //   const emailIsValid = await emailSchema.isValid({
  //     email: emailInput,
  //   });

  //   let email = document.getElementById('email');
  //   let confirmEmail = document.getElementById('confirmEmail');
  //   let emailIsConfirm = email.value === confirmEmail.value;

  //   // check for email validity + uploadedFile exist
  //   if (!emailIsValid || !emailIsConfirm) {
  //     setEmailError("Email isn't valid or confirm!");
  //   }
  //   else {
  //     setEmailError(null);
  //   }
  // };

  // check for valid input for submit btn styling
  // let submitBtnStyle = "";
  // if (!emailError && uploadedFile && emailError !== "first-render" && !uploading && uploadedFile[0].size < maxSize ) {
  //   submitBtnStyle = "pink-button";
  // }


  
  if(showUploadedSuccess){
    return <ThankYou setCreatorState={setCreatorState}/>
  }

  return (
    <div className="size-chart-uploading-wrapper">
      <div className="size-chart-uploading">
        <div className="wrapper">

          <Link onClick={setCreatorState ? () => setCreatorState(false) : null} to={{pathname:"/main", state:{from: "", haveSizeCharts: true}}}><img
              className="x-button"
              src={xButton}
              alt="exit pop up"
          ></img></Link>

          <img
            className="back-arrow"
            src={backArrow}
            alt="back arrow"
            onClick={() =>
              setUploadButnClicked(page_location.ENTER_OR_UPLOAD_PAGE)
            }
          ></img>
          <h1>Upload Your Size Charts</h1>
          <p>Please make sure to use body measurement size charts.<br/>Ensure your chart is labeled with gender and product type. </p>
          <DragAndDrop
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            uploading={uploading}
            maxSize={maxSize}
            setIsFileValid={setIsFileValid}
            precentProgress={precentProgress}
          />
          <form>
            <label>Email *</label>
            <label className="text-right" style={{marginBottom: '40px'}}>Email is for size chart confirmation purposes</label>
            <input
              autoComplete="off"
              // autocomplete="off"
              type="email"
              placeholder="Email *"
              ref={emailRef}
              onChange={handleEmailInputChange}
              id="email"
              onPaste={(e)=>{
                e.preventDefault()
                return false;
              }} 
              onCopy={(e)=>{
                e.preventDefault()
                return false;
              }}
              className={(emailError && emailError !== "first-render") ? "input-email-wrong" : "" }
            />
            <label>Confirm Email *</label>
            <input
                type="email"
                autoComplete="off"
                // autocomplete="off"
                placeholder="Confirm Email *"
                id="confirmEmail"
                ref={emailVerifyRef}
                // onChange={handleConfirmEmailInputChange}
                onPaste={(e)=>{
                  e.preventDefault()
                  return false;
                }} 
                onCopy={(e)=>{
                  e.preventDefault()
                  return false;
                }}
                onChange={handleEmailInputChange}
                className={ (emailError && emailError !== "first-render") ? "input-email-wrong" : "" }
            />
            <div className="email-error" style={{marginButton: '40px'}}>
              {emailError && emailError !== "first-render" && <label className="error-message">{emailError}</label>}
              
            </div>
            <textarea
              type="text"
              className="description-input"
              placeholder="Add Description..."
              ref={descriptionRef}
            ></textarea>
            <div
              className={`submit-button ${(uploading || !isValid || !isFileValid) ? "disabled" : ""}`}
              onClick={submitHandler}
            >
              Submit
            </div>
          </form>
        </div>
      </div>
      {/* {showSomethingWentWrong && (
        <SomethingWentWrong
          setShowSomethingWentWrong={setShowSomethingWentWrong}
        />
      )} */}
      <SomethingWentWrong
        showSomethingWentWrong={showSomethingWentWrong}
        setShowSomethingWentWrong={setShowSomethingWentWrong}
      />
    </div>
  );
}
