import React, {useEffect,useState,useContext} from 'react';
import './ProductFilter.scss';
import ApiService from '../../services/ApiService';
import {productFilters} from '../../utils/product-filter';
import arrowRight from '../../assets/images/arrow-right-title.svg';
import FilterOption from './FilterOption';
import {ProductFilterArrContext} from '../../context/ProductFilterArrContext';
import {RetailerProfileContext} from '../../context/RetailerProfileContext';
import {CollectionArrContext} from '../../context/CollectionArrContext';
import {CategoriesArrContext} from '../../context/CategoriesArrContext';
import {ManufacturersArrContext} from '../../context/ManufacturersArrContext';


export default function ProductFilter({setIsFilterOpen,filterPos,filterToEdit, setFilterToEdit}) {

    const [selectedOption, setSelectedOption] = useState(null)
    const [productFilterArr] = useContext(ProductFilterArrContext);
    const [retailerProfile] = useContext(RetailerProfileContext);
    const [collectionArr, setCollectionArr] = useContext(CollectionArrContext);
    const [categoiesArr, setCategoiesArr] = useContext(CategoriesArrContext);
    const [manufacturersArr, setManufacturersArr] = useContext(ManufacturersArrContext);

    const [shopProperties,setShopProperties] = useState({});

    const renderTitles = () => {
        let existList = []
        existList = productFilterArr.map(filter => {
            return filter.type
        });
        let arr = [];
        let i = 0;
        for (const [key, value] of Object.entries(productFilters)) {   
            
            // protect pair_status mix with unwanted filter options
            let ignore = false; 
            productFilterArr.forEach(type => {
                 
                if (type.type === "pair_status" && ["vendors", "product_types", "sku"].includes(key)){
                    ignore = true;
                }else if( ["vendors", "product_types", "sku"].includes(type.type) && key === "pair_status"){

                    ignore = true;
                }
            })
            if(ignore){}
            else if(existList.includes(key)){}
            else if(value.platform.includes(retailerProfile.source)){
            arr.push(
            <div key={i++} onClick={() =>{setSelectedOption(key)}} className='title-list' style={{borderBottom: (key === 'status' || key === 'category' || key === 'collections') && 'unset'}}>
                <p>{value.displayName}</p>
                <img alt='arrow right' src={arrowRight} />
            </div>)
            }
            
        }
        return arr;
    }

    
    
    useEffect(() => {
        new ApiService().getFilterOptions().then((data) => {
            
            if(data?.id_category_default || data?.manufacturer_name ){
                setCategoiesArr(data.id_category_default)
                setManufacturersArr(data.manufacturer_name)
                let categoriesnArr = data.id_category_default.map(categoryItem =>{
                    return categoryItem.name
                })
                let manufacturersArr = data.manufacturer_name.map(manufacturerItem =>{
                    return manufacturerItem.name
                })
                setShopProperties({manufacturer_name: manufacturersArr, id_category_default: categoriesnArr})
            }
            else if(data.collections){
                let collectionArr = []
                setCollectionArr(data.collections)
                data.collections.forEach(element => {
                    if(element.name  !== 'All Products'){
                        collectionArr.push(element.name)   
                    }
                });
                setShopProperties({collections: collectionArr})
                
            }else{
                setShopProperties(data);    
            }
            if(filterToEdit.type){
                setSelectedOption(filterToEdit.type);
            }
            else{
                setSelectedOption(null);
            }
        });
        // eslint-disable-next-line
      }, []);
      
      console.log("productFilterArr", productFilterArr);
    return (
        <div className="product-filter-wrapper" style={{left: filterPos.left, top: filterPos.top + 50}}>
        {selectedOption === null && filterToEdit.type === undefined  && renderTitles()}
        {selectedOption !== null && <FilterOption type={selectedOption} setSelectedOption={setSelectedOption} shopProperties={shopProperties} setIsFilterOpen={setIsFilterOpen} filterToEdit={filterToEdit}  setFilterToEdit={setFilterToEdit} chooseOption={selectedOption === 'tags'} />}

            
        </div>
    )
}
