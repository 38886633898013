import React, { useState } from 'react'
import './SizeChartCreatorError.scss';
import errorImg from '../../assets/images/size-chart-error.svg';
import vImg from '../../assets/images/icon-v-error.svg';
import arrowLeft from '../../assets/images/arrow-left.svg'
import arrowRight from '../../assets/images/arrow-right.svg'

export default function SizeChartCreatorError({ currentError, errorSize, errorList, setCurrentErrorObj, setOpenTab, canBeSubmited }) {

    const [errorNumber, setErrorNumber] = useState(0);

    const setErorNumber = (type) => {
        if (type === 'next') {
            if (errorNumber === errorSize - 1) {
                setErrorNumber(0);
                setCurrentErrorObj(errorList[0])
                if (setOpenTab !== undefined)
                    setOpenTab(errorList[0].tableType);


            }
            else {
                setErrorNumber(errorNumber + 1)
                setCurrentErrorObj(errorList[errorNumber + 1])
                if (setOpenTab !== undefined)
                    setOpenTab(errorList[errorNumber + 1].tableType);

            }
        }
        else {
            if (errorNumber === 0) {
                setErrorNumber(errorSize - 1);
                setCurrentErrorObj(errorList[errorSize - 1])
                if (setOpenTab !== undefined)
                    setOpenTab(errorList[errorSize - 1].tableType);
            }
            else {
                setErrorNumber(errorNumber - 1);
                setCurrentErrorObj(errorList[errorNumber - 1]);
                if (setOpenTab !== undefined)
                    setOpenTab(errorList[errorNumber - 1].tableType);
            }
        }
    }

    return (

        <div className={`size-chart-error-container ${canBeSubmited && 'can-submit'}`} >
            <img alt="error sign" src={canBeSubmited ? vImg : errorImg} />
            <p className='text-para-error'>{canBeSubmited ? 'Your size chart is ready to go. Submit it for final review and approval.' : currentError.message}</p>
            {!canBeSubmited && <div className='buttons-section'>
                <img alt="prev button" src={arrowLeft} onClick={() => { setErorNumber('prev') }} />
                &nbsp;{errorNumber + 1 > errorSize ? errorSize : errorNumber + 1}&nbsp; / &nbsp;{errorSize}&nbsp;
                <img alt="next button" src={arrowRight} onClick={() => { setErorNumber('next') }} />
            </div>}

        </div>
    )
}
